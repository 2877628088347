import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import * as _ from 'lodash';
import { finalize, Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { GridDataModel } from 'src/app-core/data-model/grid-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
    selector: 'app-change-log-list',
    templateUrl: './change-log-list.component.html'
})
export class ChangeLogListComponent extends AppComponentBase implements OnInit, OnDestroy {

    @ViewChild('kg', { static: true }) private grid: any = GridComponent;
    @ViewChild(TooltipDirective, { static: true }) public tooltipDir!: TooltipDirective;
    componentSubscriptions: Subscription = new Subscription();
    GridDataModel = new GridDataModel();
    commonDataModel = new CommonDataModel();
    paramLeadId: any;
    paramLeadLineId: any;
    gridFilterModel = _.cloneDeep(this.commonDataModel.gridFilterModel);
    gridPayload = _.cloneDeep(this.commonDataModel.gridPayload);
    changeLogGridColumnModel = _.cloneDeep(this.GridDataModel.changeLogGridColumnModel);
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        injector: Injector,
        private activeRoute: ActivatedRoute,
        private commonMethod: CommonMethod,
        private commonService: CommonService) {
        super(injector);
        this.gridPayload.grid_name = this.commonConstants.gridNameConstants.changeLogGrid;
    }

    ngOnInit(): void {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramLeadId = params.get('leadId');
            this.paramLeadLineId = params.get('leadlineId');
            if (this.paramLeadId) {
                this.onLoadItems();
            }
        }));
    }

    onLoadItems(): void {
        this.kendogridHelper.showLoadingIndicator();
        this.commonService.getGridFilters(this.commonConstants.gridNameConstants.changeLogGrid)
            .subscribe(response => {
                if (response.data) {
                    this.commonMethod.mappingGridFilters(response?.data, this.kendogridHelper, this.gridFilterModel, this.changeLogGridColumnModel);
                }
                this.onRefreshGrid();
            });
    }

    onRefreshGrid() {
        this.grid.dataStateChange.emit({
            skip: 0,
            take: this.kendogridHelper.defaultRecordsCountPerPage,
            filter: this.kendogridHelper.filter,
            group: this.kendogridHelper.groups,
            sort: this.kendogridHelper.sort
        });
    }

    getGridRecords(event: DataStateChangeEvent) {
        if (event) {
            this.kendogridHelper.dataStateChangeEvent(event);
        }
        this.kendogridHelper.showLoadingIndicator();
        this.commonMethod.gridObjectMapping(this.gridPayload, event);
        this.commonMethod.objectMapping(this.gridFilterModel, this.gridPayload);
        this.commonService.postChangeLogGridRecords(this.paramLeadId, this.paramLeadLineId, this.gridPayload)
            .pipe(finalize(() => this.kendogridHelper.hideLoadingIndicator()))
            .subscribe(response => {
                this.kendogridHelper.setGridData(response.data);
            });
    }

    onColumnReorder(event: any): void {
        this.gridFilterModel.column_fields = this.commonMethod.setGridColumnReorder(event, this.changeLogGridColumnModel);
        this.updateGridFilters();
    }

    onColumnResize(event: any): void {
        this.commonMethod.setGridColumnResize(event, this.changeLogGridColumnModel);
        this.updateGridFilters();
    }

    onChangeColumnOptions(event: any, index: number): void {
        this.changeLogGridColumnModel[index].hidden = !event.checked;
        this.gridFilterModel.column_fields = Object.assign([], this.changeLogGridColumnModel);
        this.updateGridFilters();
    }

    onSortChange(): void {
        this.updateGridFilters();
    }

    onGroupChange(): void {
        this.updateGridFilters();
    }

    updateGridFilters(): void {
        this.commonMethod.updateGridFilters(this.commonConstants.gridNameConstants.changeLogGrid, this.gridFilterModel, this.changeLogGridColumnModel);
    }

    onShowTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
            && element.offsetWidth < element.scrollWidth) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}

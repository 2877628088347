import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';

@Component({
    selector: 'app-add-comment-form',
    templateUrl: './add-comment-form.component.html'
})
export class AddCommentFormComponent implements OnInit {

    commonDataModel = new CommonDataModel()
    resolveIssueFormPayload = _.cloneDeep(this.commonDataModel.resolveIssueFormPayload);
    body: any;
    requestId: any;
    isFormLoading = false;
    activeUserSourceList: any[] = [];
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<AddCommentFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.requestId = data?.requestId;
            this.activeUserSourceList = data?.activeUserSourceList;
        }
    }

    ngOnInit() {
    }

    onFormSubmit() {
        if (this.body) {
            this.isFormLoading = true;
            const addCommentFormPayload = _.cloneDeep(this.commonDataModel.addCommentFormPayload);
            const comment = this.commonMethod.commentValidation(this.body);
            if (comment) {
                addCommentFormPayload.body = comment;
                addCommentFormPayload.users = this.commonMethod.getTaggedUsers();
            }
            this.commonService.postAddComment(CommonConstants.gridNameConstants.pricing, this.requestId, addCommentFormPayload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.isFormLoading = false;
                    this.customToastrService.success(response?.message);
                    this.dialogRef.close({ confirmResult: true });
                });
        }
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

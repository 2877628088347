import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CommonConstants } from '../constants/common-constants';
import { CommonMethod } from '../method/common-method';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild {

    readonly commonConstants: CommonConstants = CommonConstants;

    constructor(
        private router: Router,
        private commonMethod: CommonMethod) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.onCheckLogin(url);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(childRoute, state);
    }

    onCheckLogin(url: string): boolean {
        const loggedUserData = this.commonMethod.getLoggedUserData();
        if (loggedUserData && loggedUserData?.token) {
            if (url === '/login') {
                this.router.navigateByUrl('/dashboard');
            }
            return url !== '/login';
        } else {
            if (url !== '/login') {
                this.commonMethod.logout();
            }
            return true;
        }

    }

}

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'currencyValue'
})
export class CurrencyValuePipe implements PipeTransform {

    constructor(
        private currencyPipe: CurrencyPipe) {
    }

    transform(value: any): any {
        if (value) {
            const data = value.toString().replace("$", "").trim();
            return this.currencyPipe.transform(parseFloat(data))?.toString();
        } else {
            return this.currencyPipe.transform(0.00)?.toString();
        }
    }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class LeadModuleService {

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    getLeadLineScore(leadId: string, leadLineId: string): Observable<any> {
        return this.httpCommonService.getRecord(`score/${leadId}/${leadLineId}`);
    }

    postSizingRequestForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`requests`, inputData);
    }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Subscription, finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { LeadLineDataModel } from 'src/app-core/data-model/leadline-data-model';
import { ReportDataModel } from 'src/app-core/data-model/report-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { ReportService } from 'src/app-core/services/report.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-handoff-report',
    templateUrl: './handoff-report.component.html',
    styleUrls: ['./handoff-report.component.scss']
})
export class HandoffReportComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonSettings = new CommonSettings();
    reportDataModel = new ReportDataModel();
    leadLineDataModel = new LeadLineDataModel();
    reportFormPayload = _.cloneDeep(this.reportDataModel.reportFormPayload);
    leadLineNavigationModel = _.cloneDeep(this.leadLineDataModel.leadLineNavigationModel);
    isFormLoading = false;
    isReportLoading = false;
    isDisabled = false;
    paramLeadId: any;
    paramLeadLineId: any;
    paramRequestId: any;
    isBuildReportDisabledBtn = false;
    isExpandLeadLine = false;
    selectedUserList: any[] = [];
    selectedUserTooltipList: any[] = [];
    activeUserSourceList: any[] = [];
    drawingOptional = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        public commonMethod: CommonMethod,
        private commonService: CommonService,
        private activeRoute: ActivatedRoute,
        private reportService: ReportService,
        private customToastrService: CustomToastrService) {
    }

    ngOnInit(): void {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramLeadId = params.get('leadId');
            this.paramLeadLineId = params.get('leadlineId');
            this.paramRequestId = params.get('requestId');
            if (this.commonMethod.getByPassDrawingData()) {
                const loggedUserId = this.commonMethod.getUserId();
                const data = this.commonMethod.getByPassDrawingData();
                if (this.paramLeadId === data?.leadId && this.paramLeadLineId === data?.leadlineId && loggedUserId === data?.loggedUserId) {
                    this.drawingOptional = data?.isBypassDrawing;
                } else {
                    this.drawingOptional = false;
                    this.mappingByPassDrawingStorageModel();
                }
            } else {
                this.drawingOptional = false;
                this.mappingByPassDrawingStorageModel();
            }
            this.onLoadItems();
        }));

        this.componentSubscriptions.add(
            this.commonMethod.getIsActiveLeadlineStatus.subscribe((data: boolean) => {
                this.isDisabled = data;
            })
        );
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.commonService.getActiveUserList().subscribe(response => {
            if (response?.data) {
                this.activeUserSourceList = _.cloneDeep(response.data);
            }
        });
        if (this.commonMethod.checkLeadLineId(this.paramLeadLineId)) {
            this.getFieldsCheck();
        }
    }

    getFieldsCheck() {
        this.commonService.getFieldsCheck(this.drawingOptional ? 1 : 0).subscribe(response => {
            if (response.data) {
                this.isFormLoading = false;
                this.commonMethod.objectMapping(this.reportFormPayload, response.data);
                this.isBuildReportDisabledBtn = this.reportFormPayload.isBuildReport;
                if (!this.reportFormPayload.url) {
                    this.isFormLoading = false;
                }
            }
        });
    }

    onActiveUserChange(event: any): void {
        if (event?.length) {
            this.selectedUserTooltipList = _.map(event, (list => list.name));
        } else {
            this.selectedUserTooltipList = [];
        }
    }

    onEmailReport(): void {
        if (this.selectedUserList.length) {
            this.isFormLoading = true;
            let payload = _.cloneDeep(this.reportDataModel.reportEmailFormPayload);
            payload.value = _.map(this.selectedUserList, (list => {
                return { email: list.email, name: list.name };
            }));
            this.reportService.postEmailReport(this.paramLeadId, this.paramLeadLineId, payload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.selectedUserList = [];
                    this.customToastrService.success(response?.message);
                });
        }
    }

    onBuildReport(): void {
        if (this.reportFormPayload.isBuildReport) {
            this.isReportLoading = true;
            this.isBuildReportDisabledBtn = false;
            this.reportService.getSyncDealRecord(this.paramLeadId).subscribe(response => {
                this.buildReport();
            });
        }
    }

    buildReport(): void {
        this.reportService.getBuildReport(this.drawingOptional ? 1 : 0, this.paramLeadId, this.paramLeadLineId)
            .subscribe(response => {
                if (response.data) {
                    this.isBuildReportDisabledBtn = true;
                    this.drawingOptional = false;
                    this.mappingByPassDrawingStorageModel();
                    this.reportFormPayload.fileName = response.data.file;
                    this.reportFormPayload.url = response.data.url;
                    this.customToastrService.success(CommonConstants.reportConstants.reportMessageText);
                }
            });
    }

    onLoadComplete($event: any) {
        this.isFormLoading = false;
        this.isReportLoading = false;
    }

    onLoadError(error: any) {
        this.customToastrService.error('Document downloaded failed!');
    }

    onNavigation(type: string): void {
        this.commonMethod.leadLineModuleRedirection(this.mapParamData(), type);
    }

    onNavigateLeadLine(dataItem: any): void {
        if (dataItem?.form_name) {
            const index = _.findIndex(this.leadLineNavigationModel, ['label', dataItem.form_name]);
            if (index !== -1) {
                this.commonMethod.leadLineModuleRedirection(this.mapParamData(), `lead-line/${this.leadLineNavigationModel[index].route}`);
            }
        }
    }

    onToggleExpand(): void {
        this.isExpandLeadLine = !this.isExpandLeadLine;
    }

    onGetColorStatus(value: boolean) {
        return value ? 'success-content' : 'error-content';
    }

    onGetStatus(value: boolean) {
        return value ? 'Completed' : 'Pending';
    }

    onSetImage(value: boolean) {
        return value ? 'check-circle-icon' : 'info-circle-icon';
    }

    mapParamData() {
        return {
            lead_id: this.paramLeadId,
            leadline_id: this.paramLeadLineId,
            request_id: this.paramRequestId,
        }
    }

    onSetTargetLink(fileUrl: string) {
        return this.commonMethod.setTargetLink(fileUrl);
    }

    onSetFileNavigationLink(fileUrl: string) {
        return this.commonMethod.setFileNavigationLink(fileUrl);
    }

    onChangeDrawingOptional(): void {
        this.isFormLoading = true;
        this.getFieldsCheck();
        this.mappingByPassDrawingStorageModel();
    }

    mappingByPassDrawingStorageModel() {
        const byPassDrawingModel = {
            leadId: this.paramLeadId,
            leadlineId: this.paramLeadLineId,
            isBypassDrawing: this.drawingOptional,
            loggedUserId: this.commonMethod.getUserId()
        }
        this.commonMethod.setByPassDrawingData(byPassDrawingModel);
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { ConformationDialogEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LeadModuleService } from 'src/app-core/services/lead-module.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-request-form',
    templateUrl: './request-form.component.html'
})
export class RequestFormComponent implements OnInit {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel();
    requiredAt = new Date();
    minDate = new Date();
    isFormLoading = false;
    dropDownSourceList: any;
    formTitle = '';
    dataTimeFormat = this.commonSettings.dateTimeFormat;
    dropDownFilterSettings = this.commonSettings.dropDownFilterSettings;
    requestFormPayload = _.cloneDeep(this.commonDataModel.requestFormPayload);
    commonConstants: typeof CommonConstants = CommonConstants;
    conformationDialogEnum: typeof ConformationDialogEnum = ConformationDialogEnum;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private leadModuleService: LeadModuleService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<RequestFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.requestFormPayload.lead_id = data.leadId;
            this.requestFormPayload.leadline_id = data.leadlineId;
            this.requestFormPayload.document_type = data.document_type;
            this.onLoadItems();
            switch (data.document_type) {
                case this.commonConstants.leadLineConstants.sizing:
                    this.formTitle = 'Sizing';
                    this.requestFormPayload.note = CommonConstants.appConstants.sizingDocumentCommentText;
                    break;
                case this.commonConstants.leadLineConstants.pricing:
                    this.formTitle = 'Pricing';
                    break;
                case this.commonConstants.leadLineConstants.drawing:
                    this.formTitle = 'Drawing';
                    this.requestFormPayload.note = CommonConstants.appConstants.drawingDocumentCommentText;
                    break;
                default:
                    break;
            }
        }
    }

    ngOnInit() {
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.commonService.getActiveUserList()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response?.data) {
                    this.dropDownSourceList = {
                        ... this.dropDownSourceList,
                        ['respondersDataSource']: _.cloneDeep(response.data),
                        ['reportDataSource']: _.cloneDeep(this.commonDataModel.reportDataSource),
                        ['pricingTypeDataSource']: _.cloneDeep(this.commonDataModel.pricingTypeDataSource),
                        ['drawingLevelDataSource']: _.cloneDeep(this.commonDataModel.drawingLevelDataSource),
                        ['turnAroundDataSource']: _.cloneDeep(this.commonDataModel.turnAroundDataSource),
                    };
                }
            });

        this.commonService.getUserFormSettings()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (!_.isEmpty(response?.data)) {
                    switch (this.requestFormPayload.document_type) {
                        case this.commonConstants.leadLineConstants.sizing:
                            this.mappingRespondersDataSource(response.data?.sizing_user, response.data?.sizing_user_optional);
                            break;
                        case this.commonConstants.leadLineConstants.pricing:
                            this.mappingRespondersDataSource(response.data?.pricing_user, response.data?.pricing_user_optional);
                            break;
                        case this.commonConstants.leadLineConstants.drawing:
                            this.mappingRespondersDataSource(response.data?.drawing_user, response.data?.drawing_user_optional);
                            break;
                        default:
                            break;
                    }
                }
            });

        if (this.requestFormPayload.document_type !== this.commonConstants.leadLineConstants.sizing) {
            this.commonService.getModelNumerList(this.requestFormPayload.lead_id, this.requestFormPayload.leadline_id)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    if (response?.data) {
                        let data = _.filter(response.data, (list: any) => {
                            if (list.model) {
                                list.key = list.model;
                                list.value = `${list.model} (${list.doc_req_id})`;
                                return list;
                            }
                        });
                        this.dropDownSourceList = {
                            ... this.dropDownSourceList,
                            ['modelNumberDataSource']: _.cloneDeep(data)
                        };
                    }
                });
        }
    }

    onFormSubmit() {
        if (this.requestFormPayload.note) {
            this.isFormLoading = true;
            const comment = this.commonMethod.commentValidation(this.requestFormPayload.note);
            if (comment) {
                this.requestFormPayload.note = comment;
                this.requestFormPayload.users = this.commonMethod.getTaggedUsers();
            }
            if (this.requestFormPayload.is_pricing_needed && this.requestFormPayload.pricing_data.price_type) {
                this.requestFormPayload.pricing_data.note = this.getPriceTypeText(this.requestFormPayload.pricing_data.price_type);
            }
            const payload = _.cloneDeep(this.requestFormPayload);
            payload.required_at = this.commonMethod.convertDateTime(this.requiredAt);
            this.leadModuleService.postSizingRequestForm(payload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    this.dialogRef.close({ confirmResult: true });
                });
        }
    }

    mappingRespondersDataSource(data: any, optionalResponders: any) {
        this.requestFormPayload.user_ids = [];
        if (data) {
            this.requestFormPayload.user_ids.push(data);
        }
        if (optionalResponders) {
            _.filter(optionalResponders, (list: any) => {
                return this.requestFormPayload.user_ids.push(list.id);
            });
        }
    }

    onTypeChange(value: any): void {
        if (value) {
            this.requestFormPayload.note = this.getPriceTypeText(value);
        } else {
            this.requestFormPayload.note = '';
        }
    }

    getPriceTypeText(value: any) {
        return `<span>This is a ${value === '1' ? 'FIRM' : 'BUDGET'} Pricing Request</span>`;
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[busyIf]',
})

export class BusyIfDirective {

    public className = 'form-loader';

    @Input() set busyIf(isBusy: boolean) {
        this.refreshState(isBusy);
    }

    constructor(private elementRef: ElementRef) {
    }

    refreshState(isBusy: boolean): void {
        if (isBusy === undefined) {
            return;
        }
        if (isBusy) {
            this.elementRef.nativeElement.style.visibility = 'visible';
        } else {
            this.elementRef.nativeElement.style.visibility = 'hidden';
        }
    }

}

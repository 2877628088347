import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[RestrictPattern]'
})
export class RestrictPatternDirective {

    @Input('pattern') pattern: any;
    private oldvalue: any;
    private regExpr: any;

    constructor(
        private control: NgControl) {
    }

    @HostListener('input', ['$event']) change($event: any) {
        let item = $event.target;
        let value = item.value;
        let pos = item.selectionStart;
        let matchvalue = value;
        this.regExpr = new RegExp(this.pattern);
        let noMatch: boolean = (value && !(this.regExpr.test(matchvalue)));
        if (noMatch) {
            item.selectionStart = item.selectionEnd = pos - 1;
            if (item.value?.length < this.oldvalue?.length && pos == 0) {
                pos = 2;
            }

            this.oldvalue = this.oldvalue ? this.oldvalue : '';

            if (this.control && this.control?.control) {
                this.control['control'].setValue(this.oldvalue, { emit: false });
            }

            item.value = this.oldvalue;
            item.selectionStart = item.selectionEnd = pos - 1;
        }
        else {
            this.oldvalue = value;
        }

    }

}

<div class="comments-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <div class="comments-content-wrapper" infinite-scroll [infiniteScrollUpDistance]="10" [scrollWindow]="false"
        (scrolled)="onScrollDown()">
        <ng-container *ngIf="commentsList?.length">
            <div class="heading-text">Pinned Comments</div>
            <ng-container *ngFor="let item of commentsList; let i=index;">
                <div class="row mb-3" *ngIf="item.is_pinned" [ngClass]="{'disable-click': !item.is_active}">
                    <div class="col-7 p-0">
                        <div class="profile-wrapper">
                            <img
                                [src]="item?.created_by?.user_image ? item.created_by.user_image.url : 'assets/images/profile-img.png'" />
                            <div class="title-text">{{item?.created_by?.name}}</div>
                            <div class="date-text">{{item?.date_completed}}</div>
                        </div>
                    </div>
                    <div class="col-5 p-0 text-end">
                        <div class="text-sm">
                            {{documentType === commonConstants.gridNameConstants.issuesGrid ? 'Issue': 'Request'}} ID:
                            {{item?.id}}
                        </div>
                    </div>
                    <div class="col-12 p-0" *ngIf="onShowSupportDocuments(item?.support_documents)">
                        <ul class="file-list-wrapper">
                            <ng-container
                                *ngFor="let supportDocItem of item?.support_documents; let supportDocIndex=index;">
                                <li [ngClass]="{'full-width': item?.support_documents.length === 1}">
                                    <a class="navigate-link" role="button" target="_blank" href="{{supportDocItem.url}}"
                                        [matTooltip]="supportDocItem.file">
                                        {{supportDocItem.file}}
                                    </a>
                                    <button mat-flat-button type="button" color="input-color"
                                        matTooltip="Delete Document"
                                        (click)="onDeleteDocument(i,supportDocItem,supportDocIndex)"
                                        *ngIf="isLeadLineActive">
                                        <img src="assets/images/close-icon.svg" height="16" />
                                    </button>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="col-12 position-relative p-0 d-flex align-items-baseline justify-content-between">
                        <div class="text pe-2" [innerHTML]="item?.note || item?.issue_body"></div>
                        <div class="pin-btn-wrapper">
                            <button mat-flat-button type="button" color="success" class="circle-btn"
                                matTooltip="Resolve Issue" (click)="onShowResolveIssueForm(item)"
                                *ngIf="onShowResolveOption(item) && isLeadLineActive">
                                <img src="assets/images/check-icon.svg" height="18" />
                            </button>
                            <a mat-flat-button target="_blank" href="{{item?.url}}" color="primary" class="circle-btn"
                                [matTooltip]="item?.file" *ngIf="onShowIssueFile(item?.file)">
                                <img src="assets/images/file-download-icon.svg" height="16" />
                            </a>
                            <button mat-flat-button type="button" color="secondary" class="circle-btn"
                                matTooltip="Unpin" (click)="onTogglePin(i)" *ngIf="isLeadLineActive">
                                <img src="assets/images/pin-icon.svg" height="14" />
                            </button>
                        </div>
                    </div>
                    <div class="row ps-5 pe-0">
                        <ng-container *ngFor="let list of item?.comments; let index=index; let last=last;">
                            <div class="col-12 p-0">
                                <div class="profile-wrapper">
                                    <img
                                        [src]="list?.created_by?.user_image ? list.created_by.user_image.url : 'assets/images/profile-img.png'" />
                                    <div class="title-text">{{list?.created_by?.name}}</div>
                                    <div class="date-text">{{list?.updated_at}}</div>
                                </div>
                            </div>
                            <div
                                class="col-12 position-relative p-0 d-flex align-items-baseline justify-content-between">
                                <ng-container *ngIf="!list.isEditComment">
                                    <div class="text pe-2" [ngClass]="{'show-less-content': !list.isExpand}"
                                        [innerHTML]="list?.body">
                                    </div>
                                </ng-container>
                                <div class="pin-btn-wrapper">
                                    <button mat-flat-button type="button" color="secondary" class="circle-btn"
                                        matTooltip="Edit" (click)="onEditComment(list)"
                                        *ngIf="loggedUserId === list.created_by.id && !list.isEditComment && isLeadLineActive">
                                        <img src="assets/images/edit-icon.svg" height="16" />
                                    </button>
                                    <a mat-flat-button target="_blank" href="{{list?.url}}" color="primary"
                                        class="circle-btn" [matTooltip]="list?.file"
                                        *ngIf="onShowIssueFile(list?.file) && !list.isEditComment">
                                        <img src="assets/images/file-download-icon.svg" height="16" />
                                    </a>
                                    <span *ngIf="list?.body | showMoreText" class="more-less-text">
                                        <button mat-flat-button type="button" color="secondary" class="circle-btn"
                                            [matTooltip]="list.isExpand ? 'Show less' : 'Show more'"
                                            *ngIf="!list.isEditComment" (click)="list.isExpand = !list.isExpand">
                                            <img src="assets/images/{{list.isExpand?'expand-less':'expand-more'}}-icon.svg"
                                                height="20" />
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 p-0" *ngIf="list.isEditComment && isLeadLineActive">
                                <div class="form-field mb-2 mt-3 text-box-editor">
                                    <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false"
                                        [dropDownList]="activeUserSourceList" [placeHolderText]="'Reply to a comment…'"
                                        [(ngModel)]="list.body" name="body_{{i}}">
                                    </app-tiny-editor>
                                    <div class="comment-button-wrapper">
                                        <div class="upload-file-wrapper">
                                            <ng-container *ngIf="list?.uploadFile?.fileName">
                                                {{list?.uploadFile?.fileName}}
                                            </ng-container>
                                        </div>
                                        <div class="button-wrapper">
                                            <ng-container
                                                *ngIf="documentType === commonConstants.gridNameConstants.issuesGrid">
                                                <label for="file-input-{{index}}" matTooltip="Upload File" matRipple
                                                    class="upload-btn" role="button">
                                                    <img src="assets/images/file-upload-icon.svg" height="18" />
                                                </label>
                                                <input hidden type="file" id="file-input-{{index}}" ng2FileSelect
                                                    (change)="onChangeFile(list,$event)" />
                                            </ng-container>
                                            <button mat-flat-button type="button" color="secondary" matTooltip="Cancel"
                                                (click)="onCancelComment(list)">
                                                <img src="assets/images/block-icon.svg" height="18" />
                                            </button>
                                            <button mat-flat-button type="submit" color="primary" matTooltip="Update"
                                                (click)="onUpdateComment(list)">
                                                <img src="assets/images/send-icon.svg" height="16" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12 p-0" *ngIf="isLeadLineActive">
                            <div class="form-field mb-2 text-box-editor">
                                <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false"
                                    [dropDownList]="activeUserSourceList" [placeHolderText]="'Reply to a comment…'"
                                    [(ngModel)]="item.bodyText" name="bodyText_{{i}}">
                                </app-tiny-editor>
                                <div class="comment-button-wrapper">
                                    <div class="upload-file-wrapper">
                                        <ng-container *ngIf="item?.uploadFile?.fileName">
                                            {{item?.uploadFile?.fileName}}
                                        </ng-container>
                                    </div>
                                    <div class="button-wrapper">
                                        <ng-container
                                            *ngIf="documentType === commonConstants.gridNameConstants.issuesGrid">
                                            <label for="file-input-{{i}}" matTooltip="Upload File" matRipple
                                                class="upload-btn" role="button">
                                                <img src="assets/images/file-upload-icon.svg" height="18" />
                                            </label>
                                            <input hidden type="file" id="file-input-{{i}}" ng2FileSelect
                                                (change)="onChangeFile(item,$event)" />
                                        </ng-container>
                                        <button mat-flat-button type="button" color="secondary" matTooltip="Clear all"
                                            (click)="onClearComment(i)">
                                            <img src="assets/images/close-icon.svg" height="18" />
                                        </button>
                                        <button mat-flat-button type="submit" color="primary" matTooltip="Send"
                                            (click)="onAddComment(item)" [disabled]="!item.bodyText">
                                            <img src="assets/images/send-icon.svg" height="16" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="no-record-text text-start mb-4" *ngIf="onCheckCommentsList(true)">
                {{commonConstants.appConstants.noRecordsAvailable}}
            </div>
            <div class="heading-text">Other Comments</div>
            <ng-container *ngFor="let item of commentsList; let i=index;">
                <div class="row mb-3" *ngIf="!item.is_pinned" [ngClass]="{'disable-click': !item.is_active}">
                    <div class="col-7 p-0">
                        <div class="profile-wrapper">
                            <img
                                [src]="item?.created_by?.user_image ? item.created_by.user_image.url : 'assets/images/profile-img.png'" />
                            <div class="title-text">{{item?.created_by?.name}}</div>
                            <div class="date-text">{{item?.date_completed}}</div>
                        </div>
                    </div>
                    <div class="col-5 p-0 text-end">
                        <div class="text-sm">
                            {{documentType === commonConstants.gridNameConstants.issuesGrid ? 'Issue': 'Request'}} ID:
                            {{item?.id}}
                        </div>
                    </div>
                    <div class="col-12 p-0" *ngIf="onShowSupportDocuments(item?.support_documents)">
                        <ul class="file-list-wrapper">
                            <ng-container
                                *ngFor="let supportDocItem of item?.support_documents; let supportDocIndex=index;">
                                <li [ngClass]="{'full-width': item?.support_documents.length === 1}">
                                    <a class="navigate-link" role="button" target="_blank" href="{{supportDocItem.url}}"
                                        [matTooltip]="supportDocItem.file">
                                        {{supportDocItem.file}}
                                    </a>
                                    <button mat-flat-button type="button" color="input-color"
                                        matTooltip="Delete Document" *ngIf="isLeadLineActive"
                                        (click)="onDeleteDocument(i,supportDocItem,supportDocIndex)">
                                        <img src="assets/images/close-icon.svg" height="16" />
                                    </button>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="col-12 position-relative p-0 d-flex align-items-baseline justify-content-between">
                        <div class="text pe-2" [innerHTML]="item?.note || item?.issue_body"></div>
                        <div class="pin-btn-wrapper">
                            <button mat-flat-button type="button" color="success" class="circle-btn"
                                matTooltip="Resolve Issue" (click)="onShowResolveIssueForm(item)"
                                *ngIf="onShowResolveOption(item) && isLeadLineActive">
                                <img src="assets/images/check-icon.svg" height="18" />
                            </button>
                            <a mat-flat-button target="_blank" href="{{item?.url}}" color="primary" class="circle-btn"
                                [matTooltip]="item?.file" *ngIf="onShowIssueFile(item?.file)">
                                <img src="assets/images/file-download-icon.svg" height="16" />
                            </a>
                            <button mat-flat-button type="button" color="secondary" class="circle-btn" matTooltip="Pin"
                                (click)="onTogglePin(i)" *ngIf="isLeadLineActive">
                                <img src="assets/images/pin-icon.svg" height="14" />
                            </button>
                        </div>
                    </div>
                    <div class="row ps-5 pe-0">
                        <ng-container *ngFor="let list of item?.comments; let index=index; let last=last;">
                            <div class="col-12 p-0">
                                <div class="profile-wrapper">
                                    <img
                                        [src]="list?.created_by?.user_image ? list.created_by.user_image.url : 'assets/images/profile-img.png'" />
                                    <div class="title-text">{{list?.created_by?.name}}</div>
                                    <div class="date-text">{{list?.updated_at}}</div>
                                </div>
                            </div>
                            <div
                                class="col-12 position-relative p-0 d-flex align-items-baseline justify-content-between">
                                <ng-container *ngIf="!list.isEditComment">
                                    <div class="text pe-2" [ngClass]="{'show-less-content': !list.isExpand}"
                                        [innerHTML]="list?.body">
                                    </div>
                                </ng-container>
                                <div class="pin-btn-wrapper">
                                    <button mat-flat-button type="button" color="secondary" class="circle-btn"
                                        matTooltip="Edit" (click)="onEditComment(list)"
                                        *ngIf="loggedUserId === list.created_by.id && !list.isEditComment && isLeadLineActive">
                                        <img src="assets/images/edit-icon.svg" height="16" />
                                    </button>
                                    <a mat-flat-button target="_blank" href="{{list?.url}}" color="primary"
                                        class="circle-btn" [matTooltip]="list?.file"
                                        *ngIf="onShowIssueFile(list?.file)">
                                        <img src="assets/images/file-download-icon.svg" height="16" />
                                    </a>
                                    <span *ngIf="list?.body | showMoreText" class="more-less-text">
                                        <button mat-flat-button type="button" color="secondary" class="circle-btn"
                                            [matTooltip]="list.isExpand ? 'Show less' : 'Show more'"
                                            *ngIf="!list.isEditComment" (click)="list.isExpand = !list.isExpand">
                                            <img src="assets/images/{{list.isExpand?'expand-less':'expand-more'}}-icon.svg"
                                                height="20" />
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 p-0" *ngIf="list.isEditComment && isLeadLineActive">
                                <div class="form-field mb-2 mt-3 text-box-editor">
                                    <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false"
                                        [dropDownList]="activeUserSourceList" [placeHolderText]="'Reply to a comment…'"
                                        [(ngModel)]="list.body" name="body_{{i}}">
                                    </app-tiny-editor>
                                    <div class="comment-button-wrapper">
                                        <div class="upload-file-wrapper">
                                            <ng-container *ngIf="list?.uploadFile?.fileName">
                                                {{list?.uploadFile?.fileName}}
                                            </ng-container>
                                        </div>
                                        <div class="button-wrapper">
                                            <ng-container
                                                *ngIf="documentType === commonConstants.gridNameConstants.issuesGrid">
                                                <label for="file-input-{{index}}" matTooltip="Upload File" matRipple
                                                    class="upload-btn" role="button">
                                                    <img src="assets/images/file-upload-icon.svg" height="18" />
                                                </label>
                                                <input hidden type="file" id="file-input-{{index}}" ng2FileSelect
                                                    (change)="onChangeFile(list,$event)" />
                                            </ng-container>
                                            <button mat-flat-button type="button" color="secondary" matTooltip="Cancel"
                                                (click)="onCancelComment(list)">
                                                <img src="assets/images/block-icon.svg" height="18" />
                                            </button>
                                            <button mat-flat-button type="submit" color="primary" matTooltip="Update"
                                                (click)="onUpdateComment(list)">
                                                <img src="assets/images/send-icon.svg" height="16" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12 p-0" *ngIf="isLeadLineActive">
                            <div class="form-field mb-2 text-box-editor">
                                <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false"
                                    [dropDownList]="activeUserSourceList" [placeHolderText]="'Reply to a comment…'"
                                    [(ngModel)]="item.bodyText" name="bodyText_{{i}}">
                                </app-tiny-editor>
                                <div class="comment-button-wrapper">
                                    <div class="upload-file-wrapper">
                                        <ng-container *ngIf="item?.uploadFile?.fileName">
                                            {{item?.uploadFile?.fileName}}
                                        </ng-container>
                                    </div>
                                    <div class="button-wrapper">
                                        <ng-container
                                            *ngIf="documentType === commonConstants.gridNameConstants.issuesGrid">
                                            <label for="file-input-{{i}}" matTooltip="Upload File" matRipple
                                                class="upload-btn" role="button">
                                                <img src="assets/images/file-upload-icon.svg" height="18" />
                                            </label>
                                            <input hidden type="file" id="file-input-{{i}}" ng2FileSelect
                                                (change)="onChangeFile(item,$event)" />
                                        </ng-container>
                                        <button mat-flat-button type="button" color="secondary" matTooltip="Clear all"
                                            (click)="onClearComment(i)">
                                            <img src="assets/images/close-icon.svg" height="18" />
                                        </button>
                                        <button mat-flat-button type="submit" color="primary" matTooltip="Send"
                                            (click)="onAddComment(item)" [disabled]="!item.bodyText">
                                            <img src="assets/images/send-icon.svg" height="16" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="no-record-text text-start mb-4" *ngIf="onCheckCommentsList(false)">
                {{commonConstants.appConstants.noRecordsAvailable}}
            </div>
        </ng-container>
        <div class="no-record-text mt-3" *ngIf="!isFormLoading && !commentsList.length">
            {{commonConstants.appConstants.noRecordsAvailable}}
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class AccountModuleService {

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    postUserlogin(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord('login', inputData);
    }

    postForgotPassword(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord('forgotpassword', inputData);
    }

    postUserlogout(): Observable<any> {
        return this.httpCommonService.postRecord('users/logout', {});
    }

    postNotifications(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord('notifications', inputData);
    }

    getNotificationsRead(notificationId: string): Observable<any> {
        return this.httpCommonService.getRecord(`notifications/${notificationId}`);
    }

    getNotificationsReadAll(): Observable<any> {
        return this.httpCommonService.getRecord(`notifications/read/all`);
    }

}

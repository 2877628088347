import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { IdleUserTimesEnum } from 'src/app-core/enums/common-enum';
import { CommonConstants } from '../constants/common-constants';

@Injectable({
    providedIn: 'root',
})
export class IdleUserService {

    idleUserTimesEnum: typeof IdleUserTimesEnum = IdleUserTimesEnum;

    constructor(private _idle: Idle) {
        this._idle.setIdle(this.idleUserTimesEnum.idleTime);
        this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        window.addEventListener('storage', (event) => {
            if (event.key === CommonConstants.idleTimeConstants.idleState && event.newValue === CommonConstants.idleTimeConstants.start) {
                this.startWatching();
            }

            if (event.key === CommonConstants.idleTimeConstants.idleState && event.newValue === CommonConstants.idleTimeConstants.stop) {
                this.stopWatching();
            }
        });
    }

    get idle() {
        return this._idle;
    }

    startWatching() {
        this._idle.watch();
        localStorage.setItem(CommonConstants.idleTimeConstants.idleState, CommonConstants.idleTimeConstants.start);
    }

    setInterrupts() {
        this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    }

    stopWatching() {
        this._idle.stop();
        localStorage.setItem(CommonConstants.idleTimeConstants.idleState, CommonConstants.idleTimeConstants.stop);
    }
}
<div class="header-wrapper">
    <div class="logo-wrapper">
        <a routerLink="/dashboard">
            <img src="assets/images/logo.png" alt="Logo" role="button" />
        </a>
    </div>
    <div class="right-section-wrapper">
        <button mat-flat-button type="button" color="basic-btn" [matMenuTriggerFor]="notificationMenu">
            <span class="notification-count" *ngIf="notificationCount">
                {{notificationCount}}
            </span>
            <img src="assets/images/notification-icon.svg" height="22" />
        </button>
        <mat-menu #notificationMenu="matMenu" class="notification-menu-wrapper">
            <div class="overlay-loader" [busyIf]="isNotificationLoading">
                <div class="form-loader"></div>
            </div>
            <div class="title-text">Notifications</div>
            <ul class="notification-wrapper" infinite-scroll [infiniteScrollUpDistance]="10" [scrollWindow]="false"
                (scrolled)="onScrollDown()">
                <ng-container *ngIf="notificationList?.length; else noRecordTemplate">
                    <ng-container *ngFor="let item of notificationList">
                        <li (click)="onSelectNotification(item)">
                            <div class="text">
                                {{item?.data.message }}
                                <span class="date-text">{{item?.created_at}}</span>
                            </div>
                        </li>
                    </ng-container>
                </ng-container>
                <ng-template #noRecordTemplate>
                    <li class="no-record">You have 0 notification.</li>
                </ng-template>
            </ul>
            <button mat-flat-button type="button" class="read-btn" (click)="onMarkAllAsRead()"
                *ngIf="notificationList?.length">
                Mark all as read
            </button>
        </mat-menu>
        <button mat-flat-button type="button" [matMenuTriggerFor]="headerBarMenu" color="basic-btn">
            <img [src]="loggedUserData?.file ? loggedUserData.file : 'assets/images/profile-img.png'" height="38"
                class="profile-img" />
            <span [title]="loggedUserData?.name" class="user-name">{{loggedUserData?.name}}</span>
            <img src="assets/images/arrow-down-icon.svg" class="arrow-icon" />
        </button>
        <mat-menu #headerBarMenu="matMenu" class="header-menu-wrapper">
            <button mat-menu-item (click)="onShowProfileInfo()">
                <img src="assets/images/profile-icon.svg" />
                <span>My Account</span>
            </button>
            <a mat-menu-item [routerLink]="['profile/admin/user']" routerLinkActive="active"
                *ngIf="(permissionDataModel.isAdministrator && permissionDataModel.role === commonConstants.appRoleConstants.administrator)">
                <img src="assets/images/admin-panel-icon.svg" />
                <span>Administration</span>
            </a>
            <a mat-menu-item [routerLink]="['contact-company-list']" routerLinkActive="active">
                <img src="assets/images/company-icon.svg" class="company-icon" />
                <span>Companies & Contacts</span>
            </a>
            <button mat-menu-item [matMenuTriggerFor]="reportsMenu">
                <img src="assets/images/report-icon.svg" />
                <span>Reports</span>
            </button>
            <a mat-menu-item [routerLink]="['system-announcements']" routerLinkActive="active">
                <img src="assets/images/announcement-icon.svg" />
                <span>System Announcements</span>
            </a>
            <button mat-menu-item (click)="onSupportClick()">
                <img src="assets/images/phone-message-icon.svg" />
                <span>Support</span>
            </button>
            <button mat-menu-item (click)="onLogout()">
                <img src="assets/images/logout-icon.svg" />
                <span>Logout</span>
            </button>
        </mat-menu>
        <mat-menu #reportsMenu="matMenu" class="header-menu-wrapper">
            <a mat-menu-item [routerLink]="['profile/lead-report-list']" routerLinkActive="active">
                <img src="assets/images/lead-report.svg" />
                <span>Lead Report</span>
            </a>
            <a mat-menu-item [routerLink]="['profile/service-report-list']" routerLinkActive="active">
                <img src="assets/images/service-report.svg" />
                <span>Service Report</span>
            </a>
            <a mat-menu-item [routerLink]="['profile/performance-report']" routerLinkActive="active">
                <img src="assets/images/performance-report.svg" />
                <span>Performance Report</span>
            </a>
        </mat-menu>
    </div>
</div>
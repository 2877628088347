<h1 mat-dialog-title>
    <span>Upload Sizing Document</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #uploadSizingDocumentForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="uploadSizingDocumentForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="form-field">
                <div class="label-text">Velocity through the grid</div>
                <kendo-textbox [(ngModel)]="uploadSizingFormPayload.velocityThroughTheGrid"
                    name="velocityThroughTheGrid" autocomplete="off"
                    pattern="((^0\.[0-9]{0,2})|(^[0-9]{1})(\d{0,7}))(\.\d{0,2})?$" RestrictPattern
                    [disabled]="uploadSizingFormPayload.is_approved === documentStatusEnum.doc_approved">
                </kendo-textbox>
            </div>
            <div class="form-field">
                <div class="label-text">Head Loss</div>
                <kendo-textbox [(ngModel)]="uploadSizingFormPayload.headLoss" name="headLoss" autocomplete="off"
                    pattern="((^0\.[0-9]{0,2})|(^[0-9]{1})(\d{0,7}))(\.\d{0,2})?$" RestrictPattern
                    [disabled]="uploadSizingFormPayload.is_approved === documentStatusEnum.doc_approved">
                </kendo-textbox>
            </div>
            <div class="form-field">
                <div class="label-text">Blinding Percentage</div>
                <kendo-textbox [(ngModel)]="uploadSizingFormPayload.blindingPercentage" name="blindingPercentage"
                    autocomplete="off" pattern="((^0\.[0-9]{0,2})|(^[0-9]{1})(\d{0,7}))(\.\d{0,2})?$" RestrictPattern
                    [disabled]="uploadSizingFormPayload.is_approved === documentStatusEnum.doc_approved">
                </kendo-textbox>
            </div>
            <div class="form-field">
                <div class="label-text">D1 at peak flow</div>
                <kendo-textbox [(ngModel)]="uploadSizingFormPayload.peakFlow" name="peakFlow" autocomplete="off"
                    pattern="((^0\.[0-9]{0,2})|(^[0-9]{1})(\d{0,7}))(\.\d{0,2})?$" RestrictPattern
                    [disabled]="uploadSizingFormPayload.is_approved === documentStatusEnum.doc_approved">
                </kendo-textbox>
            </div>
            <div class="row position-relative">
                <div class="form-field mb-0 p-0">
                    <div class="label-text">Washwater Amount</div>
                </div>
                <div class="col-6 ps-0 pe-4">
                    <div class="form-field">
                        <kendo-textbox [(ngModel)]="uploadSizingFormPayload.washwaterAmountGpm"
                            name="washwaterAmountGpm" autocomplete="off"
                            pattern="((^0\.[0-9]{0,2})|(^[0-9]{1})(\d{0,7}))(\.\d{0,2})?$" RestrictPattern
                            [disabled]="uploadSizingFormPayload.is_approved === documentStatusEnum.doc_approved">
                            <ng-template kendoTextBoxSuffixTemplate>
                                <span class="text-wrapper">
                                    GPM
                                </span>
                            </ng-template>
                        </kendo-textbox>
                    </div>
                </div>
                <div class="col-6 ps-4 pe-0">
                    <div class="form-field">
                        <kendo-textbox [(ngModel)]="uploadSizingFormPayload.washwaterAmountPsi"
                            name="washwaterAmountPsi" autocomplete="off"
                            pattern="((^0\.[0-9]{0,2})|(^[0-9]{1})(\d{0,7}))(\.\d{0,2})?$" RestrictPattern
                            [disabled]="uploadSizingFormPayload.is_approved === documentStatusEnum.doc_approved">
                            <ng-template kendoTextBoxSuffixTemplate>
                                <span class="text-wrapper">
                                    PSI
                                </span>
                            </ng-template>
                        </kendo-textbox>
                    </div>
                </div>
                <div class="text-position">@</div>
            </div>
            <div class="form-field" *ngIf="uploadedFile.url">
                <div class="label-text">Uploaded File</div>
                <div class="file-wrapper">
                    <a [target]="onSetTargetLink(uploadedFile.url)" [href]="onSetFileNavigationLink(uploadedFile.url)">
                        {{uploadedFile?.fileName}}
                    </a>
                </div>
            </div>
            <div class="form-field" *ngIf="uploadSizingFormPayload.is_approved !== documentStatusEnum.doc_approved">
                <div class="label-text">Choose File
                    <sup *ngIf="!uploadedFile.url">*</sup>
                </div>
                <label for="myFile" class="custom-input-file"
                    [ngClass]="{'error-field': !uploadedFile.url && !uploadSizingFormPayload.file.fileName && uploadSizingDocumentForm.submitted}">
                    <input type="file" id="myFile" name="filename" class="input-file" ng2FileSelect
                        (change)="onChangeFile($event)">
                </label>
            </div>
        </div>
        <div mat-dialog-actions *ngIf="uploadSizingFormPayload.is_approved !== documentStatusEnum.doc_approved">
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpCommonService {

    public baseUrl = environment.baseUrl;

    constructor(
        private http: HttpClient) {
    }

    // Get Records
    getRecord(url: string): Observable<any> {
        return this.http.get(this.baseUrl + url).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    // Get Records by Id
    getRecordId(url: string, id: string): Observable<any> {
        return this.http.get(this.baseUrl + url + id).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    // Create new record
    postRecord(url: string, inputData: object): Observable<any> {
        return this.http.post(this.baseUrl + url, inputData).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    // Update record
    putRecord(url: string, inputData: object): Observable<any> {
        return this.http.put(this.baseUrl + url, inputData).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    // Update record
    patchRecord(url: string, inputData: object): Observable<any> {
        return this.http.patch(this.baseUrl + url, inputData).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    // Delete records
    deleteRecord(url: string, inputData: object): Observable<any> {
        return this.http.delete(this.baseUrl + url, { body: inputData }).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    // Download File
    downloadFile(url: string): Observable<any> {
        return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

}

import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { GridDataModel } from 'src/app-core/data-model/grid-data-model';

@Component({
    selector: 'app-sizing-list',
    templateUrl: './sizing-list.component.html'
})
export class SizingListComponent implements OnInit {

    gridDataModel = new GridDataModel();
    sizingGridColumnModel = _.cloneDeep(this.gridDataModel.sizingGridColumnModel);
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor() {
    }

    ngOnInit(): void {
    }

}

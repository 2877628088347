import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-conformation-dialog',
    templateUrl: './delete-conformation-dialog.component.html',
    styleUrls: ['./delete-conformation-dialog.component.scss']
})
export class DeleteConformationDialogComponent implements OnInit {

    confirmText: string | undefined;

    constructor(
        private dialogRef: MatDialogRef<DeleteConformationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) confirmText?: string) {
        if (confirmText) {
            this.confirmText = confirmText;
        }
    }

    ngOnInit() {
    }

    onConfirm() {
        this.dialogRef.close({ confirmResult: true });
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

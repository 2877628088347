import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { LeadDataModel } from 'src/app-core/data-model/lead-data-model';
import { ConformationDialogEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-upload-document-dialog',
    templateUrl: './upload-document-dialog.component.html'
})
export class UploadDocumentDialogComponent implements OnInit {

    commonSettings = new CommonSettings();
    leadDataModel = new LeadDataModel();
    commonDataModel = new CommonDataModel();
    uploadDocFormPayload = _.cloneDeep(this.commonDataModel.uploadDocFormPayload);
    priceField: any;
    uploadedFile: any;
    uploadedSupportFiles: any[] = [];
    isFormLoading = false;
    formType: any;
    requestId: any;
    baseUrl = environment.baseUrl;
    commonConstants: typeof CommonConstants = CommonConstants;
    conformationDialogEnum: typeof ConformationDialogEnum = ConformationDialogEnum;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.formType = data?.formType;
            this.requestId = data?.requestId;
            this.uploadedFile = data?.uploadedFile;
            this.uploadedSupportFiles = data?.uploadedSupportFiles;
            this.uploadDocFormPayload.model = data?.model;
            this.priceField = this.commonMethod.convertCurrencyToDecimal(data?.price);
        }
    }

    ngOnInit() {
    }

    onFormSubmit() {
        if (this.uploadDocFormPayload.file.fileName) {
            this.isFormLoading = true;
            if (this.formType === CommonConstants.leadLineConstants.supportingDocument) {
                this.commonService.postUploadSupportingDocumentForm(this.requestId, this.uploadDocFormPayload)
                    .pipe(finalize(() => this.isFormLoading = false))
                    .subscribe(response => {
                        this.commonMethod.sendLoadAllCommentsValue('0');
                        this.customToastrService.success(response?.message);
                        this.dialogRef.close({ confirmResult: true });
                    });
            } else {
                this.commonService.postUpdatePrice(this.requestId, { price: this.priceField }).subscribe(responseList => {
                    this.commonService.postUploadPricingDocumentForm(this.requestId, this.uploadDocFormPayload)
                        .pipe(finalize(() => this.isFormLoading = false))
                        .subscribe(response => {
                            this.customToastrService.success(response?.message);
                            this.dialogRef.close({ confirmResult: true });
                        });
                });
            }
        }
    }

    async onChangeFile(event: any) {
        try {
            const resultData = await this.commonMethod.uploadFileValidation(event);
            if (resultData.isFileValid) {
                this.commonMethod.objectMapping(this.uploadDocFormPayload.file, resultData);
            } else {
                this.uploadDocFormPayload.file = _.cloneDeep(this.commonDataModel.fileModel);
            }
        } catch (error: any) {
            this.customToastrService.error(error);
        }
    }

    onSetTargetLink(fileUrl: string) {
        return this.commonMethod.setTargetLink(fileUrl);
    }

    onSetFileNavigationLink(fileUrl: string) {
        return this.commonMethod.setFileNavigationLink(fileUrl);
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

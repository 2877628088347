<div class="common-wrapper">
    <div class="page-title-wrapper">
        <div class="row">
            <div class="col-10 p-0 d-flex align-items-center">
                <span class="display-5 pe-4">Change Log</span>
            </div>
            <div class="col-2 p-0">
                <div class="button-wrapper icon-button-wrapper text-end">
                    <button mat-flat-button type="button" color="input-color" matTooltip="Column Options"
                        [matMenuTriggerFor]="columnOptionsMenu">
                        <img src="assets/images/view-column-icon.svg" height="17" />
                    </button>
                    <mat-menu #columnOptionsMenu="matMenu" class="column-options-wrapper">
                        <ul (click)="$event.stopPropagation();">
                            <li *ngFor="let items of changeLogGridColumnModel; let i=index;">
                                <mat-checkbox color="primary" [name]="items.field" [checked]="!items.hidden"
                                    [disableRipple]="true" (change)="onChangeColumnOptions($event,i)">
                                    {{items.title}}
                                </mat-checkbox>
                            </li>
                        </ul>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <div class="kendo-grid-wrapper">
        <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
        </ng-template>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="onShowTooltip($event)" class="table-wrapper">
            <kendo-grid 
                #kg
                [data]="kendogridHelper.gridData"
                [resizable]="true"
                [reorderable]="true"
                (columnResize)="onColumnResize($event)"
                (columnReorder)="onColumnReorder($event)"
                [columnMenu]="false" 
                [filterable]="false"
                [sort]="kendogridHelper.sort"
                (sortChange)="onSortChange()"
                [sortable]="kendogridHelper.defaultSortable"
                [pageable]="true"
                [pageSize]="kendogridHelper.defaultRecordsCountPerPage" 
                [skip]="kendogridHelper.skipCount"
                [groupable]="true" 
                [group]="kendogridHelper.groups"
                (groupChange)="onGroupChange()"
                (dataStateChange)="getGridRecords($event)"
                [loading]="kendogridHelper.isLoading">
                <kendo-grid-column *ngFor="let list of changeLogGridColumnModel | sortBy: 'orderIndex'"
                    [field]="list.field" [title]="list.title" [width]="list?.width" [hidden]="list.hidden"
                    [minResizableWidth]="100" [filter]="list?.filter" [format]="list?.format"
                    [sortable]="list?.sortable">
                </kendo-grid-column>
                <ng-template kendoGridLoadingTemplate>
                    <div class="overlay-loader">
                        <div class="form-loader"></div>
                    </div>
                </ng-template>
            </kendo-grid>
        </div>
    </div>
</div>
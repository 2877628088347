import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-reassign-user-form',
    templateUrl: './reassign-user-form.component.html'
})
export class ReassignUserFormComponent implements OnInit {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel();
    dateTimeFormat = this.commonSettings.dateTimeFormat;
    reAssignUserFormPayload = _.cloneDeep(this.commonDataModel.reAssignUserFormPayload);
    dialogData: any;
    minDate = new Date();
    isFormLoading = false;
    dueDateTime: any;
    reAssignSourceList: any[] = [];
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<ReassignUserFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.dialogData = data;
            if (this.dialogData.revised_due_date) {
                this.dueDateTime = this.commonMethod.convertDateObject(this.dialogData.revised_due_date);
            } else {
                this.dueDateTime = new Date();
            }
        }
    }

    ngOnInit() {
        this.onLoadItems();
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.commonService.getActiveUserList()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.reAssignSourceList = response?.data;
            });
    }

    onFormSubmit() {
        this.isFormLoading = true;
        if (this.dueDateTime) {
            this.reAssignUserFormPayload.re_assigned_date = this.commonMethod.convertDateTime(this.dueDateTime);
        }
        if (this.reAssignUserFormPayload.re_assigned_comment) {
            const comment = this.commonMethod.commentValidation(this.reAssignUserFormPayload.re_assigned_comment);
            if (comment) {
                this.reAssignUserFormPayload.re_assigned_comment = comment;
                this.reAssignUserFormPayload.tagged_user_ids = this.commonMethod.getTaggedUsers();
            }
        }
        switch (this.dialogData?.formType) {
            case CommonConstants.gridNameConstants.issuesGrid:
                this.commonService.postIssueReassignUserForm(this.dialogData?.requestId, this.reAssignUserFormPayload)
                    .pipe(finalize(() => this.isFormLoading = false))
                    .subscribe(response => {
                        this.customToastrService.success(this.commonConstants.reAssignUserFormConstants.successFormText);
                        this.dialogRef.close({ confirmResult: true });
                    });
                break;
            default:
                this.commonService.postReassignUserForm(this.dialogData?.requestId, this.reAssignUserFormPayload)
                    .pipe(finalize(() => this.isFormLoading = false))
                    .subscribe(response => {
                        this.customToastrService.success(this.commonConstants.reAssignUserFormConstants.successFormText);
                        this.dialogRef.close({ confirmResult: true });
                    });
                break;
        }
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

<h1 mat-dialog-title>
    <span>
        Upload {{formType === commonConstants.leadLineConstants.supportingDocument? 'Supporting':'Pricing' }} Document
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper conformation-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <div class="form-field" *ngIf="uploadedFile?.url">
        <div class="label-text">Uploaded File</div>
        <div class="file-wrapper">
            <a [target]="onSetTargetLink(uploadedFile.url)" [href]="onSetFileNavigationLink(uploadedFile.url)">
                {{uploadedFile.fileName}}
            </a>
        </div>
    </div>
    <div class="form-field" *ngIf="uploadedSupportFiles?.length">
        <div class="label-text">Uploaded Support Files</div>
        <div class="file-wrapper">
            <ng-container *ngFor="let item of uploadedSupportFiles; let i=index">
                <a [target]="onSetTargetLink(item.url)" [href]="onSetFileNavigationLink(item.url)">
                    {{i+1 +'.'+item.file}}
                </a>
            </ng-container>
        </div>
    </div>
    <form #uploadDocumentForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="uploadDocumentForm.form.valid && onFormSubmit()">
        <div class="form-field">
            <div class="label-text">Choose File <sup>*</sup></div>
            <label for="myFile" class="custom-input-file"
                [ngClass]="{'error-field': !uploadDocFormPayload.file.fileName && uploadDocumentForm.submitted}">
                <input type="file" id="myFile" name="filename" class="input-file" ng2FileSelect
                    (change)="onChangeFile($event)">
            </label>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
<div class="details-box-wrapper">
    <div class="display-6">Activity</div>
    <div class="text-wrapper">
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.lead_last_updated | valueCheck}}</div>
                    <div class="label">Last Updated</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.lead_last_user | valueCheck}}</div>
                    <div class="label">By</div>
                </div>
            </div>
        </div>
    </div>
</div>
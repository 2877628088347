<div class="page-title-wrapper">
    <div class="row">
        <div class="col-5 p-0 d-flex align-items-center">
            <span class="display-5 pe-4">Document And Status</span>
        </div>
        <div class="col-7 p-0 text-end">
            <div class="button-wrapper">
                <button mat-flat-button type="button" color="primary" (click)="onShowRequestForm()"
                    [disabled]="isDisabled">
                    New {{gridName}} Request
                </button>
                <span class="icon-button-wrapper">
                    <button mat-flat-button type="button" color="input-color" matTooltip="Column Options"
                        [matMenuTriggerFor]="columnOptionsMenu">
                        <img src="assets/images/view-column-icon.svg" height="17" />
                    </button>
                </span>
                <mat-menu #columnOptionsMenu="matMenu" class="column-options-wrapper">
                    <ul (click)="$event.stopPropagation();">
                        <li *ngFor="let items of gridColumnModel; let i=index;">
                            <mat-checkbox color="primary" [name]="items.field" [checked]="!items.hidden"
                                [disableRipple]="true" (change)="onChangeColumnOptions($event,i)">
                                {{items.title}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </mat-menu>
            </div>
        </div>
    </div>
</div>
<div class="kendo-grid-wrapper">
    <ng-template #template let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="onShowTooltip($event)"
        class="table-wrapper">
        <kendo-grid
            #kg
            [data]="kendogridHelper.gridData"
            [resizable]="true"
            [reorderable]="true"
            (columnResize)="onColumnResize($event)"
            (columnReorder)="onColumnReorder($event)"
            [columnMenu]="false"
            [filterable]="false"
            [sort]="kendogridHelper.sort"
            [sortable]="kendogridHelper.defaultSortable"
            (sortChange)="onSortChange()"
            [pageable]="true"
            [pageSize]="kendogridHelper.defaultRecordsCountPerPage"
            [skip]="kendogridHelper.skipCount"
            [groupable]="true"
            [group]="kendogridHelper.groups"
            (groupChange)="onGroupChange()"
            (dataStateChange)="getGridRecords($event)"
            [loading]="kendogridHelper.isLoading">
            <kendo-grid-column *ngFor="let list of gridColumnModel | sortBy: 'orderIndex'" [field]="list.field"
                [title]="list.title" [width]="list?.width" [hidden]="list.hidden" [minResizableWidth]="150"
                [filter]="list?.filter" [format]="list?.format" [sortable]="list?.sortable">
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'doc_req_id'">
                    <div class="navigate-link d-block" (click)="onLoadCommentsById(dataItem)">
                        {{dataItem.doc_req_id}}
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'model'">
                    <div *ngIf="dataItem.is_approved !== documentStatusEnum.new_request &&
                        dataItem.is_approved !== documentStatusEnum.commented &&
                        !dataItem.isEdit &&
                        (dataItem.model || dataItem.equip_type); else modelInput">
                        {{(!dataItem.model && dataItem.equip_type ) ? dataItem.equip_type.trim() : dataItem.model}}
                    </div>
                    <ng-template #modelInput>
                        <div *ngIf="dataItem.is_approved === documentStatusEnum.new_request ||
                            dataItem.is_approved == documentStatusEnum.commented || dataItem.isEdit">
                            <kendo-textbox class="grid-text-box" [(ngModel)]="dataItem.model" [disabled]="isDisabled"
                                name=" dataItem-model{{dataItem.id}}" autocomplete="off" [maxlength]="50">
                            </kendo-textbox>
                        </div>
                    </ng-template>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem
                    *ngIf="list.field === 'price' && formType === commonConstants.leadLineConstants.pricing">
                    <div *ngIf="dataItem.is_approved !== documentStatusEnum.new_request &&
                        dataItem.is_approved !== documentStatusEnum.commented &&
                        (dataItem.model || dataItem.equip_type) && !dataItem.isEdit; else priceInput">
                        {{dataItem.price}}
                    </div>
                    <ng-template #priceInput>
                        <div *ngIf="dataItem.is_approved === documentStatusEnum.new_request ||
                            dataItem.is_approved === documentStatusEnum.commented || dataItem.isEdit">
                            <kendo-textbox class="grid-text-box" [(ngModel)]="dataItem.price"
                                (focus)="onCurrencyField(dataItem,true)" (blur)="onCurrencyField(dataItem,false)"
                                name=" dataItem-price{{dataItem.id}}" autocomplete="off"
                                pattern="^$?(\d{0,8})?(?:,\d{3})*(\.\d{0,2})?$" [disabled]="isDisabled"
                                RestrictPattern>
                            </kendo-textbox>
                        </div>
                    </ng-template>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'file'">
                    <ng-container
                        *ngIf="dataItem.is_approved === 2 || dataItem.isEdit ||
                        (dataItem.is_approved !== 5 && dataItem.is_approved !== 1 && dataItem.is_approved !== 0); else fileTemp">
                        <div class="basic-grid-btn" role="button" (click)="onFileUpload(dataItem,formType)"
                            [ngClass]="{'disable-click': isDisabled}">
                            Upload File
                        </div>
                    </ng-container>
                    <ng-template #fileTemp>
                        <ng-container
                            *ngIf="!dataItem.isEdit && dataItem.file && dataItem.is_approved !== 6; else emptyTemp">
                            <ng-container [ngSwitch]="formType">
                                <span *ngSwitchCase="commonConstants.leadLineConstants.sizing"
                                    [ngClass]="{'disable-click': isDisabled}">
                                    <div tooltipClass="kendo-white-tooltip-wrapper" #tooltip="kendoTooltip" kendoTooltip
                                        [tooltipTemplate]="template" filter=".sizing-file-content" position="bottom">
                                        <ng-template #template let-anchor>
                                            <ul class="tooltip-wrapper">
                                                <li>
                                                    {{'Velocity through the grid: ' + dataItem.velocityThroughTheGrid |
                                                    valueCheck}}
                                                </li>
                                                <li>
                                                    {{'Head Loss: ' + dataItem.headLoss | valueCheck}}
                                                </li>
                                                <li>
                                                    {{'Blinding Percentage: ' + dataItem.blindingPercentage |
                                                    valueCheck}}
                                                </li>
                                                <li>
                                                    {{'D1 at peak flow: ' + dataItem.peakFlow | valueCheck}}
                                                </li>
                                                <li>
                                                    {{'Washwater Amount: ' + dataItem.washwaterAmountGpm +' GPM @ '+
                                                    dataItem.washwaterAmountPsi +' PSI'}}
                                                </li>
                                            </ul>
                                        </ng-template>
                                        <div class="basic-grid-btn-ellipsis sizing-file-content"
                                            (click)="onFileUpload(dataItem,formType)" [ngClass]="{'disable-click': isDisabled}">
                                            File
                                        </div>
                                    </div>
                                </span>
                                <span *ngSwitchCase="commonConstants.leadLineConstants.drawing"
                                    [ngClass]="{'disable-click': isDisabled}">
                                    <div class="basic-grid-btn-ellipsis" (click)="onFileUpload(dataItem,formType)">
                                        File
                                    </div>
                                </span>
                                <span *ngSwitchCase="commonConstants.leadLineConstants.pricing"
                                    [ngClass]="{'disable-click': isDisabled}">
                                    <a class="navigate-link d-block" [target]="onSetTargetLink(dataItem.url)"
                                        [href]="onSetFileNavigationLink(dataItem.url)">
                                        File
                                    </a>
                                </span>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                    <ng-template #emptyTemp>
                        <span class="upload-file">-</span>
                    </ng-template>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem
                    *ngIf="list.field === 'comment' && formType === commonConstants.leadLineConstants.pricing">
                    <div class="basic-grid-btn" role="button" (click)="onShowCommentForm(dataItem)">
                        Add Comment
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'responders'">
                    <div tooltipClass="kendo-white-tooltip-wrapper" #tooltip="kendoTooltip" kendoTooltip
                        [tooltipTemplate]="template" filter=".responder-content" position="bottom">
                        <ng-template #template let-anchor>
                            <ul class="tooltip-wrapper">
                                <li *ngFor="let item of dataItem.responders.split(',')">
                                    {{item}}
                                </li>
                            </ul>
                        </ng-template>
                        <div class="basic-grid-btn-ellipsis font-normal responder-content">
                            {{dataItem.responders}}
                        </div>
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'approved_by_name'">
                    <ng-container *ngIf="(dataItem.is_approved === 1 || dataItem.is_approved === 2); else approvalTemp">
                        <span>{{dataItem.approved_by_name}}</span>
                    </ng-container>
                    <ng-template #approvalTemp>
                        <ng-container *ngIf="(permissionDataModel[formType] && dataItem.is_approved === 0) &&
                            (loggedUserId === dataItem.re_assigned_to ||
                            (dataItem.responder_ids && dataItem.responder_ids.indexOf(loggedUserId) !== -1) ||
                            loggedUserId === dataItem.created_by || loggedUserId === dataItem.requested_by)">
                            <div class="grid-icon-btn-wrapper">
                                <button mat-flat-button type="button" color="success" matTooltip="Approve Request"
                                    (click)="onShowConformationRequest(dataItem,conformationDialogEnum.approveRequest)"
                                    [disabled]="isDisabled">
                                    <img src="assets/images/check-icon.svg" />
                                </button>
                                <button mat-flat-button type="button" color="error" matTooltip="Change Request"
                                    (click)="onShowConformationRequest(dataItem,conformationDialogEnum.rejectRequest)"
                                    [disabled]="isDisabled">
                                    <img src="assets/images/close-icon.svg" />
                                </button>
                            </div>
                        </ng-container>
                    </ng-template>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'is_approved'">
                    <ng-container [ngSwitch]="dataItem.is_approved"
                        *ngIf="formType === commonConstants.leadLineConstants.pricing">
                        <span *ngSwitchCase="0">Approval Pending</span>
                        <span *ngSwitchCase="1">Complete</span>
                        <span *ngSwitchCase="2">Change Request</span>
                        <span *ngSwitchCase="4">Open</span>
                        <span *ngSwitchCase="5">Cancelled</span>
                        <span *ngSwitchCase="6">Pending</span>
                        <span *ngSwitchDefault>-</span>
                    </ng-container>
                    <ng-container [ngSwitch]="dataItem.is_approved"
                        *ngIf="formType !== commonConstants.leadLineConstants.pricing">
                        <span *ngSwitchCase="0 && dataItem.file">Approval Pending</span>
                        <span *ngSwitchCase="1">Approved</span>
                        <span *ngSwitchCase="2">Change Request</span>
                        <span *ngSwitchCase="0 || 4 || 6">Document Pending</span>
                        <span *ngSwitchCase="5">Cancelled</span>
                        <span *ngSwitchDefault>-</span>
                    </ng-container>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'support_documents'">
                    <div class="basic-grid-btn" role="button" [ngClass]="{'disable-click': isDisabled}"
                        (click)="onShowUploadDocumentForm(dataItem,commonConstants.leadLineConstants.supportingDocument)"
                        *ngIf="!dataItem.support_documents.length; else elseSupportingDocument">
                        Upload Document
                    </div>
                    <ng-template #elseSupportingDocument>
                        <div tooltipClass="kendo-white-tooltip-wrapper" #tooltip="kendoTooltip" kendoTooltip
                            [tooltipTemplate]="template" filter=".supporting-file-content" position="bottom">
                            <ng-template #template let-anchor>
                                <ul class="tooltip-wrapper">
                                    <li *ngFor="let item of dataItem.support_documents; let i=index">
                                        {{i+1 +'.'+item.file}}
                                    </li>
                                </ul>
                            </ng-template>
                            <div class="basic-grid-btn-ellipsis supporting-file-content"
                                (click)="onShowUploadDocumentForm(dataItem,commonConstants.leadLineConstants.supportingDocument)" 
                                [ngClass]="{'disable-click': isDisabled}">
                                {{dataItem.support_documents?.length > 1 ? 'Files': 'File'}}
                            </div>
                        </div>
                    </ng-template>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'action'">
                    <ng-container *ngIf="formType === commonConstants.leadLineConstants.pricing &&
                        (dataItem.is_approved === documentStatusEnum.new_request ||
                        dataItem.is_approved === documentStatusEnum.commented || dataItem.isEdit)">
                        <button mat-flat-button type="button" color="secondary" class="icon-btn" matTooltip="Save"
                            (click)="onUpdatePrice(dataItem)" [disabled]="isDisabled">
                            <img src="assets/images/check-icon.svg" height="22" />
                        </button>
                    </ng-container>
                    <ng-container *ngIf="(dataItem.is_approved !== documentStatusEnum.doc_approved &&
                        dataItem.is_approved !== documentStatusEnum.doc_cancelled &&
                        (loggedUserId === dataItem.requested_by ||
                        (dataItem.responder_ids && dataItem.responder_ids.indexOf(loggedUserId) !== -1) ||
                        permissionToEdit)); else actionHiphen">
                        <ng-container *ngIf="!dataItem.isEdit &&
                            (dataItem.is_approved === documentStatusEnum.doc_uploaded ||
                            dataItem.is_approved === documentStatusEnum.doc_rejected)">
                            <button mat-flat-button type="button" color="secondary" class="icon-btn"
                                [matTooltip]="onSetEditTooltip()" (click)="onEditField(dataItem)"
                                [disabled]="isDisabled">
                                <img src="assets/images/edit-icon.svg" height="20" />
                            </button>
                        </ng-container>
                        <ng-container *ngIf="dataItem.isEdit">
                            <button mat-flat-button type="button" color="secondary" class="icon-btn"
                                matTooltip="Cancel Edit" (click)="onCancelEdit(dataItem)" [disabled]="isDisabled">
                                <img src="assets/images/close-icon.svg" />
                            </button>
                        </ng-container>
                        <ng-container *ngIf="(dataItem.is_approved === documentStatusEnum.doc_uploaded ||
                            dataItem.is_approved === documentStatusEnum.doc_rejected ||
                            dataItem.is_approved === documentStatusEnum.new_request ||
                            dataItem.is_approved === documentStatusEnum.commented)">
                            <button mat-flat-button type="button" color="primary" class="icon-btn"
                                matTooltip="Revise Due Date" (click)="onShowReviseDueDateForm(dataItem)"
                                [disabled]="isDisabled">
                                <img src="assets/images/edit-calendar-icon.svg" height="20" />
                            </button>
                        </ng-container>
                        <ng-container
                            *ngIf="(dataItem.responder_ids && dataItem.responder_ids.indexOf(loggedUserId) !== -1)">
                            <button mat-flat-button type="button" color="primary" class="icon-btn"
                                matTooltip="Re-Assign To" (click)="onShowReassignUserForm(dataItem)"
                                [disabled]="isDisabled">
                                <img src="assets/images/re-assign-user-icon.svg" height="20" />
                            </button>
                        </ng-container>
                        <ng-container *ngIf="loggedUserId === dataItem.requested_by &&
                            (!dataItem.isEdit || dataItem.is_approved === documentStatusEnum.new_request)">
                            <button mat-flat-button type="button" color="error" class="icon-btn"
                                matTooltip="Cancel Request" (click)="onShowCancelForm(dataItem)"
                                [disabled]="isDisabled">
                                <img src="assets/images/block-icon.svg" height="20" />
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-template #actionHiphen>
                        <ng-container [ngSwitch]="formType">
                            <span *ngSwitchCase="commonConstants.leadLineConstants.sizing">
                                <img src="assets/images/info-icon.svg" class="image-icon-primary"
                                    [matTooltip]="commonConstants.gridTooltipConstants.noPermissionHydro" />
                            </span>
                            <span *ngSwitchCase="commonConstants.leadLineConstants.drawing">
                                <img src="assets/images/info-icon.svg" class="image-icon-primary"
                                    [matTooltip]="commonConstants.gridTooltipConstants.noPermissionCad" />
                            </span>
                            <span *ngSwitchCase="commonConstants.leadLineConstants.pricing">
                                <img src="assets/images/info-icon.svg" class="image-icon-primary"
                                    [matTooltip]="commonConstants.gridTooltipConstants.noPermissionBudget" />
                            </span>
                        </ng-container>
                    </ng-template>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridLoadingTemplate>
                <div class="overlay-loader">
                    <div class="form-loader"></div>
                </div>
            </ng-template>
        </kendo-grid>
    </div>
</div>
<div class="expansion-wrapper" [ngClass]="{'expanded': isDocumentWrapperExpand}">
    <div class="expansion-header-wrapper">
        <button mat-button type="button" [matTooltip]="(isDocumentWrapperExpand?'Collapse':'Expand')"
            (click)="onToggleExpand()">
            <img src="assets/images/double-arrow-icon.svg" height="14" class="expand-arrow-icon" />
        </button>
        <button mat-button type="button" matTooltip="All Comments" (click)="onTabChange(0)"
            [ngClass]="{'active': onActiveTab(0)}">
            <img src="assets/images/comments-icon.svg" height="15" />
        </button>
        <button mat-button type="button" matTooltip="All Thread" (click)="onTabChange(1)"
            [ngClass]="{'active': onActiveTab(1)}">
            <img src="assets/images/feather-activity-icon.svg" height="17" />
        </button>
        <button mat-button type="button" matTooltip="All Documents" (click)="onTabChange(2)"
            [ngClass]="{'active': onActiveTab(2)}">
            <img src="assets/images/document-icon.svg" height="17" />
        </button>
        <button mat-button type="button" matTooltip="Logs" (click)="onTabChange(3)"
            [ngClass]="{'active': onActiveTab(3)}">
            <img src="assets/images/log-icon.svg" height="17" />
            <img src="assets/images/clock-icon.svg" height="11" class="time-icon" *ngIf="isShowLogsNotification" />
        </button>
    </div>
    <div class="expansion-content-wrapper">
        <mat-tab-group class="tab-group-wrapper document-tab-wrapper" [selectedIndex]="selectedIndexValue"
            (selectedTabChange)="onTabChange($event.index)">
            <mat-tab>
                <ng-template mat-tab-label>All Comments</ng-template>
                <ng-template matTabContent>
                    <app-all-comments-list [type]="formType" [dropDownList]="activeUserSourceList">
                    </app-all-comments-list>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>All Thread</ng-template>
                <ng-template matTabContent>
                    <app-all-thread-list [type]="formType">
                    </app-all-thread-list>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>All Documents</ng-template>
                <ng-template matTabContent>
                    <app-all-documents-list [type]="formType">
                    </app-all-documents-list>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Logs</ng-template>
                <ng-template matTabContent>
                    <app-all-logs-list [logsNotificationCount]="logsNotificationCount">
                    </app-all-logs-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseHttpService } from 'src/app-core/services/base-http.service';
import { CommonService } from 'src/app-core/services/common.service';
import { HttpCommonService } from 'src/app-core/services/http-common.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangeLogListComponent } from './change-log-list/change-log-list.component';
import { DrawingListComponent } from './drawing-list/drawing-list.component';
import { HandoffReportComponent } from './handoff-report/handoff-report.component';
import { LayoutComponent } from './layout/layout.component';
import { LeadLayoutComponent } from './lead-layout/lead-layout.component';
import { PricingListComponent } from './pricing-list/pricing-list.component';
import { AppCommonModule } from './shared/app-common.module';
import { SizingListComponent } from './sizing-list/sizing-list.component';
import { SystemAnnouncementsComponent } from './system-announcements/system-announcements.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
    declarations: [
        AppComponent,
        ChangeLogListComponent,
        DrawingListComponent,
        HandoffReportComponent,
        LayoutComponent,
        LeadLayoutComponent,
        PricingListComponent,
        SizingListComponent,
        SystemAnnouncementsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppCommonModule,
        NgIdleKeepaliveModule.forRoot(),
    ],
    providers: [
        CommonService,
        HttpCommonService,
        CurrencyPipe,
        { provide: HTTP_INTERCEPTORS, useClass: BaseHttpService, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

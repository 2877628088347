<h1 mat-dialog-title>
    <span>Re-Assign User</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper conformation-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #reassignForm="ngForm" novalidate autocomplete="off" (ngSubmit)="reassignForm.form.valid && onFormSubmit()">
        <div class="row">
            <div class="col-6 ps-0">
                <div class="form-field">
                    <div class="label-text">Re-Assign Request To <sup>*</sup></div>
                    <kendo-dropdownlist [data]="reAssignSourceList" [textField]="'name'" [valueField]="'id'"
                        [defaultItem]="commonSettings.defaultItems.idName" [valuePrimitive]="true" [filterable]="true"
                        [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                        [(ngModel)]="reAssignUserFormPayload.re_assigned_to" name="re_assigned_to" required>
                    </kendo-dropdownlist>
                </div>
            </div>
            <div class="col-6 pe-0">
                <div class="form-field">
                    <div class="label-text">Revise Due Date</div>
                    <kendo-datetimepicker [(ngModel)]="dueDateTime" name="dueDateTime" [min]="minDate"
                        [readOnlyInput]="true" [format]="dateTimeFormat" [placeholder]="dateTimeFormat">
                    </kendo-datetimepicker>
                </div>
            </div>
        </div>
        <div class="form-field">
            <div class="label-text">Comment</div>
            <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false" [dropDownList]="reAssignSourceList"
                [(ngModel)]="reAssignUserFormPayload.re_assigned_comment" name="re_assigned_comment">
            </app-tiny-editor>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
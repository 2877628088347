<h1 mat-dialog-title>
    <span>Confirmation Request</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper delete-conformation-wrapper text-center">
    <img src="assets/images/warning-icon.svg" height="60" />
    <div class="display-6">Are you sure?</div>
    <div class="title-text" [innerHtml]="confirmText"></div>
    <div mat-dialog-actions>
        <button mat-flat-button type="button" color="secondary" (click)="onClose()">
            No
        </button>
        <button mat-flat-button type="button" color="primary" (click)="onConfirm()">
            Yes
        </button>
    </div>
</div>
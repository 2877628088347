import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonMethod } from 'src/app-core/method/common-method';
import { ChangePasswordComponent } from '../profile/change-password/change-password.component';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    constructor(
        private matDialog: MatDialog,
        private commonMethod: CommonMethod) {
    }

    ngOnInit(): void {
        const userData = this.commonMethod.getLoggedUserData();
        if (userData.passwordReset === 1) {
            this.matDialog.open(ChangePasswordComponent, {
                panelClass: 'confirm-dialog-wrapper',
                hasBackdrop: false,
                data: {
                    isDisabledClosedBtn: true,
                    isShowOldPassword: false
                }
            });
        }
    }

}

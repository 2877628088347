import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";

export class CommonSettings {

    public dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };

    public dateFormat = "MM-dd-yyyy";

    public dateTimeFormat = "MM/dd/yyyy hh:mm a";

    public serviceDateTimeFormat = "yyyy/MM/dd hh:mm:ss";

    public twentyFourHrsdateTimeFormat = "yyyy-MM-dd H:mm:ss";

    public defaultItems = {
        KeyValue: {
            key: '',
            value: 'Select'
        },
        textValue: {
            text: 'Select',
            value: null
        },
        idName: {
            id: '',
            name: 'Select'
        },
        userFilterIdName: {
            id: 0,
            name: 'All Users'
        },
        contactIdName: {
            contact_id: '',
            name: 'Select'
        },
        contactNameId: {
            id: '',
            contact_name: 'Select'
        },
        leadlineId: {
            leadline_id: 'Select'
        },
        callId: {
            call_id: 'Select'
        },
        fieldsValueKey: {
            key: 'Select',
            value: ''
        },
        operatorsValueKey: {
            key: 'Select',
            value: '',
            no_of_fields: 0
        },
        callLineId: {
            callLine_id: 'Select'
        },
        affiliateCompanyId: {
            affiliate_company_id: '',
            affiliate_company_name: 'Select'
        },
        gridFilterKeyValue: {
            key: 'Select Report',
            value: ''
        },
        fieldsNameId: {
            name: 'Select',
            id: ''
        },
        nameId0: {
            id: 0,
            name: 'Select'
        },
    }

}
<div class="reports-wrapper">
    <div class="reports-header-wrapper display-5">
        Handoff Report
    </div>
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <div class="reports-content-wrapper">
        <div class="left-section-wrapper">
            <ul class="timeline-list-wrapper">
                <li>
                    <div class="timeline-content" [ngClass]="onGetColorStatus(reportFormPayload?.leadline_form)">
                        <span class="circle-img">
                            <img src="assets/images/{{onSetImage(reportFormPayload?.leadline_form)}}.svg" />
                        </span>
                        <div class="reports-box-wrapper">
                            <div class="header-section">
                                <div>
                                    <span class="title-text me-2">LeadLine Form</span>
                                    <button mat-flat-button type="button" color="input-color"
                                        [matTooltip]="isExpandLeadLine?'Expand less':'Expand more'"
                                        (click)="onToggleExpand()">
                                        <img src="assets/images/{{isExpandLeadLine?'expand-less-icon':'expand-more-icon'}}.svg"
                                            height="20" />
                                    </button>
                                </div>
                                <span class="status-btn">
                                    {{onGetStatus(reportFormPayload?.leadline_form)}}
                                </span>
                            </div>
                            <ul [hidden]="!isExpandLeadLine">
                                <ng-container *ngFor="let item of reportFormPayload?.leadline_sections">
                                    <li class="field-wrapper" [ngClass]="(item?.is_completed ? '':'error-content')"
                                        (click)="onNavigateLeadLine(item)" role="buton">
                                        <img src="assets/images/{{onSetImage(item?.is_completed)}}.svg" />
                                        <span>
                                            {{item?.form_name}}
                                        </span>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="timeline-content" [ngClass]="onGetColorStatus(reportFormPayload?.sizing?.is_completed)">
                        <span class="circle-img">
                            <img src="assets/images/{{onSetImage(reportFormPayload?.sizing?.is_completed)}}.svg" />
                        </span>
                        <div class="reports-box-wrapper">
                            <div class="header-section">
                                <div class="title-text"
                                    (click)="onNavigation(commonConstants.gridNameConstants.sizingList)">
                                    Sizing
                                </div>
                                <span class="status-btn">
                                    {{onGetStatus(reportFormPayload?.sizing?.is_completed)}}
                                </span>
                            </div>
                            <ul *ngIf="!reportFormPayload?.sizing?.is_completed">
                                <li class="field-wrapper error-content m-0">
                                    <img src="assets/images/info-circle-icon.svg" />
                                    <span>
                                        {{reportFormPayload?.sizing?.message}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="timeline-content"
                        [ngClass]="onGetColorStatus(reportFormPayload?.drawing?.is_completed)">
                        <span class="circle-img">
                            <img src="assets/images/{{onSetImage(reportFormPayload?.drawing?.is_completed)}}.svg" />
                        </span>
                        <div class="reports-box-wrapper">
                            <div class="header-section">
                                <div class="title-text"
                                    (click)="onNavigation(commonConstants.gridNameConstants.drawingList)">
                                    Drawing
                                </div>
                                <span class="status-btn">
                                    {{onGetStatus(reportFormPayload?.drawing?.is_completed)}}
                                </span>
                            </div>
                            <div class="mt-3" (click)="$event.stopPropagation()">
                                <mat-checkbox [(ngModel)]="drawingOptional" name="drawing" color="success"
                                    class="check-box-sm" (change)="onChangeDrawingOptional()" [disabled]="isDisabled">
                                    Click To Bypass Drawing Requirement
                                </mat-checkbox>
                            </div>
                            <ul *ngIf="!reportFormPayload?.drawing?.is_completed">
                                <li class="field-wrapper error-content m-0">
                                    <img src="assets/images/info-circle-icon.svg" />
                                    <span>
                                        {{reportFormPayload?.drawing?.message}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="timeline-content"
                        [ngClass]="onGetColorStatus(reportFormPayload?.pricing?.is_completed)">
                        <span class="circle-img">
                            <img src="assets/images/{{onSetImage(reportFormPayload?.pricing?.is_completed)}}.svg" />
                        </span>
                        <div class="reports-box-wrapper">
                            <div class="header-section">
                                <div class="title-text"
                                    (click)="onNavigation(commonConstants.gridNameConstants.pricingList)">
                                    Pricing
                                </div>
                                <span class="status-btn">
                                    {{onGetStatus(reportFormPayload?.pricing?.is_completed)}}
                                </span>
                            </div>
                            <ul *ngIf="!reportFormPayload?.pricing?.is_completed">
                                <li class="field-wrapper error-content m-0">
                                    <img src="assets/images/info-circle-icon.svg" />
                                    <span>
                                        {{reportFormPayload?.pricing?.message}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="right-section-wrapper">
            <div class="overlay-loader position-fixed" [busyIf]="isReportLoading">
                <div class="form-loader"></div>
                <div class="text">Please be patient with us... Handoff report generation is in progress.</div>
            </div>
            <div class="field-content-wrapper" *ngIf="!reportFormPayload?.url; else pdfTemplate">
                <ng-container *ngFor="let item of reportFormPayload?.leadline_sections">
                    <ng-container *ngIf="!item.is_completed">
                        <div class="title-text" (click)="onNavigateLeadLine(item)" role="buton">
                            {{item?.form_name}}
                        </div>
                        <div class="row mt-3 mb-4">
                            <div class="col-4 ps-0" *ngFor="let list of item?.required_fields">
                                <div class="text">{{list?.label}}</div>
                                <div class="label">{{list?.value}} <sup>*</sup></div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="form-field mb-0" *ngIf="!reportFormPayload?.sizing?.is_completed">
                    <div class="title-text" (click)="onNavigation(commonConstants.gridNameConstants.sizingList)"
                        role="buton">
                        Sizing
                    </div>
                    <div class="field-wrapper error-content mt-1 mb-5">
                        <img src="assets/images/info-circle-icon.svg" />
                        <span>{{reportFormPayload?.sizing?.message}}</span>
                    </div>
                </div>
                <div class="form-field mb-0" *ngIf="!reportFormPayload?.drawing?.is_completed">
                    <div class="title-text" (click)="onNavigation(commonConstants.gridNameConstants.drawingList)"
                        role="buton">
                        Drawing
                    </div>
                    <div class="field-wrapper error-content mt-1 mb-5">
                        <img src="assets/images/info-circle-icon.svg" />
                        <span>{{reportFormPayload?.drawing?.message}}</span>
                    </div>
                </div>
                <div class="form-field mb-0" *ngIf="!reportFormPayload?.pricing?.is_completed">
                    <div class="title-text" (click)="onNavigation(commonConstants.gridNameConstants.pricingList)"
                        role="buton">
                        Pricing
                    </div>
                    <div class="field-wrapper error-content mt-1 mb-5">
                        <img src="assets/images/info-circle-icon.svg" />
                        <span>{{reportFormPayload?.pricing?.message}}</span>
                    </div>
                </div>
            </div>
            <ng-template #pdfTemplate>
                <div class="pdf-viewer-wrapper">
                    <ng-container *ngIf="reportFormPayload.url; else noRecordTemplate">
                        <div class="pdf-header-wrapper">
                            <div class="text">
                                <ng-container *ngIf="reportFormPayload.fileName">
                                    <span>Preview </span> - {{reportFormPayload.fileName}}
                                    <div class="date-text">{{reportFormPayload.reportGeneratedDateTime}}</div>
                                </ng-container>
                            </div>
                            <div class="button-wrapper">
                                <a target="_blank" [href]="reportFormPayload.url" mat-flat-button role="button"
                                    color="primary" matTooltip="Download" class="ms-3">
                                    <img src="assets/images/file-download-icon.svg" height="16" />
                                </a>
                            </div>
                        </div>
                        <div class="pdf-content-wrapper">
                            <pdf-viewer [src]="reportFormPayload.url" [original-size]="false" [render-text]="true"
                                [show-all]="true" [autoresize]="true" [fit-to-page]="true"
                                [external-link-target]="'blank'" (after-load-complete)="onLoadComplete($event)"
                                (error)="onLoadError($event)">
                            </pdf-viewer>
                        </div>
                    </ng-container>
                    <ng-template #noRecordTemplate>
                        <div class="no-record-text text-start mb-4">
                            {{commonConstants.appConstants.noRecordsAvailable}}
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="reports-footer-wrapper">
        <span class="active-user-dropdown">
            <div tooltipClass="kendo-white-tooltip-wrapper" #tooltip="kendoTooltip" kendoTooltip
                [tooltipTemplate]="template" filter=".selected-user-content" position="top">
                <ng-template #template let-anchor>
                    <ul class="tooltip-wrapper">
                        <ng-container *ngIf="selectedUserTooltipList?.length; else noRecordFound">
                            <li *ngFor="let item of selectedUserTooltipList">
                                {{item}}
                            </li>
                        </ng-container>
                        <ng-template #noRecordFound>
                            <li>
                                {{commonConstants.appConstants.noRecordsAvailable}}
                            </li>
                        </ng-template>
                    </ul>
                </ng-template>
                <div class="basic-grid-btn-ellipsis selected-user-content">
                    <kendo-multiselect kendoMultiSelectSummaryTag [data]="activeUserSourceList" [textField]="'email'"
                        [valueField]="'id'" [valuePrimitive]="false" [filterable]="true"
                        [kendoDropDownFilter]="commonSettings.dropDownFilterSettings" [checkboxes]="true"
                        [autoClose]="false" placeholder="Select Email" [disabled]="!reportFormPayload.url || isDisabled"
                        (valueChange)="onActiveUserChange($event)" [(ngModel)]="selectedUserList"
                        name="selected_userList">
                    </kendo-multiselect>
                </div>
            </div>
        </span>
        <button mat-flat-button type="button" color="secondary" (click)="onEmailReport()"
            [disabled]="!reportFormPayload.url || !selectedUserList.length || isDisabled">
            Email Report
        </button>
        <button mat-flat-button type="button" color="primary" (click)="onBuildReport()"
            [disabled]="!isBuildReportDisabledBtn || isDisabled">
            Build Report
        </button>
    </div>
</div>
import { EquipmentDataLengthEnum } from "../enums/common-enum";

export class CommonConstants {

    static readonly appRoleConstants = {
        administrator: 'administrator',
        operationMember: 'operation_member'
    }

    static readonly appConstants = {
        orderIndex: 'orderIndex',
        approveRequestTitle: 'Approve Request',
        approveRequestText: 'Approve this request',
        rejectRequestTitle: 'Reject Request',
        noRecordsAvailable: 'No records available.',
        cancelRequestText: 'You want to cancel the document request?',
        deactiveUserText: 'You want to deactivate ',
        rejectRequestText: 'You want to reject the request?',
        invalidEmailErrorText: 'Invalid email. Please enter a valid email.',
        emailCountErrorText: 'The maximum no. of emails that can be added are ',
        formMandatoryErrorText: 'Please fill all the mandatory fields.',
        loggedOutText: 'You have been successfully logged out!',
        serverErrorText: 'Internal server error.',
        sessionErrorText: 'Your Session is Timeout, Please re-login.',
        permissionErrorText: 'You do not have permission to access this module.',
        settingPermissionErrorText: 'You do not have permission to access this setting.',
        invalidFileFormat: 'File type not supported',
        imageFileSizeMessage: 'File size is more than 2 MB.',
        fileSizeErrorText: 'Error in uploading file. Please upload file less than 10 MB.',
        deleteFileText: 'You want to delete this file?',
        deleteScheduledReport: 'You want to delete this scheduled report',
        daily: 'daily',
        weekly: 'weekly',
        leadLineCopyText: 'You want to create a copy of leadline ',
        sizingDocumentCommentText: 'Please provide a sizing document',
        drawingDocumentCommentText: 'Please provide a drawing document',
        modelFieldRequiredText: 'Please fill the model field before upload the file.',
        leadLineDeactivateText: 'You want to ',
        scrollableGrid: 'scrollable',
        noScrollableGrid: 'none',
        serviceIDWarning: 'Please enter valid service id',
        archiveConfirmationDialog: 'You want to archive this contact',
        restoreConfirmationDialog: 'You want to restore this contact',
        archiveCompanyConfirmationDialog: 'You want to archive this company',
        restoreCompanyConfirmationDialog: 'You want to restore this company',
    };

    static readonly statusConstants = {
        open: 'Open',
        pending: 'Pending',
        complete: 'Complete',
        resolved: 'Resolved',
        closed: 'Closed'
    }

    static readonly moduleConstants = {
        leadDetails: 'leadDetails',
        leadService: 'leadService'
    }

    static readonly gridNameConstants = {
        sizing: 'sizing',
        sizingList: 'sizing-list',
        drawing: 'drawing',
        drawingList: 'drawing-list',
        pricing: 'pricing',
        pricingList: 'pricing-list',
        dashboard: 'dashboard',
        leadGrid: 'lead',
        issuesGrid: 'issues',
        leadLineGrid: 'leadline',
        userRequestGrid: 'user_request',
        changeLogGrid: 'changeLog',
        usersGrid: 'users',
        engineerReport: 'engineerReport',
        recentActivity: 'recentActivity',
        documentStatusGrid: 'documentStatusGrid',
        performanceMetricsGrid: 'performance_metrics',
        issue: 'issue',
        report: 'report',
        serviceReport: 'servicereport',
        leadlineStatus: 'leadlineStatus',
        serviceGrid: 'services',
        affiliatesGrid: 'affiliate_contacts',
        service: 'service',
        contactsGrid: 'contact',
        companyGrid: 'company',
        associateContactGrid: 'associate_contact',
        associateCompanyGrid: 'associate_company',
        announcementsGrid: 'systemAnnouncement',
        logHistoryGrid: 'logHistory'
    }

    static readonly leadSearchConstants = {
        date: 'date',
        search: 'search',
        dateTime: 'datetime'
    }

    static readonly logHistoryFilterConstants = {
        yesterday: 'yesterday',
        today: 'today',
        this_week: 'this_week',
        last_week: 'last_week',
        date: 'date',
    }

    static readonly logHistoryCalendarConstants = {
        daily: 'daily',
        monthly: 'monthly',
        weekly: 'weekly'
    }

    static readonly idleTimeConstants = {
        idleState: 'idleState',
        userIdle: 'User is idle! - Logging out',
        start: 'started',
        stop: 'stopped',
    }

    static readonly reAssignUserFormConstants = {
        issue: 'issue',
        successFormText: 'Re-Assign user submitted successfully.'
    }

    static readonly leadDetailsConstants = {
        memoSuccessForm: 'Note created successfully.',
        memoPinSuccessForm: 'Note pinned successfully.',
        memoUnPinSuccessForm: 'Note unpinned successfully.',
        issueResolvedSuccessText: 'This issue has already been resolved.'
    }

    static readonly leadLineConstants = {
        sizing: 'hydro',
        pricing: 'budget',
        drawing: 'cad',
        issue: 'issue',
        supportingDocument: 'supportingDocument',
        idInfoSuccessForm: 'Id information updated successfully.',
        partInfoSuccessForm: 'Part information updated successfully.',
        revenueInfoSuccessForm: 'Revenue information updated successfully.',
        projectInfoSuccessForm: 'Project information updated successfully.',
        screenInfoSuccessForm: 'Screen/Compactor details updated successfully.',
        gritEquipmentInfoSuccessForm: 'Grit Equipment details updated successfully.',
        equipmentInfoSuccessForm: 'Equipment information updated successfully.',
        bidInfoSuccessForm: 'Bid information updated successfully.',
        controlsInfoSuccessForm: 'Controls information updated successfully.',
        pricingInfoSuccessForm: 'Pricing information updated successfully.',
        equipmentItemCleared: 'Equipment info has been cleared.',
        equipmentItemRemoved: 'Equipment info has been removed.',
        equipmentMaxCountMessage: `We can add maximum ${EquipmentDataLengthEnum.value} equipment info.`,
    }

    static readonly gridTooltipConstants = {
        editHydro: 'Edit the model number & sizing document',
        editCad: 'Edit the model number & drawing document',
        editBudget: 'Edit the price & pricing document',
        noPermissionHydro: 'You do not have permission to edit the model number.',
        noPermissionCad: 'You do not have permission to edit the model number.',
        noPermissionBudget: 'You do not have permission to edit the price.',
    }

    static readonly uploadDocumentsConstants = {
        drawingBreakoutError: 'We can add maximum ',
        breakoutDuplicateError: 'is already added in breadkout drawing. Please choose other file.'
    }

    static readonly reportConstants = {
        emailReport: 'emailReport',
        buildReport: 'buildReport',
        emailSentMessageText: 'Email Sent Successfully.',
        reportMessageText: 'Report Generated Successfully.'
    }

    static readonly performanceMetricsConstants = {
        all: 'all',
        created: 'created',
        assigned: 'assigned'
    }

    static readonly fileExtensionConstants = {
        pdf: 'pdf',
        doc: 'doc',
        dot: 'dot',
        docx: 'docx',
        dotx: 'dotx',
        xls: 'xls',
        xlt: 'xlt',
        xlsx: 'xlsx',
        xltx: 'xltx',
        csv: '.csv',
        exportPdf: '.pdf'
    }

    static readonly downloadFileConstants = {
        leads: 'Leads',
        performance: 'Performance',
        users: 'Users',
        reports: 'Reports',
        serviceReports: 'ServiceReports',
        contacts: 'Contact',
        company: 'Company',
        announcements: 'Announcements',
        logHistory: 'Log-History'
    }

    static readonly notificationTooltipConstants = {
        sizingRequest: 'Enable/Disable Notifications for new sizing request if you are assigned/responder of a Request.',
        sizingUpdate: 'Enable/Disable Notifications if sizing Request assigned to me is updated.',
        sizingComment: 'Enable/Disable Notifications for comments on sizing request.',
        drawingRequest: 'Enable/Disable Notifications for new Drawing Request if you are assigned/responder of a Request.',
        drawingUpdate: 'Enable/Disable Notifications if Drawing Request assigned to me is updated.',
        drawingComment: 'Enable/Disable Notifications for comments on Drawing request.',
        budgetRequest: 'Enable/Disable Notifications for new Pricing request if you are assigned/responder of a Request.',
        budgetUpdate: 'Enable/Disable Notifications if Pricing Request assigned to me is updated.',
        budgetComment: 'Enable/Disable Notifications for comments on Pricing request.',
        issueRequest: 'Enable/Disable Notifications for new issue request if you are assigned/responder of a Request.',
        issueComment: 'Enable/Disable Notifications for comments on Issue request.'
    }

    static readonly formTypeConstants = {
        add: 'add',
        edit: 'edit',
        followUp: 'followUp'
    }

    static readonly documentTypeConstants = {
        warranty: 'warranty',
        salesOrder: 'sales_order'
    }

    static readonly dealStageConstants = {
        ul: 'UL',
        prel: 'PREL',
        dd: 'DD',
        bid: 'BID',
        abr: 'ABR',
        win: 'WIN',
        lost: 'LOST',
        dead: 'DEAD'
    }

    static readonly leadStageFullFormConstants = {
        ul: 'Unqualified Lead',
        prel: 'Preliminary Design',
        dd: 'Detailed Design',
        bid: 'Bidding',
        abr: 'Awaiting Bid Results',
        win: 'Win',
        lost: 'Bid Lost to Competitor',
        dead: 'No Bid Project Dead'
    }

    static readonly revenueInputFromContants = {
        dashboard: 'dashboard',
        leadlineForm: 'leadline_form'
    }
}

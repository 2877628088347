<h1 mat-dialog-title>
    <span>Change Password</span>
    <button mat-icon-button type="button" matTooltip="Close" [disabled]="changePasswordForm?.isDisabledClosedBtn"
        (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #profileInfoForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="profileInfoForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="form-field" *ngIf="changePasswordForm.isShowOldPassword">
                <div class="label-text">Old Password <sup>*</sup></div>
                <kendo-textbox #oldPassword [(ngModel)]="changePasswordFormPayload.password" name="password"
                    autocomplete="off" [maxlength]="30" required>
                </kendo-textbox>
            </div>
            <div class="form-field">
                <div class="label-text">New Password <sup>*</sup></div>
                <kendo-textbox #newPassword [(ngModel)]="changePasswordFormPayload.new_password" name="new_password"
                    autocomplete="off" [maxlength]="30" required>
                </kendo-textbox>
            </div>
            <div class="form-field">
                <div class="label-text">Confirm Password <sup>*</sup></div>
                <kendo-textbox #confirmPassword [(ngModel)]="changePasswordFormPayload.confirm_password"
                    name="confirm_password" autocomplete="off" [maxlength]="30" required>
                </kendo-textbox>
            </div>
            <div *ngIf="!isPasswordMatch" class="error-txt">Password must match</div>
        </div>
        <div mat-dialog-actions class="pt-0">
            <button mat-flat-button type="button" color="secondary" [disabled]="changePasswordForm?.isDisabledClosedBtn"
                (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Submit
            </button>
        </div>
    </form>
</div>
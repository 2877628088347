import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { LeadLineDataModel } from 'src/app-core/data-model/leadline-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { CommonService } from 'src/app-core/services/common.service';
import { ConformationDialogComponent } from '../conformation-dialog/conformation-dialog.component';

@Component({
    selector: 'app-leadline-revenue-editor-dialog',
    templateUrl: './leadline-revenue-editor-dialog.component.html',
    styleUrls: ['./leadline-revenue-editor-dialog.component.scss']
})
export class LeadlineRevenueEditorDialogComponent implements OnInit {

    isFormLoading = false;
    customPatterns = new CustomPatterns();
    leadLineDataModel = new LeadLineDataModel();
    revenueInfoFormPayload = this.leadLineDataModel.revenueInfoFormPayload;
    revenueDataSource: any;
    copyRevenueDataSource: any;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private dialogRef: MatDialogRef<ConformationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
    }

    ngOnInit(): void {
        this.onLoadItems();
    }

    onLoadItems() {
        this.isFormLoading = true;
        this.commonService.getIdInfoForm()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response.data) {
                    this.revenueDataSource = _.cloneDeep(response.data);
                    this.copyRevenueDataSource = _.cloneDeep(response.data);
                    this.revenueDataSource.leadLine.map((val: any) => {
                        val.lolgross_amount = val.lolgross_amount ? this.commonMethod.convertCurrency(val.lolgross_amount) : this.commonMethod.convertCurrency('0.00'),
                            val.lolrevenue_forecast = val.lolrevenue_forecast ? this.commonMethod.convertCurrency(val.lolrevenue_forecast) : this.commonMethod.convertCurrency('0.00')
                    });
                }
            });
        this.commonService.getLeadDetailsRevenue().subscribe(response => {
            this.commonMethod.sendRevenueValue(response?.data.detail_info.amount);
        });
    }

    onRevenueBlur(item: any, i: any): void {
        if (item.lolgross_amount === "" || item.lolrevenue_forecast === "") {
            this.onLoadItems();
            return;
        }
        if (item.lolgross_amount && this.copyRevenueDataSource.leadLine[i].lolgross_amount !== this.commonMethod.convertCurrencyToDecimal(item.lolgross_amount) || item.lolrevenue_forecast && this.copyRevenueDataSource.leadLine[i].lolrevenue_forecast !== this.commonMethod.convertCurrencyToDecimal(item.lolrevenue_forecast)) {
            this.isFormLoading = true;
            let payload = _.cloneDeep(this.revenueInfoFormPayload);
            payload.lolgross_amount = this.commonMethod.convertCurrencyToDecimal(item.lolgross_amount ? item.lolgross_amount : 0);
            payload.lolrevenue_forecast = this.commonMethod.convertCurrencyToDecimal(item.lolrevenue_forecast ? item.lolrevenue_forecast : 0);
            payload.input_from = CommonConstants.revenueInputFromContants.dashboard;
            this.commonService.postRevenueInfoForm(payload, item.lollead_id, item.lolleadline_id)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.onLoadItems();
                });
        }
    }

    handleFocus(item: any, type: string) {
        if (type === 'amount') {
            item.lolgross_amount = '';
        } else {
            item.lolrevenue_forecast = '';
        }
    }

    onClose() {
        this.dialogRef.close({ confirmResult: true });
    }

}

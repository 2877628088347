<div class="comments-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <div class="comments-content-wrapper" infinite-scroll [infiniteScrollUpDistance]="10" [scrollWindow]="false"
        (scrolled)="onScrollDown()">
        <ng-container *ngIf="threadList?.length">
            <div class="row mb-3" *ngFor="let item of threadList; let i=index;">
                <div class="col-7 p-0">
                    <div class="profile-wrapper">
                        <img [src]="item?.image ? item.image : 'assets/images/profile-img.png'" />
                        <div class="title-text">{{item?.user}}</div>
                        <div class="date-text">{{item?.time}}</div>
                    </div>
                </div>
                <div class="col-5 p-0 text-end">
                    <div class="text-sm">Request ID: {{item?.id}}</div>
                </div>
                <div class="col-12 p-0">
                    <div class="text">{{item?.description}}</div>
                </div>
            </div>
        </ng-container>
        <div class="no-record-text mt-2" *ngIf="!isFormLoading && !threadList.length">
            {{commonConstants.appConstants.noRecordsAvailable}}
        </div>
    </div>
</div>
<div class="system-announcement-wrapper">
    <div class="page-header-wrapper">
        <span class="display-6">LeadSpeed</span>
        <img src="assets/images/arrow-right-icon.svg" class="ms-1 me-1" />
        <span class="display-6">Announcements</span>
    </div>
    <div class="system-announcement-content-wrapper">
        <div class="main-container-wrapper">
            <div class="announcement-editor">
                <div class="overlay-loader" [busyIf]="isFormLoading">
                    <div class="form-loader"></div>
                </div>
                <form #systemAnnouncementForm="ngForm" novalidate autocomplete="off"
                    (ngSubmit)="systemAnnouncementForm.form.valid && onSubmit()" *ngIf="isShowAnnouncementContainer">
                    <div class="page-title-wrapper">
                        <div class="row">
                            <div class="col-2 d-flex align-items-center">
                                <div class="display-4">Announcements</div>
                            </div>
                            <div class="col-10">
                                <div class="button-wrapper">
                                    <span class="icon-button-wrapper">
                                        <span *ngIf="isShowAnnoucementEditor">
                                            <button mat-flat-button type="submit" color="primary" class="px-3">
                                                {{formType === commonConstants.formTypeConstants.edit ? 'Update':
                                                'Save'}}
                                            </button>
                                            <button mat-flat-button type="submit" color="secondary" class="px-3"
                                                (click)="resetAnnouncement(systemAnnouncementForm)">
                                                Cancel
                                            </button>
                                        </span>
                                        <button mat-flat-button type="button" color="primary" class="px-3"
                                            (click)="addNewAnnouncement()" *ngIf="!isShowAnnoucementEditor">
                                            Add New Announcement
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="announcement-editor-content" *ngIf="isShowAnnoucementEditor">
                        <div class="row title-wrapper">
                            <kendo-label class="k-form">
                                <kendo-textbox type="text" name="subject_line" autocomplete="off"
                                    [(ngModel)]="systemAnnouncementFormPayload.subject_line" required>
                                    <ng-template kendoTextBoxPrefixTemplate>
                                        <div class="title-prefix">Title</div>
                                    </ng-template>
                                </kendo-textbox>
                            </kendo-label>
                            <ng-template kendoTextBoxPrefixTemplate>
                                <p>Title</p>
                                <kendo-textbox-separator></kendo-textbox-separator>
                            </ng-template>
                        </div>
                        <div class="row mt-3 kendo-editor-wrapper">
                            <div class="col-12">
                                <kendo-editor [(ngModel)]="systemAnnouncementFormPayload.description" name="description"
                                    #editor required></kendo-editor>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="view-announcement" *ngIf="isViewAnnouncement">
                    <div class="row">
                        <div class="title col-12">{{systemAnnouncementFormPayload.subject_line}}</div>
                        <div class="description col-12 mt-3" [innerHTML]="systemAnnouncementFormPayload?.description">
                        </div>
                    </div>
                </div>
            </div>
            <div class="announcement-editor-container mt-3">
                <div class="page-title-wrapper mb-1">
                    <div class="row">
                        <div class="col-2 d-flex align-items-center">
                            <div class="display-4">Announcement History</div>
                        </div>
                        <div class="col-10">
                            <div class="button-wrapper">
                                <span class="icon-button-wrapper">
                                    <button mat-flat-button type="button" color="input-color" matTooltip="Export CSV"
                                        (click)="onExportToCSV()" [disabled]="!kendogridHelper.gridData.data.length">
                                        <img src="assets/images/file-download-icon.svg" />
                                    </button>
                                    <button mat-flat-button type="button" color="input-color"
                                        matTooltip="Column Options" [matMenuTriggerFor]="columnOptionsMenu">
                                        <img src="assets/images/view-column-icon.svg" height="18" />
                                    </button>
                                </span>
                                <mat-menu #columnOptionsMenu="matMenu" class="column-options-wrapper">
                                    <ul (click)="$event.stopPropagation();" class="overflow-hidden">
                                        <ng-container *ngFor="let items of announcementsGridColumnModel; let i=index;">
                                            <li *ngIf="items.field !== 'action' || isShowAnnouncementContainer">
                                                <mat-checkbox color="primary" [name]="items.field"
                                                    [checked]="!items.hidden" [disableRipple]="true"
                                                    (change)="onChangeColumnOptions($event,i)">
                                                    {{items.title}}
                                                </mat-checkbox>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kendo-grid-wrapper">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>
                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="onShowTooltip($event)" class="table-wrapper">
                        <kendo-grid 
                            #kg 
                            [data]="kendogridHelper.gridData" 
                            [resizable]="true" [reorderable]="true"
                            (columnResize)="onColumnResize($event)" 
                            (columnReorder)="onColumnReorder($event)"
                            [columnMenu]="false" 
                            [filterable]="false" 
                            [sort]="kendogridHelper.sort"
                            [sortable]="kendogridHelper.defaultSortable" 
                            (sortChange)="onSortChange()" [pageable]="true"
                            [pageSize]="kendogridHelper.defaultRecordsCountPerPage" 
                            [skip]="kendogridHelper.skipCount"
                            [groupable]="true" 
                            [group]="kendogridHelper.groups" 
                            (groupChange)="onGroupChange()"
                            (dataStateChange)="getGridRecords($event)" 
                            [loading]="kendogridHelper.isLoading">
                            <kendo-grid-column *ngFor="let list of announcementsGridColumnModel | sortBy: 'orderIndex'"
                                [field]="list.field" [title]="list.title" [width]="list?.width" [hidden]="list.hidden"
                                [minResizableWidth]="100" [filter]="list?.filter" [format]="list?.format"
                                [sortable]="list?.sortable">
                                <ng-template kendoGridHeaderTemplate>
                                    <span *ngIf="list.field !== 'action' || isShowAnnouncementContainer">
                                        {{ list.title }}
                                    </span>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'updated_at'">
                                    <a class="d-block">
                                        {{ dataItem.updated_at | date:'M/d/yy, h:mm a':'UTC' }}
                                    </a>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem *ngIf="list.field === 'subject_line'">
                                    <a class="navigate-link d-block" (click)="viewAnnouncement(dataItem)">
                                        {{dataItem.subject_line}}
                                    </a>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem
                                    *ngIf="list.field === 'action' && isShowAnnouncementContainer">
                                    <div class="grid-icon-btn-wrapper">
                                        <button mat-flat-button type="button" color="primary" matTooltip="Edit">
                                            <img src="assets/images/revenue-icon.svg" height="14"
                                                (click)="fetchAnnouncement(dataItem)" />
                                        </button>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-excel
                                [fileName]="commonConstants.downloadFileConstants.announcements + commonConstants.fileExtensionConstants.csv">
                            </kendo-grid-excel>
                            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                                <kendo-pager-info></kendo-pager-info>
                                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                                <kendo-pager-numeric-buttons [buttonCount]="10"> </kendo-pager-numeric-buttons>
                                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                                <kendo-dropdownlist class="page-size-dd" [data]="pageSizes"
                                    (valueChange)="onRefreshGrid()" [textField]="'text'" [valueField]="'value'"
                                    [valuePrimitive]="true" [(ngModel)]="kendogridHelper.defaultRecordsCountPerPage">
                                </kendo-dropdownlist>
                            </ng-template>
                            <ng-template kendoGridLoadingTemplate>
                                <div class="overlay-loader">
                                    <div class="form-loader"></div>
                                </div>
                            </ng-template>
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="overlay-loader" [busyIf]="isFormLoading">
    <div class="form-loader"></div>
</div>
<h1 mat-dialog-title class="image-dialog-title">
    <span>Personal Information</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="profile-image-wrapper">
    <div class="overlay-wrapper">
        <ng-container *ngIf="profileFormPayload.file; else defaultImage">
            <div class="profile-image" [ngStyle]="{'background-image': 'url(' + profileFormPayload.file + ')'}">
            </div>
        </ng-container>
        <ng-template #defaultImage>
            <div class="profile-image"
                [ngStyle]="{'background-image': 'url('+ (profileFormPayload.url? profileFormPayload.url : 'assets/images/profile-img.png') + ')'}">
            </div>
        </ng-template>
        <div class="overlay-content">
            <label for="file-input" matTooltip="Upload image">
                <img src="assets/images/camera-icon.svg" role="button" />
            </label>
            <img src="assets/images/close-icon.svg" class="pt-2" role="button" matTooltip="Delete image"
                *ngIf="profileFormPayload.file" (click)="onClearImage()" />
            <input hidden type="file" id="file-input" class="camera-input-file" [accept]="allowedExtensionsString"
                ng2FileSelect (change)="onChangeFile($event)" />
        </div>
    </div>
</div>
<div class="form-dialog-wrapper profile-info-dialog-wrapper">
    <form #profileInfoForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="profileInfoForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper pt-5">
            <div class="row pt-5">
                <div class="col-6 ps-0">
                    <div class="form-field">
                        <div class="label-text">Name <sup>*</sup></div>
                        <kendo-textbox [(ngModel)]="profileFormPayload.name" name="name" autocomplete="off"
                            [maxlength]="100" pattern="^[\w\s]+$" RestrictPattern required>
                        </kendo-textbox>
                    </div>
                </div>
                <div class="col-6 pe-0">
                    <div class="form-field">
                        <div class="label-text">Email</div>
                        <kendo-textbox [(ngModel)]="profileFormPayload.email" name="email" autocomplete="off"
                            [disabled]="true">
                        </kendo-textbox>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 ps-0">
                    <div class="form-field">
                        <div class="label-text">Role</div>
                        <kendo-textbox [(ngModel)]="profileFormPayload.user_role.name" name="role" autocomplete="off"
                            [disabled]="true">
                        </kendo-textbox>
                    </div>
                </div>
                <div class="col-6 pe-0">
                    <div class="form-field">
                        <div class="label-text">Group</div>
                        <kendo-textbox [(ngModel)]="profileFormPayload.group" name="group" autocomplete="off"
                            [disabled]="true">
                        </kendo-textbox>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-field">
                        <div class="label-text">Notifications <sup>*</sup></div>
                        <kendo-multiselect [data]="notificationDataSource" [textField]="'value'" [valueField]="'key'"
                            [valuePrimitive]="false" [filterable]="true"
                            [kendoDropDownFilter]="commonSettings.dropDownFilterSettings" placeholder="Select"
                            [loading]="isNotificationsLoading" [(ngModel)]="profileFormPayload.notifications"
                            name="notifications" required>
                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                <span class="template" [title]="dataItem.title">{{ dataItem.value }}</span>
                            </ng-template>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onShowProfileInfo()">
                Change Password
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
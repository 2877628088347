export class ReportDataModel {

    public reportFormPayload: any = {
        isReportGenerated: false,
        isBuildReport: false,
        leadline_form: false,
        fileName: null,
        url: null,
        reportGeneratedDateTime: null,
        leadline_sections: [] as any[],
        sizing: {
            is_completed: false,
            message: ''
        },
        drawing: {
            is_completed: false,
            message: ''
        },
        pricing: {
            is_completed: false,
            message: ''
        }
    }

    public reportEmailFormPayload: any = {
        value: [] as any[]
    }

}
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonService } from 'src/app-core/services/common.service';
import { EditStatusInformationComponent } from 'src/app/lead-details/edit-status-information/edit-status-information.component';

@Component({
    selector: 'app-lead-status-info',
    templateUrl: './lead-status-info.component.html'
})
export class LeadStatusInfoComponent implements OnInit {

    componentSubscriptions: Subscription = new Subscription();
    leadDetailsModel: any;
    paramLeadId: any;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set data(list: any) {
        this.leadDetailsModel = list;
    }

    constructor(
        private activeRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private router: Router,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramLeadId = params.get('leadId');
        }));
    }

    onLoadItems(): void {
        this.commonService.getLeadDetails(this.paramLeadId).subscribe(response => {
            this.leadDetailsModel = response?.data.status_info;
        });
    }

    isLeadDetailsRoute(): boolean {
        return this.router.url.includes('lead-details');
    }

    onShowEditStatusInformation(): void {
        this.matDialog.open(EditStatusInformationComponent, {
            position: {
                right: '0',
            },
            panelClass: 'sm-dialog-wrapper',
            hasBackdrop: false,
            data: {
                formType: CommonConstants.formTypeConstants.edit,
                leadId: this.paramLeadId
            }
        }).afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result?.confirmResult) {
                this.onLoadItems();
            }
        });
    }

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LeadDetailsService } from 'src/app-core/services/lead-details.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-issue-form',
    templateUrl: './issue-form.component.html'
})
export class IssueFormComponent {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel()
    dateTimeFormat = this.commonSettings.dateTimeFormat;
    issueFormPayload = _.cloneDeep(this.commonDataModel.issueFormPayload);
    issueFormLoaderModel = _.cloneDeep(this.commonDataModel.issueFormLoaderModel);
    leadLineSourceList: any[] = [];
    callLineSourceList: any[] = [];
    respondersSourceList: any[] = [];
    dueDateTime = new Date();
    minDate = new Date();
    moduleType: string = '';
    serviceId: any;
    isNestedService = false;
    commonConstants: typeof CommonConstants = CommonConstants;
    allowedExtensions = ['png', 'jpeg', 'bmp', 'jpg', 'svg'];
    allowedExtensionsString = `.${this.allowedExtensions.join(',.')}`;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private leadDetailsService: LeadDetailsService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<IssueFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.isNestedService = data?.isNestedService;
            this.moduleType = data?.moduleType;
            this.issueFormPayload.lead_id = parseInt(data?.leadId);
            this.issueFormPayload.call_id = data?.callId;
            this.issueFormPayload.call_line_id = data.calllineId;
            this.onLoadItems();
        }
    }

    onLoadItems(): void {
        this.issueFormLoaderModel.isResponderLoading = true;
        this.commonService.getActiveUserList()
            .pipe(finalize(() => this.issueFormLoaderModel.isResponderLoading = false))
            .subscribe(response => {
                this.respondersSourceList = response?.data;
            });

        this.issueFormLoaderModel.isLeadLineLoading = true;
        this.leadDetailsService.getLeadLineList(this.issueFormPayload.lead_id)
            .pipe(finalize(() => this.issueFormLoaderModel.isLeadLineLoading = false))
            .subscribe(response => {
                this.leadLineSourceList = response?.data;
            });
        this.leadDetailsService.getCallLineList(this.issueFormPayload.lead_id)
            .pipe(finalize(() => this.issueFormLoaderModel.isLeadLineLoading = false))
            .subscribe(response => {
                this.callLineSourceList = response?.data;
            });
        if (this.isNestedService) {
            this.serviceId = `${this.issueFormPayload.call_id} - ${this.issueFormPayload.call_line_id}`;
        } else {
            this.serviceId = this.issueFormPayload.call_id;
        }
    }

    onFormSubmit() {
        if (this.dueDateTime) {
            this.issueFormPayload.due_date_time = this.commonMethod.convertDateTime(this.dueDateTime);
        }
        if (this.issueFormPayload.leadline_id === 'Select') {
            this.issueFormPayload.leadline_id = 0;
        }
        if (!this.issueFormPayload.file.fileName) {
            this.issueFormPayload.file = null;
        }
        if (this.issueFormPayload.issue_body) {
            this.issueFormLoaderModel.isFormLoading = true;
            const comment = this.commonMethod.commentValidation(this.issueFormPayload.issue_body);
            if (comment) {
                this.issueFormPayload.issue_body = comment;
                this.issueFormPayload.users = this.commonMethod.getTaggedUsers();
            }
            this.leadDetailsService.postIssueForm(this.issueFormPayload)
                .pipe(finalize(() => this.issueFormLoaderModel.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    this.dialogRef.close({ confirmResult: true });
                });
        }
    }

    async onChangeFile(event: any) {
        try {
            const resultData = await this.commonMethod.uploadFileValidation(event);
            if (resultData.isFileValid) {
                this.commonMethod.objectMapping(this.issueFormPayload.file, resultData);
            } else {
                this.issueFormPayload.file = _.cloneDeep(this.commonDataModel.fileModel);
            }
        } catch (error: any) {
            this.customToastrService.error(error);
        }
    }

    onChangeLeadLine(event: any): void {
        if (event.leadline_id === 'Select') {
            this.issueFormPayload.leadline_id = 0;
            this.issueFormPayload.leadline_description = '';
        } else {
            this.commonMethod.objectMapping(this.issueFormPayload, event);
        }
    }

    onChangeCallId(event: any): void {
        if (event.call_id === 'Select') {
            this.issueFormPayload.call_line_id = 0;
            this.issueFormPayload.call_id = '';
        } else {
            this.commonMethod.objectMapping(this.issueFormPayload, event);
        }
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

<div class="document-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <div class="search-wrapper">
        <kendo-textbox [(ngModel)]="documentSearch" name="documentSearch" autocomplete="off" [maxlength]="20"
            placeholder="Search for Request ID" (ngModelChange)="onSearchChange(documentSearch)"
            (keydown.enter)="onKeyDownEvent()">
            <ng-template kendoTextBoxPrefixTemplate>
                <span class="icon-wrapper">
                    <img src="assets/images/search-icon.svg" height="14" />
                </span>
            </ng-template>
            <ng-template kendoTextBoxSuffixTemplate *ngIf="documentSearch?.length">
                <span class="icon-wrapper" role="button" (click)="onResetFilter()">
                    <img src="assets/images/close-icon.svg" height="20" />
                </span>
            </ng-template>
        </kendo-textbox>
    </div>
    <div class="document-content-wrapper" infinite-scroll [infiniteScrollUpDistance]="10" [scrollWindow]="false"
        (scrolled)="onScrollDown()">
        <ng-container *ngIf="documentList.length">
            <div class="row" *ngFor="let item of documentList; let i=index;">
                <div class="col-8 p-0">
                    <div class="img-wrapper" [ngClass]="{'disable-click': isDisabled}">
                        <img [src]="'assets/images/'+onSetDocumentExtension(item)+'.svg'" />
                        <a class="title-text" target="_blank" href="{{item.url}}">
                            {{item?.file_name}}
                        </a>
                        <div class="date-text">{{item?.time}}</div>
                    </div>
                </div>
                <div class="col-4 p-0 text-end">
                    <div class="text-sm">Request ID: {{item?.id}}</div>
                </div>
            </div>
        </ng-container>
        <div class="no-record-text mt-2" *ngIf="!isFormLoading && !documentList.length">
            {{commonConstants.appConstants.noRecordsAvailable}}
        </div>
    </div>
</div>
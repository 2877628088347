import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'dropdownValueCheck'
})
export class DropdownValueCheckPipe implements PipeTransform {

    transform(items: any[], value: string): any[] {

        if (items?.length && value) {
            const results = _.find(items, ['key', value]);
            if (results) {
                return new Array(results);
            } else {
                return [{ value: 'N/A' }];
            }
        } else {
            return [{ value: 'N/A' }];
        }

    }

}

<h1 mat-dialog-title>
    <span>Create Issue</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper">
    <div class="overlay-loader" [busyIf]="issueFormLoaderModel.isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #issueForm="ngForm" novalidate autocomplete="off" (ngSubmit)="issueForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="form-field">
                <div class="label-text">Responders <sup>*</sup></div>
                <kendo-dropdownlist [data]="respondersSourceList" [textField]="'name'" [valueField]="'id'"
                    [defaultItem]="commonSettings.defaultItems.idName" [valuePrimitive]="true" [filterable]="true"
                    [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                    [loading]="issueFormLoaderModel.isResponderLoading" [(ngModel)]="issueFormPayload.responder_id"
                    name="responder_id" required>
                </kendo-dropdownlist>
            </div>
            <div class="form-field">
                <div class="label-text">When do you need this answer by? <sup>*</sup></div>
                <kendo-datetimepicker [(ngModel)]="dueDateTime" name="dueDateTime" [min]="minDate"
                    [readOnlyInput]="true" [format]="dateTimeFormat" [placeholder]="dateTimeFormat" required>
                </kendo-datetimepicker>
            </div>
            <div class="form-field">
                <div class="label-text">Issue Subject <sup>*</sup></div>
                <kendo-textbox [(ngModel)]="issueFormPayload.subject" name="subject" autocomplete="off"
                    [maxlength]="100" required>
                </kendo-textbox>
            </div>
            <div class="form-field">
                <div class="label-text">LeadLine</div>
                <kendo-dropdownlist [data]="leadLineSourceList" [textField]="'leadline_id'" [valueField]="'leadline_id'"
                    [defaultItem]="commonSettings.defaultItems.leadlineId" [valuePrimitive]="true" [filterable]="true"
                    [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                    [loading]="issueFormLoaderModel.isLeadLineLoading" [(ngModel)]="issueFormPayload.leadline_id"
                    name="leadline_id" placeholder="Select" (selectionChange)="onChangeLeadLine($event)" required>
                </kendo-dropdownlist>
            </div>
            <div class="form-field">
                <div class="label-text">Leadline Description</div>
                <kendo-textbox [(ngModel)]="issueFormPayload.leadline_description" name="leadline_description"
                    autocomplete="off" [disabled]="true">
                </kendo-textbox>
            </div>
            <div class="form-field" *ngIf="moduleType === commonConstants.moduleConstants.leadDetails">
                <div class="label-text">Associated Service Request</div>
                <kendo-dropdownlist [data]="callLineSourceList" [textField]="'call_id'" [valueField]="'call_id'"
                    [defaultItem]="commonSettings.defaultItems.callId" [valuePrimitive]="true" [filterable]="true"
                    [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                    [loading]="issueFormLoaderModel.isLeadLineLoading" [(ngModel)]="issueFormPayload.call_id"
                    name="call_id" placeholder="Select" (selectionChange)="onChangeCallId($event)" [disabled]="false">
                </kendo-dropdownlist>
            </div>
            <div class="form-field" [hidden]="moduleType === commonConstants.moduleConstants.leadDetails">
                <div class="label-text">Service ID</div>
                <kendo-textbox [(ngModel)]="serviceId" name="call_id" autocomplete="off" [disabled]="true">
                </kendo-textbox>
            </div>
            <div class="form-field">
                <div class="label-text">Comment <sup>*</sup></div>
                <div [ngClass]="{'error-field': !issueFormPayload.issue_body.length && issueForm.submitted}">
                    <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false" [dropDownList]="respondersSourceList"
                        [(ngModel)]="issueFormPayload.issue_body" name="issue_body">
                    </app-tiny-editor>
                </div>
            </div>
            <div class="form-field">
                <div class="label-text">Upload Document</div>
                <label for="myFile" class="custom-input-file">
                    <input type="file" id="myFile" name="filename" class="input-file" ng2FileSelect
                        (change)="onChangeFile($event)">
                </label>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Submit
            </button>
        </div>
    </form>
</div>
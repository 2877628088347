import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { GridDataModel } from 'src/app-core/data-model/grid-data-model';

@Component({
    selector: 'app-drawing-list',
    templateUrl: './drawing-list.component.html'
})
export class DrawingListComponent implements OnInit {

    gridDataModel = new GridDataModel();
    drawingGridColumnModel = _.cloneDeep(this.gridDataModel.sizingGridColumnModel);
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor() {
    }

    ngOnInit(): void {
    }

}

<h1 mat-dialog-title>
    <span>Create New Filter</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper conformation-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #gridFilterForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="gridFilterForm.form.valid && onFormSubmit()">
        <div class="form-field">
            <div class="label-text">Report Name <sup>*</sup></div>
            <kendo-textbox [(ngModel)]="gridFilterFormPayload.filter_name" name="filter_name" autocomplete="off"
                [maxlength]="100" required>
            </kendo-textbox>
        </div>
        <div class="form-field">
            <mat-checkbox color="accent" [(ngModel)]="gridFilterFormPayload.is_default_filter" name="is_default_filter">
                My Default Report
            </mat-checkbox>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
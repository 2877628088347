import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonConstants } from '../constants/common-constants';
import { ConformationDialogEnum } from '../enums/common-enum';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class CommonService {

    leadId: any;
    leadlineId: any;
    requestId: any;

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    setLeadId(leadId: any) {
        this.leadId = leadId;
    }

    setLeadlineId(leadlineId: any) {
        this.leadlineId = leadlineId;
    }

    setRequestId(requestId: any) {
        this.requestId = requestId;
    }

    getUserFormRecord(userId: string): Observable<any> {
        return this.httpCommonService.getRecord(`user/details/${userId}`);
    }

    getGridFilters(gridName: string): Observable<any> {
        return this.httpCommonService.getRecord(`filter/${gridName}`);
    }

    postGridFilters(gridName: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`filter/${gridName}`, inputData);
    }

    postGridRecords(leadId: string, leadlineId: string, gridName: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`requests/grid/${leadId}/${leadlineId}/${gridName}`, inputData);
    }

    postLeadLineGridRecords(leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline/${leadId}`, inputData);
    }

    getLeadDetails(leadId: string): Observable<any> {
        return this.httpCommonService.getRecordId('dashboard/lead/', `${leadId}`);
    }

    getSyncDealDetails(leadId: string): Observable<any> {
        return this.httpCommonService.getRecordId(`sync_deal_details/`, `${leadId}`);
    }

    getLeadLineDetails(leadId: string, leadLineId: string): Observable<any> {
        return this.httpCommonService.getRecord(`leadlinedetails/${leadId}/${leadLineId}`);
    }

    getActiveUserList(): Observable<any> {
        return this.httpCommonService.getRecord('users/all');
    }

    postAllCommentsRecords(requestId: string, type: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`requests/comments/${this.leadId}/${this.leadlineId}/${type}/${requestId}`, inputData);
    }

    postAllThreadRecords(type: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`requests/activity/${this.leadId}/${this.leadlineId}/${type}`, inputData);
    }

    postCommentsPin(type: string, requestId: string, inputData: object): Observable<any> {
        let endPoint = 'requests/comments/pinned';
        if (type === CommonConstants.gridNameConstants.issuesGrid) {
            endPoint = 'issue/pinned';
        }
        return this.httpCommonService.postRecord(`${endPoint}/${requestId}`, inputData);
    }

    postAddComment(type: string, requestId: string, inputData: object): Observable<any> {
        let endPoint = 'comments/documentrequest';
        if (type === CommonConstants.gridNameConstants.issuesGrid) {
            endPoint = 'comments/issue';
        }
        return this.httpCommonService.postRecord(`${endPoint}/${requestId}`, inputData);
    }

    postEditComment(commentId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`comments/${commentId}`, inputData);
    }

    deleteSupportDocumentById(supportDocId: string): Observable<any> {
        return this.httpCommonService.deleteRecord(`documents/${supportDocId}`, {});
    }

    postAllDocumentRecords(type: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`requests/archive/${this.leadId}/${this.leadlineId}/${type}`, inputData);
    }

    postAllLogRecords(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-activity/lead/${this.leadId}/leadline/${this.leadlineId}`, inputData);
    }

    postChangeLogGridRecords(leadId: string, leadlineId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`activity/lead/${leadId}/leadline/${leadlineId}`, inputData);
    }

    getModelNumerList(leadId: string, leadlineId: string): Observable<any> {
        return this.httpCommonService.getRecord(`requests/models/${leadId}/${leadlineId}`);
    }

    postApproveRequestForm(requestData: any, inputData: object): Observable<any> {
        const results = (requestData.requestType === ConformationDialogEnum.approveRequest ? 'approve' : 'reject');
        return this.httpCommonService.postRecord(`requests/${results}/${requestData.requestId}`, inputData);
    }

    postChancelRequest(requestId: string): Observable<any> {
        return this.httpCommonService.patchRecord(`requests/cancel/${requestId}`, {});
    }

    postUploadSizingDocumentForm(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord(`requests/upload_sizing/${inputData?.doc_req_id}`, inputData);
    }

    getBreakoutFileList(requestId: string): Observable<any> {
        return this.httpCommonService.getRecord(`requests/breakout_drawings/${requestId}`);
    }

    deleteBreakoutFileById(id: string): Observable<any> {
        return this.httpCommonService.getRecord(`requests/delete_breakout_drawings/${id}`);
    }

    postUploadDrawingDocumentForm(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord(`requests/upload_drawing/${inputData?.doc_req_id}`, inputData);
    }

    postUploadPricingDocumentForm(requestId: string, inputData: any): Observable<any> {
        return this.httpCommonService.postRecord(`requests/upload/${requestId}`, inputData);
    }

    postUploadSupportingDocumentForm(requestId: string, inputData: any): Observable<any> {
        return this.httpCommonService.postRecord(`documents/documentrequest/${requestId}`, inputData);
    }

    postUpdatePrice(requestId: string, inputData: any): Observable<any> {
        return this.httpCommonService.postRecord(`requests/update_price/${requestId}`, inputData);
    }

    postReviseDueDateForm(requestId: string, inputData: object): Observable<any> {
        return this.httpCommonService.patchRecord(`requests/change/${requestId}`, inputData);
    }

    postIssueReviseDueDate(requestId: string, inputData: any): Observable<any> {
        return this.httpCommonService.patchRecord(`issues/change/${requestId}`, inputData);
    }

    postReassignUserForm(requestId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`re-assignto/${requestId}`, inputData);
    }

    postIssueReassignUserForm(requestId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`issue-re-assigned/${requestId}`, inputData);
    }

    getUserFormSettings(): Observable<any> {
        return this.httpCommonService.getRecord('user_setting');
    }

    getLeadLineFields(): Observable<any> {
        return this.httpCommonService.getRecord('leadline-form-fields-2');
    }

    postRecentActivityRecords(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`requests/comments/${this.leadId}/${this.leadlineId}/all`, inputData);
    }

    postIssueComments(leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`issue/comments/${leadId}`, inputData);
    }

    postIssueCommentsById(leadId: string, issueId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`issue/comments/${leadId}/0/${issueId}`, inputData);
    }

    postResolveIssueForm(issueId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`comments/issue/${issueId}`, inputData);
    }

    getFieldsCheck(byPassDrawingValue = 0): Observable<any> {
        return this.httpCommonService.getRecord(`fields_check/${this.leadId}/${this.leadlineId}/${byPassDrawingValue}`);
    }

    downloadFile(url: any): Observable<any> {
        return this.httpCommonService.downloadFile(url);
    }

    getLogsNotification(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('leadlinenotifications', inputData);
    }

    postReadLogsNotification(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('readleadlinenotifications', inputData);
    }

    getIdInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/id-info/${this.leadId}`);
    }

    postRevenueInfoForm(inputData: object, leadId: any, leadlineId: any): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/revenueinfo/${leadId}/${leadlineId}`, inputData);
    }

    getDocTypeData(leadId: string, type: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`service-documents/${leadId}/${type}`, inputData);
    }

    getGridFilter(gridName: string): Observable<any> {
        return this.httpCommonService.getRecord(`get-grid-filter/${gridName}`);
    }

    postGridFilterForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`post-grid-filter`, inputData);
    }

    updateGridFilter(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`update-grid-filter`, inputData);
    }

    deleteGridFilter(inputData: object): Observable<any> {
        return this.httpCommonService.deleteRecord(`delete-grid-filter`, inputData);
    }

    getUsersList(inputData: any): Observable<any> {
        return this.httpCommonService.getRecord(`users/all/${inputData}`);
    }

    getAnnouncementsGrid(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`announcementgrid`, inputData);
    }

    postAnnouncements(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`create-announcement`, inputData);
    }

    fetchAnnouncements(id: any): Observable<any> {
        return this.httpCommonService.getRecord(`fetch-announcement/${id}`);
    }

    updateAnnouncements(id: any, inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`update-announcement/${id}`, inputData);
    }

    acknowledgeAnnouncement(): Observable<any> {
        return this.httpCommonService.postRecord(`acknowledged-announcement`, {});
    }

    latestAnnouncement(): Observable<any> {
        return this.httpCommonService.getRecord(`latest-announcement`);
    }

    postLead(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`create-lead`, inputData);
    }

    getLeadDetailsRevenue(): Observable<any> {
        return this.httpCommonService.getRecord(`dashboard/lead/${this.leadId}`);
    }

    getShippingAddress(company_id: any): Observable<any> {
        return this.httpCommonService.getRecord(`get-shipping-address/${company_id}`);
    }

}

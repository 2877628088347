<h1 mat-dialog-title>
    <span>
        Create Lead
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper lead-form-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #createLeadForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="createLeadForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="lead-tab-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="form-field mb-3">
                            <div class="label-text">Project Name <sup>*</sup></div>
                            <kendo-textbox [(ngModel)]="leadFormPayload.dealname" name="dealname" autocomplete="off"
                                [maxlength]="250" required>
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-field mb-3">
                            <div class="label-text">Description <sup>*</sup></div>
                            <kendo-textarea [(ngModel)]="leadFormPayload.long_description" name="long_description"
                                [rows]="6" autocomplete="off" resizable="vertical" required>
                            </kendo-textarea>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Company <sup>*</sup></div>
                            <kendo-dropdownlist [data]="companiesDataList" [textField]="'name'" [valueField]="'id'"
                                [valuePrimitive]="true" [loading]="isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.nameId0" [filterable]="true"
                                [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                [(ngModel)]="leadFormPayload.company_id" name="company"
                                (valueChange)="onCompanyChange($event)" placeholder="Select" required>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">City, State</div>
                            <kendo-textbox [(ngModel)]="cityState" name="cityState" autocomplete="off"
                                [disabled]="true"></kendo-textbox>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-field mb-3">
                            <div class="label-text">Address</div>
                            <kendo-textbox [(ngModel)]="address" name="address" autocomplete="off"
                                [disabled]="true"></kendo-textbox>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Country</div>
                            <kendo-textbox [(ngModel)]="country" name="country" autocomplete="off"
                                [disabled]="true"></kendo-textbox>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Zip code</div>
                            <kendo-textbox [(ngModel)]="zipcode" name="zipcode" autocomplete="off"
                                [disabled]="true"></kendo-textbox>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="shippingAddressDataList.length > 0 else projectShippingAddress">
                        <div class="form-field mb-3">
                            <div class="label-text">Project Shipping Address <sup>*</sup></div>
                            <kendo-dropdownlist [data]="shippingAddressDataList" [textField]="'value'"
                                [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.KeyValue"
                                [(ngModel)]="leadFormPayload.shipping_address_id" name="project_shipping_address"
                                placeholder="Select" required>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <ng-template #projectShippingAddress>
                        <div class="col-12">
                            <div class="form-field mb-3">
                                <div class="label-text">Project Shipping Address</div>
                                <kendo-dropdownlist [data]="shippingAddressDataList" [textField]="'value'"
                                    [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                    [defaultItem]="commonSettings.defaultItems.KeyValue"
                                    [(ngModel)]="leadFormPayload.shipping_address_id"
                                    name="project_shipping_address" placeholder="Select">
                                </kendo-dropdownlist>
                            </div>
                        </div>
                    </ng-template>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Month Of Budget Year End</div>
                            <kendo-dropdownlist [data]="budgetYearEndDataList" [textField]="'value'"
                                [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.KeyValue"
                                [(ngModel)]="leadFormPayload.month_of_budget_year_end" name="month_of_budget_year_end"
                                placeholder="Select">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Marketing Program</div>
                            <kendo-dropdownlist [data]="marketingBudgetYearDataList" [textField]="'key'"
                                [valueField]="'value'" [valuePrimitive]="true" [loading]="isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.fieldsValueKey"
                                [(ngModel)]="leadFormPayload.marketing_program" name="marketing_program"
                                placeholder="Select">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Business Development</div>
                            <kendo-dropdownlist [data]="businessDevelopmentDataList" [textField]="'key'"
                                [valueField]="'value'" [valuePrimitive]="true" [loading]="isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.fieldsValueKey"
                                [(ngModel)]="leadFormPayload.business_development" name="business_development"
                                placeholder="Select">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Rep Commission Level</div>
                            <kendo-dropdownlist [data]="repCommissionLevelDataList" [textField]="'value'"
                                [valueField]="'key'" [valuePrimitive]="true"
                                [(ngModel)]="leadFormPayload.rep_commission_level"
                                [defaultItem]="commonSettings.defaultItems.KeyValue" name="rep_commission_level">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Sales Person Confidence</div>
                            <kendo-dropdownlist [data]="salesPersonConfidenceDataList" [textField]="'value'"
                                [valueField]="'key'" [valuePrimitive]="true"
                                [(ngModel)]="leadFormPayload.salesperson_confidence"
                                [defaultItem]="commonSettings.defaultItems.KeyValue" name="salesperson_confidence">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Referred By</div>
                            <kendo-textbox [(ngModel)]="leadFormPayload.referred_by" name="referred_by"
                                [maxlength]="250" autocomplete="off">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Lead Stage</div>
                            <kendo-dropdownlist [data]="leadStageDataSource" [(ngModel)]="leadFormPayload.deal_stage"
                                [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
                                [defaultItem]="commonSettings.defaultItems.textValue" name="deal_stage">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Lead Stage Date</div>
                            <kendo-datepicker [(ngModel)]="leadFormPayload.deal_stage_date" name="deal_stage_date"
                                [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                [navigation]="false">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Bid Date</div>
                            <kendo-datepicker [(ngModel)]="leadFormPayload.bid_date" name="bid_date"
                                [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                [navigation]="false">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Expected Close</div>
                            <kendo-datepicker [(ngModel)]="leadFormPayload.deal_closed_date" name="deal_closed_date"
                                [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                [navigation]="false" [min]="minDate">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Validity Expiration Date</div>
                            <kendo-datepicker [(ngModel)]="leadFormPayload.expiration_date" name="expiration_date"
                                [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                [navigation]="false" [min]="minDate">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">RSM</div>
                            <kendo-textbox [(ngModel)]="leadFormPayload.deal_owner" name="deal_owner" autocomplete="off"
                                [disabled]="true">
                            </kendo-textbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Back
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { DefaultResultsPerPage, DeletedValueEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { ContactCompanyService } from 'src/app-core/services/contact-company.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {

	customPatterns = new CustomPatterns();
	commonSettings = new CommonSettings();
	commonDataModel = new CommonDataModel();
	contactFormPayload = _.cloneDeep(this.commonDataModel.contactFormPayload);
	contactFormLoaderModel = _.cloneDeep(this.commonDataModel.contactFormLoaderModel);
	resultPerPage = DefaultResultsPerPage.value;
	companyList: any[] = [];
	removedAssociations: any[] = [];
	industryTypeSourceList: any;
	confirmationResult: any;
	selectedCompany: any;
	isActive = false;
	isRestore = false;
	contactEmailExist = false;
	formType: string = '';
	newCompany!: {
		id: number;
		company_id: number;
		is_primary: boolean;
		name: string;
		industry_type: string;
		is_removed: boolean;
	};
	companyIds: any[] = [];
	commonConstants: typeof CommonConstants = CommonConstants;

	constructor(
		private commonMethod: CommonMethod,
		private customToastrService: CustomToastrService,
		private contactService: ContactCompanyService,
		private dialogRef: MatDialogRef<ContactFormComponent>,
		@Inject(MAT_DIALOG_DATA) data?: any) {
		if (data) {
			this.contactFormPayload.contact_id = data?.contactId;
			this.contactFormPayload.id = data?.id;
			this.formType = data?.formType;
			this.onLoadItems();
		}
	}

	onLoadItems(): void {
		this.contactFormLoaderModel.isFormLoading = true;
		if (this.formType === CommonConstants.formTypeConstants.add) {
			this.contactFormPayload.contact_id = '';
			this.contactService.getContactId()
				.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
				.subscribe(response => {
					this.contactFormPayload.contact_id = response?.lastContactID;
				});
		} else {
			this.contactService.getAllCompanyList()
				.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
				.subscribe(response => {
					this.companyList = response.data.map((company: any) => ({
						...company,
						isDisabled: false
					}));
				});
			this.contactService.getContactFormEdit(this.contactFormPayload.id)
				.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
				.subscribe(response => {
					if (response.data) {
						this.commonMethod.objectMapping(this.contactFormPayload, response.data);
						this.contactFormPayload.association.forEach((association: { id: any; }) => {
							const contact = this.companyList.find(c => c.id === association.id);
							if (contact) {
								contact.isDisabled = true;
							}
						});
						this.isRestore = (this.contactFormPayload.is_deleted === DeletedValueEnum.deletedRestoreValue ? true : false);
						if (this.contactFormPayload && this.contactFormPayload.association && Array.isArray(this.contactFormPayload.association)) {
							this.contactFormPayload.association.forEach((item: { is_primary: boolean; rowClass: string; }) => {
								item.rowClass = item.is_primary ? 'primary' : '';
							});
						}
					}
				});
		}
		this.contactService.getIndustryType()
			.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
			.subscribe(response => {
				this.industryTypeSourceList = response.data.dd_fields.industry_type;
			});
	}

	async onShowArchiveConfirmation() {
		const confirmationMessage = this.isRestore
			? CommonConstants.appConstants.restoreConfirmationDialog
			: CommonConstants.appConstants.archiveConfirmationDialog;

		this.confirmationResult = await this.commonMethod.asyncOpenDeleteConformationDialog(`${confirmationMessage}`);

		if (this.confirmationResult) {
			this.postArchiveData();
		}
	}

	postArchiveData() {
		this.contactFormLoaderModel.isFormLoading = true;
		this.isActive = (this.contactFormPayload.is_deleted === DeletedValueEnum.deletedArchiveValue ? false : true);
		const payload = {
			contact_id: this.contactFormPayload.id,
			is_active: this.isActive
		}
		this.contactService.getArchiveStatus(payload)
			.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
			.subscribe(response => {
				this.isRestore = !this.isRestore;
				this.customToastrService.success(response?.message);
				this.dialogRef.close({ confirmResult: true });
			});
	}

	onChangeValidateEmail() {
		const payload = {
			email: this.contactFormPayload.email,
			id: this.contactFormPayload.id
		}
		this.contactFormLoaderModel.isFormLoading = true;
		this.contactService.postCheckEmailContact(payload)
			.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
			.subscribe(response => {
				this.contactEmailExist = (response?.exist ? true : false);
			});
	}

	addCompanies() {
		if (this.selectedCompany) {
			const company = this.companyList.find(c => c.id === this.selectedCompany);

			if (company) {
				this.newCompany = {
					id: company.id,
					company_id: company.company_id,
					is_primary: false,
					name: company.name,
					industry_type: company.industry_type,
					is_removed: false
				};

				this.contactFormPayload.association.push(this.newCompany);
				this.contactFormPayload.association_changed = true;
				company.isDisabled = true;
			}

			this.selectedCompany = null;
		}
	}

	togglePrimaryCompany(index: number) {
		this.contactFormPayload.association.forEach((item: { is_primary: boolean; rowClass: string; }, i: number) => {
			item.is_primary = i === index;
			item.rowClass = item.is_primary ? 'primary' : '';
		});
		this.contactFormPayload.association_changed = true;
	}

	onDeleteAssociations(indexArray: number[]) {
		indexArray.sort((a, b) => b - a);
		const removedObjects: any[] = [];
		indexArray.forEach(index => {
			const association = this.contactFormPayload.association[index];
			if (Array.isArray(association)) {
				removedObjects.push(...association.filter(obj => typeof obj === 'object' && obj !== null));
				this.contactFormPayload.association.splice(index, 1);
			} else if (typeof association === 'object' && association !== null) {
				association.is_removed = true;
				association.is_primary = false;
				removedObjects.push(association);
				this.contactFormPayload.association.splice(index, 1);
			}
		});

		removedObjects.forEach(removedObject => {
			const contact = this.companyList.find(c => c.id === removedObject.id);
			if (contact) {
				contact.isDisabled = false;
			}
		});

		this.removedAssociations.push(...removedObjects);
		this.contactFormPayload.association_changed = true;
	}

	onFormSubmit() {
		this.contactFormLoaderModel.isFormLoading = true;
		if (this.formType === CommonConstants.formTypeConstants.add) {
			this.contactService.postContactForm(this.contactFormPayload)
				.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
				.subscribe(response => {
					this.customToastrService.success(response?.message);
					this.dialogRef.close({ confirmResult: true });
				});
		} else {
			if (this.contactFormPayload.association.length > 0) {
				const isAtLeastOneCheckboxChecked = this.contactFormPayload.association.some((company: any) => company.is_primary);
				if (!isAtLeastOneCheckboxChecked) {
					this.contactFormLoaderModel.isFormLoading = false;
					this.customToastrService.warning("Please select the primary company");
					return;
				} else {
					this.updateContactForm();
				}
			} else {
				this.updateContactForm();
			}
		}
	}

	updateContactForm() {
		if (this.newCompany && this.removedAssociations.length > 0 && this.contactFormPayload.association.length === 0) {
			this.contactFormPayload.association_changed = false;
		}
		if (!this.newCompany && this.contactFormPayload.association_changed) {
			const filteredRemovedAssociations = this.removedAssociations.filter(item => typeof item === 'object');
			this.contactFormPayload.association.push(...filteredRemovedAssociations);
		}
		const payloadToSend = { ...this.contactFormPayload };
		if (!payloadToSend.association_changed) {
			delete payloadToSend.association;
		}
		this.contactService.updateContactForm(payloadToSend.id, payloadToSend)
			.pipe(finalize(() => this.contactFormLoaderModel.isFormLoading = false))
			.subscribe(response => {
				if (response?.isWarning) {
					this.customToastrService.warning(response?.message);
				} else {
					this.customToastrService.success(response?.message);
				}
				this.dialogRef.close({ confirmResult: true });
			});
	}

	itemDisabled(itemArgs: { dataItem: any }) {
		return itemArgs.dataItem?.isDisabled;
	}

	onClose() {
		this.dialogRef.close({ confirmResult: false });
	}

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'negativeValueCheck'
})
export class NegativeValueCheckPipe implements PipeTransform {

    transform(value: any): any {
        if (value) {
            if (value === '1' || value === '-1') {
                return 'Yes';
            } else if (value === '0') {
                return 'No';
            } else {
                return 'N/A';
            }
        } else {
            return 'N/A';
        }
    }

}

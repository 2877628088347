import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Subscription, finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';

@Component({
    selector: 'app-all-documents-list',
    templateUrl: './all-documents-list.component.html',
    styleUrls: ['./all-documents-list.component.scss']
})
export class AllDocumentsListComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    virtualScrollPayload: any;
    documentType: any;
    isFormLoading = false;
    isDisabled = false;
    documentList: any[] = [];
    documentSearch = '';
    currentPage = 1;
    lastPage = 1;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set type(value: string) {
        this.documentType = value;
    }

    constructor(
        private commonMethod: CommonMethod,
        private activeRoute: ActivatedRoute,
        private commonService: CommonService) {
        let virtualScrollPayload = _.cloneDeep(this.commonDataModel.virtualScrollPayload);
        this.virtualScrollPayload = Object.assign(virtualScrollPayload, { filter_value: '' });
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.onLoadItems();
        }));
        this.componentSubscriptions.add(
            this.commonMethod.getIsActiveLeadlineStatus.subscribe((data: boolean) => {
                this.isDisabled = data;
            })
        );
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.commonService.postAllDocumentRecords(this.documentType, this.virtualScrollPayload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response.data) {
                    this.currentPage = response.data?.current_page;
                    this.lastPage = response.data?.last_page;
                    this.documentList = this.virtualScrollPayload.skip_count === 0 ? response.data.data : this.documentList.concat(...response.data.data);
                }
            });
    }

    onScrollDown() {
        if (this.documentList?.length > 1) {
            const skipCount = this.commonMethod.lazyLoadingPagination(this.virtualScrollPayload?.skip_count, this.currentPage, this.lastPage);
            if (skipCount !== this.virtualScrollPayload.skip_count) {
                this.virtualScrollPayload.skip_count = skipCount;
                this.onLoadItems();
            }
        }
    }

    onSearchChange(value: string): void {
        if (value) {
            if (value?.length >= 1) {
                this.virtualScrollPayload.filter_value = value;
            } else {
                this.virtualScrollPayload.filter_value = '';
            }
        } else {
            this.onLoadItems();
        }
    }

    onKeyDownEvent(): void {
        if (this.documentSearch && this.documentSearch?.length >= 1) {
            this.virtualScrollPayload.skip_count = 0;
            this.virtualScrollPayload.filter_value = this.documentSearch;
            this.onLoadItems();
        }
    }

    onResetFilter(): void {
        this.documentSearch = '';
        this.virtualScrollPayload.skip_count = 0;
        this.virtualScrollPayload.filter_value = '';
        this.onLoadItems();
    }

    onSetDocumentExtension(dataItem: any) {
        let results = '';
        if (dataItem?.file_name) {
            const fileExtension = dataItem.file_name.substr(dataItem.file_name.lastIndexOf('.') + 1).toLowerCase();
            if (fileExtension === CommonConstants.fileExtensionConstants.pdf) {
                results = "pdf-icon";
            } else if (this.commonMethod.checkExcelFileExtension(fileExtension)) {
                results = 'excel-icon';
            } else if (this.commonMethod.checkWordFileExtension(fileExtension)) {
                results = 'word-icon';
            } else {
                results = 'document-icon';
            }
        }
        return results;
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { LeadDataModel } from 'src/app-core/data-model/lead-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LeadService } from 'src/app-core/services/lead-service.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-edit-lead-summary',
    templateUrl: './edit-lead-summary.component.html'
})
export class EditLeadSummaryComponent implements OnInit {

    commonSettings = new CommonSettings();
    leadDataModel = new LeadDataModel();
    leadSummaryPayload = _.cloneDeep(this.leadDataModel.leadSummaryPayload);
    isFormLoading = false;
    formType: any;
    hubspot_id: any;
    legacy_id: any;
    businessDevelopmentDataList: any[] = [];
    projectShippingDataList: any[] = [];

    constructor(
        private leadService: LeadService,
        private commonService: CommonService,
        private commonMethod: CommonMethod,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<EditLeadSummaryComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.formType = data?.formType;
            this.leadSummaryPayload.deal_id = data?.leadId;
            this.hubspot_id = data?.dealId;
            this.legacy_id = data.legacyId ? data.legacyId : '';
        }
    }

    ngOnInit(): void {
        this.onLoadItems();
    }

    onLoadItems() {
        this.isFormLoading = true;
        this.leadService.getLeadFields()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.businessDevelopmentDataList = response.data.dd_fields.business_development;
            });
        this.leadService.editlead(this.leadSummaryPayload.deal_id)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.commonMethod.objectMapping(this.leadSummaryPayload, response.data);
                this.getShippingDetails(response.data.company_id);
            })
    }

    getShippingDetails(companyId: any) {
        this.commonService.getShippingAddress(companyId)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.projectShippingDataList = response.data;
            });
    }

    onFormSubmit() {
        this.isFormLoading = true;
        const payloadToSend = { ...this.leadSummaryPayload };
        payloadToSend.bid_date = this.commonMethod.convertDate(this.leadSummaryPayload.bid_date);
        payloadToSend.deal_closed_date = this.commonMethod.convertDate(this.leadSummaryPayload.deal_closed_date);
        payloadToSend.deal_stage_date = this.commonMethod.convertDate(this.leadSummaryPayload.deal_stage_date);

        this.leadService.updateSummaryDetails(this.leadSummaryPayload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response?.isWarning) {
                    this.customToastrService.warning(response?.message);
                } else {
                    this.customToastrService.success(response?.message);
                }
                this.dialogRef.close({ confirmResult: true });
            });
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

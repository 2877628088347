<h1 mat-dialog-title>
    <span>New {{formTitle}} Request</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #requestForm="ngForm" novalidate autocomplete="off" (ngSubmit)="requestForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="form-field">
                <div class="label-text">Responders <sup>*</sup></div>
                <kendo-multiselect [data]="dropDownSourceList?.respondersDataSource" [textField]="'name'"
                    [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                    [kendoDropDownFilter]="commonSettings.dropDownFilterSettings" placeholder="Select"
                    [(ngModel)]="requestFormPayload.user_ids" name="user_ids" required>
                </kendo-multiselect>
            </div>
            <div class="form-field">
                <div class="label-text">Due By <sup>*</sup></div>
                <kendo-datetimepicker [(ngModel)]="requiredAt" name="requiredAt" [min]="minDate" [readOnlyInput]="true"
                    [format]="dataTimeFormat" [placeholder]="dataTimeFormat">
                </kendo-datetimepicker>
            </div>
            <ng-container *ngIf="requestFormPayload.document_type === commonConstants.leadLineConstants.pricing || 
                requestFormPayload.document_type === commonConstants.leadLineConstants.drawing">
                <div class="form-field">
                    <div class="label-text">Model Number</div>
                    <kendo-dropdownlist [data]="dropDownSourceList?.modelNumberDataSource" [textField]="'value'"
                        [valueField]="'key'" [valuePrimitive]="true"
                        [defaultItem]="commonSettings.defaultItems.KeyValue" [filterable]="true"
                        [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                        [(ngModel)]="requestFormPayload.model" name="model">
                    </kendo-dropdownlist>
                </div>
            </ng-container>
            <ng-container *ngIf="requestFormPayload.document_type === commonConstants.leadLineConstants.pricing">
                <div class="form-field">
                    <div class="label-text">Type <sup>*</sup></div>
                    <kendo-dropdownlist [data]="dropDownSourceList?.pricingTypeDataSource" [textField]="'value'"
                        [valueField]="'key'" [valuePrimitive]="true"
                        [defaultItem]="commonSettings.defaultItems.KeyValue" (valueChange)="onTypeChange($event)"
                        [(ngModel)]="requestFormPayload.price_type" name="price_type" required>
                    </kendo-dropdownlist>
                </div>
            </ng-container>
            <ng-container *ngIf="requestFormPayload.document_type === commonConstants.leadLineConstants.drawing">
                <div class="form-field">
                    <div class="label-text">Drawing Type <sup>*</sup></div>
                    <kendo-dropdownlist [data]="dropDownSourceList?.drawingLevelDataSource" [textField]="'value'"
                        [valueField]="'key'" [valuePrimitive]="true"
                        [defaultItem]="commonSettings.defaultItems.KeyValue" [(ngModel)]="requestFormPayload.level"
                        name="level" required>
                    </kendo-dropdownlist>
                </div>
            </ng-container>
            <div class="form-field">
                <div class="label-text">Report <sup>*</sup></div>
                <kendo-dropdownlist [data]="dropDownSourceList?.reportDataSource" [textField]="'value'"
                    [valueField]="'key'" [valuePrimitive]="true" [defaultItem]="commonSettings.defaultItems.KeyValue"
                    [(ngModel)]="requestFormPayload.template" name="template" required>
                </kendo-dropdownlist>
            </div>
            <div class="form-field">
                <div class="label-text">Comment <sup>*</sup></div>
                <div [ngClass]="{'error-field': !requestFormPayload.note.length && requestForm.submitted}">
                    <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false"
                        [dropDownList]="dropDownSourceList?.respondersDataSource" [(ngModel)]="requestFormPayload.note"
                        name="note">
                    </app-tiny-editor>
                </div>
            </div>
            <ng-container *ngIf="requestFormPayload.document_type === commonConstants.leadLineConstants.sizing">
                <div class="form-field">
                    <mat-checkbox color="border-checkbox" [(ngModel)]="requestFormPayload.is_pricing_needed"
                        name="is_pricing_needed">
                        Include an automated pricing request on sizing approval?
                    </mat-checkbox>
                </div>
                <ng-container *ngIf="requestFormPayload.is_pricing_needed">
                    <div class="form-field">
                        <div class="label-text">Responders <sup>*</sup></div>
                        <kendo-multiselect [data]="dropDownSourceList?.respondersDataSource" [textField]="'name'"
                            [valueField]="'id'" [valuePrimitive]="true"
                            [kendoDropDownFilter]="commonSettings.dropDownFilterSettings" placeholder="Select"
                            [(ngModel)]="requestFormPayload.pricing_data.user_ids" name="pricing_user_ids" required>
                        </kendo-multiselect>
                    </div>
                    <div class="form-field">
                        <div class="label-text">Type <sup>*</sup></div>
                        <kendo-dropdownlist [data]="dropDownSourceList?.pricingTypeDataSource" [textField]="'value'"
                            [valueField]="'key'" [valuePrimitive]="true"
                            [defaultItem]="commonSettings.defaultItems.KeyValue"
                            [(ngModel)]="requestFormPayload.pricing_data.price_type" name="price_type" required>
                        </kendo-dropdownlist>
                    </div>
                    <div class="form-field">
                        <div class="label-text">Turnaround needed on Pricing after Sizing <sup>*</sup></div>
                        <kendo-dropdownlist [data]="dropDownSourceList?.turnAroundDataSource" [textField]="'value'"
                            [valueField]="'key'" [valuePrimitive]="true"
                            [defaultItem]="commonSettings.defaultItems.KeyValue"
                            [(ngModel)]="requestFormPayload.pricing_data.required_time" name="required_time" required>
                        </kendo-dropdownlist>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Submit
            </button>
        </div>
    </form>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { finalize, Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { IdleUserTimesEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { AccountModuleService } from 'src/app-core/services/account-module.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { IdleUserService } from 'src/app-core/services/idle-user.service';
import { LoaderService } from 'src/app-core/services/loader.service';

const SERVICES: any[] = [IdleUserService];

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();

    idleUserTimesEnum: typeof IdleUserTimesEnum = IdleUserTimesEnum;

    idleTime: number = this.idleUserTimesEnum.idleTime;

    constructor(
        private idleUserService: IdleUserService,
        private keepalive: Keepalive,
        public router: Router,
        private loaderService: LoaderService,
        private commonMethod: CommonMethod,
        private customToastrService: CustomToastrService,
        private accountModuleService: AccountModuleService
    ) {
        this.componentSubscriptions.add(router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loaderService.show();
            } else if (event instanceof NavigationEnd) {
                this.loaderService.hide();
            }
        }));
    }

    ngOnInit() {
        this.keepalive.interval(1);
        this.idleUserService.startWatching();

        this.idleUserService.idle.onTimeout.subscribe(() => {
            this.idleUserService.idle.clearInterrupts();
            this.customToastrService.error(CommonConstants.idleTimeConstants.userIdle);
            this.onLogout();
            this.commonMethod.logout();
        });
    }

    onLogout(): void {
        this.loaderService.show();
        this.accountModuleService.postUserlogout()
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe(() => {
                this.commonMethod.logout();
            });
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
        this.idleUserService.stopWatching();
    }

}
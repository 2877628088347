import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class ReportService {

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    getBuildReport(byPassDrawingValue = 0, leadId: string, leadlineId: string): Observable<any> {
        return this.httpCommonService.getRecord(`build_report/${leadId}/${leadlineId}/${byPassDrawingValue}`);
    }

    postEmailReport(leadId: string, leadlineId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`email_report/${leadId}/${leadlineId}`, inputData);
    }

    getSyncDealRecord(leadId: string): Observable<any> {
        return this.httpCommonService.getRecord(`sync_deal_details/${leadId}`);
    }

}

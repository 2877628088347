<h1 mat-dialog-title>
    <span>
        {{formType === commonConstants.formTypeConstants.add ? 'Create': 'Edit'}} Contact
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper contact-form-wrapper">
    <div class="overlay-loader" [busyIf]="contactFormLoaderModel.isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #contactForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="contactForm.form.valid && !contactEmailExist && onFormSubmit()">
        <div class="dialog-content-wrapper row">
            <div class="col-12 d-none">
                <div class="form-field mb-3">
                    <div class="label-text">Contact ID <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.contact_id" name="contactId" autocomplete="off"
                        [disabled]="true" required>
                    </kendo-textbox>
                </div>
            </div>
            <div class="col-12">
                <div class="form-field mb-3">
                    <div class="label-text">Email <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.email" name="email" autocomplete="off"
                        [maxlength]="250" [pattern]="customPatterns.email" (change)="onChangeValidateEmail()" required>
                    </kendo-textbox>
                    <div class="row">
                        <div class="col-8">
                            <small class="email-error-text" *ngIf="contactEmailExist">Email Id already exist!</small>
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <mat-hint>
                                {{contactFormPayload.email?.length || 0}}/250
                            </mat-hint>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">First Name <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.firstname" name="firstname" autocomplete="off"
                        [maxlength]="50" required>
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{contactFormPayload.firstname?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Last Name <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.lastname" name="lastname" autocomplete="off"
                        [maxlength]="50" required>
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{contactFormPayload.lastname?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Job Title</div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.jobtitle" name="jobtitle" autocomplete="off"
                        [maxlength]="250">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{contactFormPayload.jobtitle?.length || 0}}/250
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Industry Type <sup>*</sup></div>
                    <kendo-dropdownlist [data]="industryTypeSourceList" [textField]="'key'" [valueField]="'value'"
                        [valuePrimitive]="true" [loading]="contactFormLoaderModel.isIndustryTypeLoading"
                        [defaultItem]="commonSettings.defaultItems.fieldsValueKey"
                        [(ngModel)]="contactFormPayload.industry_type" name="industry_type" placeholder="Select"
                        required>
                    </kendo-dropdownlist>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Phone</div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.phone" name="phone" autocomplete="off"
                        autocomplete="off" [maxlength]="50" [pattern]="customPatterns.phoneNumber">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{contactFormPayload.phone?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Alt Phone Number</div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.alt_phone_number" name="alt_phone_number"
                        autocomplete="off" [maxlength]="50" [pattern]="customPatterns.phoneNumber">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{contactFormPayload.alt_phone_number?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Mobile Number</div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.mobilephone" name="mobilephone" autocomplete="off"
                        [maxlength]="50" [pattern]="customPatterns.phoneNumber">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{contactFormPayload.mobilephone?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Fax Number</div>
                    <kendo-textbox [(ngModel)]="contactFormPayload.fax" name="fax" autocomplete="off" [maxlength]="50"
                        [pattern]="customPatterns.phoneNumber">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{contactFormPayload.fax?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-12">
                <div class="form-field mb-2">
                    <div class="label-text">Notes</div>
                    <kendo-textarea [(ngModel)]="contactFormPayload.notes" name="notes" [rows]="6" autocomplete="off"
                        resizable="vertical">
                    </kendo-textarea>
                </div>
            </div>
            <div class="col-6 offset-6 text-end mt-3 d-none" [ngClass]="{'archive': !isRestore, 'restore': isRestore}"
                (click)="onShowArchiveConfirmation()">
                <ng-container *ngIf="formType === commonConstants.formTypeConstants.edit">
                    <img src="assets/images/{{ isRestore ? 'restore-icon.svg' : 'archive.svg' }}" height="16" />
                    <b class="ms-2">{{ isRestore ? 'Restore' : 'Archive' }}</b>
                </ng-container>
            </div>
            <div class="company-wrapper" [ngClass]="{'hide': formType === commonConstants.formTypeConstants.add}">
                <div class="company-title my-3">
                    Company Association
                </div>
                <div class="row mb-3">
                    <div class="col-6 p-0">
                        <div class="form-field mb-0">
                            <kendo-dropdownlist [data]="companyList" [textField]="'name'" [valueField]="'id'"
                                [valuePrimitive]="true" [loading]="contactFormLoaderModel.isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.idName" [filterable]="true"
                                [itemDisabled]="itemDisabled"
                                [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                [(ngModel)]="selectedCompany" name="contact_name" placeholder="Select">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6 p-0 d-flex justify-content-end align-items-center">
                        <div class="button-wrapper">
                            <span class="icon-button-wrapper">
                                <button mat-flat-button type="button" color="primary" (click)="addCompanies()"
                                    [disabled]="!selectedCompany" matTooltip="Company Association">
                                    <img src="assets/images/add-circle-icon.svg" height="20" class="add-icon" />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mt-2" *ngIf="contactFormPayload.association.length > 0">
                    <table class="table associate-table table-hover">
                        <thead>
                            <tr class="table-header">
                                <th class="w-100px">Primary</th>
                                <th>Name</th>
                                <th>Industry Type</th>
                                <th class="w-100px">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let company of contactFormPayload.association let i = index"
                                [ngClass]="company.rowClass">
                                <td>
                                    <mat-radio-button [checked]="company.is_primary"
                                        (change)="togglePrimaryCompany(i)"></mat-radio-button>
                                </td>
                                <td class="wrap-contact-company" [matTooltip]="company.name">
                                    {{ company.name }}
                                </td>
                                <td class="wrap-contact-company" [matTooltip]="company.industry_type">
                                    {{ company.industry_type }}
                                </td>
                                <td>
                                    <div class="grid-icon-btn-wrapper">
                                        <button mat-flat-button type="button" color="error"
                                            matTooltip="Delete Association" class="m-0"
                                            (click)="onDeleteAssociations([i])">
                                            <img src="assets/images/delete-icon.svg" height="16" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Back
            </button>
            <button mat-flat-button type="submit" color="primary" [disabled]="isRestore">
                Save
            </button>
        </div>
    </form>
</div>
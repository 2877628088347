import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EditorComponent as KendoEditorComponent } from '@progress/kendo-angular-editor';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { GridDataModel } from 'src/app-core/data-model/grid-data-model';
import { DefaultResultsPerPage } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
    selector: 'app-system-announcements',
    templateUrl: './system-announcements.component.html',
    styleUrls: ['./system-announcements.component.scss']
})

export class SystemAnnouncementsComponent extends AppComponentBase implements OnInit {

    @ViewChild('kg', { static: true }) private grid: any = GridComponent;
    @ViewChild(TooltipDirective, { static: true }) public tooltipDir!: TooltipDirective;
    @ViewChild('systemAnnouncementForm', { static: false }) systemAnnouncementForm!: NgForm;
    @ViewChild('editor', { static: false }) editor?: KendoEditorComponent;
    gridDataModel = new GridDataModel();
    commonDataModel = new CommonDataModel();
    gridFilterModel = _.cloneDeep(this.commonDataModel.gridFilterModel);
    gridPayload = _.cloneDeep(this.commonDataModel.gridPayload);
    announcementsGridColumnModel = _.cloneDeep(this.gridDataModel.announcementsGridColumnModel);
    systemAnnouncementFormPayload = _.cloneDeep(this.commonDataModel.systemAnnouncementFormPayload);
    pageSizes = this.commonDataModel.pageSizes;
    initialGridData: any;
    formType: string = '';
    isGridExport = false;
    isFormLoading = false;
    isShowAnnoucementEditor = false;
    isShowAnnouncementContainer = false;
    isViewAnnouncement = false;

    resultPerPage = DefaultResultsPerPage.value;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        injector: Injector,
        private matDialog: MatDialog,
        private commonMethod: CommonMethod,
        private customToastrService: CustomToastrService,
        private commonService: CommonService) {
        super(injector);
        this.gridPayload.grid_name = this.commonConstants.gridNameConstants.announcementsGrid;
    }

    ngOnInit(): void {
        this.onLoadItems();
    }

    onLoadItems(): void {
        const userId = this.commonMethod.getUserId();
        this.commonService.getUserFormRecord(userId).subscribe(response => {
            this.isShowAnnouncementContainer = response.data.is_add_edit_announcement ? true : false;
        });
        this.kendogridHelper.showLoadingIndicator();
        this.commonService.getGridFilters(this.commonConstants.gridNameConstants.announcementsGrid)
            .subscribe(response => {
                if (response.data) {
                    this.commonMethod.mappingGridFilters(response?.data, this.kendogridHelper, this.gridFilterModel, this.announcementsGridColumnModel);
                }
                this.onRefreshGrid();
            });
    }

    onRefreshGrid() {
        this.grid.dataStateChange.emit({
            skip: 0,
            take: this.kendogridHelper.defaultRecordsCountPerPage,
            filter: this.kendogridHelper.filter,
            group: this.kendogridHelper.groups,
            sort: this.kendogridHelper.sort
        });
    }

    getGridRecords(event: DataStateChangeEvent) {
        if (event) {
            this.kendogridHelper.dataStateChangeEvent(event);
        }
        this.kendogridHelper.showLoadingIndicator();
        this.commonMethod.gridObjectMapping(this.gridPayload, event);
        this.commonMethod.objectMapping(this.gridFilterModel, this.gridPayload);
        const gridPayload = _.cloneDeep(this.gridPayload);
        if (this.isGridExport) {
            gridPayload.per_page = DefaultResultsPerPage.announcementGrid;
        } else {
            this.resultPerPage = event?.take;
        }
        this.commonService.getAnnouncementsGrid(this.gridPayload)
            .pipe(finalize(() => this.kendogridHelper.hideLoadingIndicator()))
            .subscribe(response => {
                this.mapGridData(response.data);
                if (this.isGridExport) {
                    setTimeout(() => {
                        this.commonMethod.onExportToCSV(this.grid);
                        this.isGridExport = false;
                        this.resultPerPage = DefaultResultsPerPage.value;
                        this.mapGridData(this.initialGridData);
                    }, 1000);
                } else {
                    if (this.kendogridHelper.skipCount === 0) {
                        this.initialGridData = _.cloneDeep(response.data);
                    }
                }
            });
    }

    mapGridData(data: any): void {
        data.data.map((item: any) => {
            return item;
        });
        this.kendogridHelper.setGridData(data);
    }

    onColumnReorder(event: any): void {
        this.gridFilterModel.column_fields = this.commonMethod.setGridColumnReorder(event, this.announcementsGridColumnModel);
        this.updateGridFilters();
    }

    onColumnResize(event: any): void {
        this.commonMethod.setGridColumnResize(event, this.announcementsGridColumnModel);
        this.updateGridFilters();
    }

    onChangeColumnOptions(event: any, index: number): void {
        this.announcementsGridColumnModel[index].hidden = !event.checked;
        this.gridFilterModel.column_fields = Object.assign([], this.announcementsGridColumnModel);
        this.updateGridFilters();
    }

    onSortChange(): void {
        this.updateGridFilters();
    }

    onGroupChange(): void {
        this.updateGridFilters();
    }

    updateGridFilters(): void {
        this.commonMethod.updateGridFilters(this.commonConstants.gridNameConstants.announcementsGrid, this.gridFilterModel, this.announcementsGridColumnModel);
    }

    addNewAnnouncement() {
        this.formType = CommonConstants.formTypeConstants.add;
        this.isShowAnnoucementEditor = true;
        this.isViewAnnouncement = false;
        this.systemAnnouncementFormPayload.id = '';
        this.systemAnnouncementFormPayload.subject_line = '';
        this.systemAnnouncementFormPayload.description = '';
        this.systemAnnouncementForm.reset();
    }

    viewAnnouncement(dataItem: any) {
        this.isFormLoading = true;
        this.isViewAnnouncement = true;
        this.isShowAnnoucementEditor = false;
        this.commonService.fetchAnnouncements(dataItem.id)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.commonMethod.objectMapping(this.systemAnnouncementFormPayload, response.data);
            });
    }

    fetchAnnouncement(dataItem: any) {
        this.isFormLoading = true;
        this.isShowAnnoucementEditor = true;
        this.isViewAnnouncement = false;
        this.formType = CommonConstants.formTypeConstants.edit;
        this.commonService.fetchAnnouncements(dataItem.id)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.commonMethod.objectMapping(this.systemAnnouncementFormPayload, response.data);
            });
    }

    onSubmit() {
        this.isFormLoading = true;
        if (this.formType === CommonConstants.formTypeConstants.edit) {
            this.commonService.updateAnnouncements(this.systemAnnouncementFormPayload.id, this.systemAnnouncementFormPayload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    this.onRefreshGrid();
                    this.resetAnnouncement(this.systemAnnouncementForm);
                });
        } else {
            this.commonService.postAnnouncements(this.systemAnnouncementFormPayload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    this.onRefreshGrid();
                    this.resetAnnouncement(this.systemAnnouncementForm);
                });
        }
    }

    onExportToCSV(): void {
        this.isGridExport = true;
        this.resultPerPage = DefaultResultsPerPage.announcementGrid;
        this.grid.dataStateChange.emit({
            skip: 0,
            take: DefaultResultsPerPage.announcementGrid,
            filter: this.kendogridHelper.filter,
            group: this.kendogridHelper.groups,
            sort: this.kendogridHelper.sort
        });
    }

    onShowTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
            && element.offsetWidth < element.scrollWidth) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    resetAnnouncement(systemAnnouncementForm: NgForm) {
        this.systemAnnouncementFormPayload.id = '';
        this.systemAnnouncementFormPayload.subject_line = '';
        this.systemAnnouncementFormPayload.description = '';
        systemAnnouncementForm.reset();
        this.isShowAnnoucementEditor = false;
    }
}

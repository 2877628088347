import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ConformationDialogComponent } from 'src/app/shared/conformation-dialog/conformation-dialog.component';
import { DeleteConformationDialogComponent } from 'src/app/shared/delete-conformation-dialog/delete-conformation-dialog.component';
import { GridFilterFormComponent } from 'src/app/shared/grid-filter-form/grid-filter-form.component';
import { LeadlineRevenueEditorDialogComponent } from 'src/app/shared/leadline-revenue-editor-dialog/leadline-revenue-editor-dialog.component';
import { ReassignUserFormComponent } from 'src/app/shared/reassign-user-form/reassign-user-form.component';
import { ReviseDueDateDialogComponent } from 'src/app/shared/revise-due-date-dialog/revise-due-date-dialog.component';
import { UploadDocumentDialogComponent } from 'src/app/shared/upload-document-dialog/upload-document-dialog.component';
import { environment } from 'src/environments/environment';
import { CommonConstants } from '../constants/common-constants';
import { CommonDataModel } from '../data-model/common-data-model';
import { DefaultResultsPerPage } from '../enums/common-enum';
import { CommonService } from '../services/common.service';
import { CustomToastrService } from '../services/custom-toastr-service';

declare var tinymce: any;
declare var $: any;

@Injectable({
    providedIn: 'root'
})

export class CommonMethod {

    commonDataModel = new CommonDataModel();
    permissionDataModel = this.commonDataModel.permissionDataModel;
    gridSelectionColumnModel = Object.assign({}, this.commonDataModel.gridSelectionColumnModel);
    userDetailsLocalStorage = 'leadspeed';
    rememberMeDetailsLocalStorage = 'rememberMe';
    byPassDrawingReportLocalStorage = 'byPassDrawing';

    constructor(
        private router: Router,
        private matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        private commonService: CommonService,
        private customToastrService: CustomToastrService) {
    }

    //  Conformation Dialog
    private setConformationDialogValue = new BehaviorSubject<boolean>(false);
    getConformationDialogValue = this.setConformationDialogValue.asObservable();

    // Delete Conformation Dialog
    private setDeleteConformationValue = new BehaviorSubject<boolean>(false);
    getDeleteConformationValue = this.setDeleteConformationValue.asObservable();

    //  Due Date Dialog
    private setDueDateDialogValue = new BehaviorSubject<boolean>(false);
    getDueDateDialogValue = this.setDueDateDialogValue.asObservable();

    //  Reassign User Dialog
    private setReassignUserDialogValue = new BehaviorSubject<boolean>(false);
    getReassignUserDialogValue = this.setReassignUserDialogValue.asObservable();

    //  Upload Document Dialog
    private setUploadDocumentDialogValue = new BehaviorSubject<boolean>(false);
    getUploadDocumentDialogValue = this.setUploadDocumentDialogValue.asObservable();

    // Update Username
    setUserNameValue = new BehaviorSubject<any>({});
    getUserNameValue = this.setUserNameValue.asObservable();

    setUserName(name: any) {
        this.setUserNameValue.next(name);
    }

    //  Load Field Check
    private setLoadFieldCheck = new BehaviorSubject<boolean>(false);
    getLoadFieldCheck = this.setLoadFieldCheck.asObservable();

    sendLoadFieldCheck(data: any) {
        this.setLoadFieldCheck.next(data);
    }

    //  Load Document Status Grid
    private setLoadDocumentStatusGrid = new BehaviorSubject<boolean>(false);
    getLoadDocumentStatusGrid = this.setLoadDocumentStatusGrid.asObservable();

    sendLoadDocumentStatusGrid(data: boolean) {
        this.setLoadDocumentStatusGrid.next(data);
    }

    //  Load All Comments
    private setLoadAllCommentsValue = new BehaviorSubject<string>('');
    getLoadAllCommentsValue = this.setLoadAllCommentsValue.asObservable();

    sendLoadAllCommentsValue(data: string) {
        this.setLoadAllCommentsValue.next(data);
    }

    //  Load Issue Grid
    private setLoadIssueGrid = new BehaviorSubject<boolean>(false);
    getLoadIssueGrid = this.setLoadIssueGrid.asObservable();

    sendLoadIssueGrid(data: boolean) {
        this.setLoadIssueGrid.next(data);
    }

    //  Load Logs Grid Records
    private setLoadAllLogsRecords = new BehaviorSubject<boolean>(false);
    getLoadAllLogsRecords = this.setLoadAllLogsRecords.asObservable();

    sendLoadAllLogsRecords(data: boolean) {
        this.setLoadAllLogsRecords.next(data);
    }

    // Load LeadLines Details
    setLoadLeadLinesGrid = new BehaviorSubject<any>(false);
    getLoadLeadLinesGrid = this.setLoadLeadLinesGrid.asObservable();

    //  Leadline Status
    private setLeadlineStatus = new BehaviorSubject<boolean>(true);
    getLeadlineStatus = this.setLeadlineStatus.asObservable();

    sendLeadlineStatus(data: boolean) {
        this.setLeadlineStatus.next(data);
    }

    // Leadline isactive status
    private setIsActiveLeadlineStatus = new BehaviorSubject<boolean>(true);
    getIsActiveLeadlineStatus = this.setIsActiveLeadlineStatus.asObservable();

    sendIsActiveLeadlineStatus(data: boolean) {
        this.setIsActiveLeadlineStatus.next(data);
    }

    private setLoadLeadLineDetailsRecords = new BehaviorSubject<boolean>(false);
    getLoadLeadLineDetailsRecords = this.setLoadLeadLineDetailsRecords.asObservable();

    sendLoadLeadLineDetailsRecords(data: boolean) {
        this.setLoadLeadLineDetailsRecords.next(data);
    }

    // Load issues list based on Service Id
    private setLoadIssueGridByServiceId = new BehaviorSubject<string>('');
    getLoadIssueGridByServiceId = this.setLoadIssueGridByServiceId.asObservable();

    sendLoadIssueGridByServiceId(data: string) {
        this.setLoadIssueGridByServiceId.next(data);
    }

    // Load issues list based on Nested Service Id
    private setLoadIssueGridByNestedServiceId = new BehaviorSubject<string>('');
    getLoadIssueGridByNestedServiceId = this.setLoadIssueGridByNestedServiceId.asObservable();

    sendLoadIssueGridByNestedServiceId(data: string) {
        this.setLoadIssueGridByNestedServiceId.next(data);
    }

    // Load Grid Filter Dropdown
    private setLoadGridFilterDropdown = new BehaviorSubject<any>({ isLoadDropDown: false, isCheckDefaultFilter: true });
    getLoadGridFilterDropdown = this.setLoadGridFilterDropdown.asObservable();

    sendLoadGridFilterDropdown(data: any) {
        this.setLoadGridFilterDropdown.next(data);
    }

    // Clear Grid Filter Dropdown
    private setClearGridFilterDropdown = new BehaviorSubject<boolean>(false);
    getClearGridFilterDropdown = this.setClearGridFilterDropdown.asObservable();

    sendClearGridFilterDropdown(data: boolean) {
        this.setClearGridFilterDropdown.next(data);
    }

    private setRevenueValue = new BehaviorSubject<string>('');
    getRevenueValue = this.setRevenueValue.asObservable();

    sendRevenueValue(data: string) {
        this.setRevenueValue.next(data);
    }

    // Set Logged User Data
    setLoggedUserData(data: any) {
        localStorage.setItem(this.userDetailsLocalStorage, this.base64Encode(data));
    }

    // Set Remember Me Data
    setRememberMeData(data: any) {
        localStorage.setItem(this.rememberMeDetailsLocalStorage, this.base64Encode(data));
    }

    // Set Bypass Drawing Data
    setByPassDrawingData(data: any) {
        localStorage.setItem(this.byPassDrawingReportLocalStorage, this.base64Encode(data));
    }

    // Get Remember Me Data
    getRememberMeData() {
        if (localStorage.getItem(this.rememberMeDetailsLocalStorage)) {
            return this.base64Decode(localStorage.getItem(this.rememberMeDetailsLocalStorage));
        }
        return false;
    }

    // Get Logged User Data
    getLoggedUserData() {
        if (localStorage.getItem(this.userDetailsLocalStorage)) {
            return this.base64Decode(localStorage.getItem(this.userDetailsLocalStorage));
        }
        return false;
    }

    // Get User Id
    getUserId() {
        const userData = this.getLoggedUserData();
        return userData?.id;
    }

    // Get Bypass Drawing Data
    getByPassDrawingData() {
        if (localStorage.getItem(this.byPassDrawingReportLocalStorage)) {
            return this.base64Decode(localStorage.getItem(this.byPassDrawingReportLocalStorage));
        }
        return false;
    }

    // Base64 Encode
    base64Encode(data: any) {
        return window.btoa(JSON.stringify(data));
    }

    // Base64 Decode
    base64Decode(data: any) {
        return JSON.parse(window.atob(data));
    }

    // Remember Me Uncheck
    clearRememberMe() {
        localStorage.removeItem(this.rememberMeDetailsLocalStorage);
    }

    // Logout
    logout() {
        this.matDialog.closeAll();
        localStorage.removeItem(this.userDetailsLocalStorage);
        localStorage.removeItem(this.byPassDrawingReportLocalStorage);
        this.router.navigateByUrl('/login');
    }

    logoutWithError(message: string) {
        this.matDialog.closeAll();
        localStorage.removeItem(this.userDetailsLocalStorage);
        localStorage.removeItem(this.byPassDrawingReportLocalStorage);
        this.router.navigateByUrl('/login');
        this.customToastrService.error(message);
    }

    setLeadParamsToServices(leadId: string, leadlineId: string, requestId: string) {
        this.commonService.setLeadId(leadId);
        this.commonService.setLeadlineId(leadlineId);
        this.commonService.setRequestId(requestId);
    }

    // Mapping Permissions
    mappingPermissionModel() {
        const data = this.getLoggedUserData();
        if (data) {
            this.permissionDataModel.role = data?.role;
            this.permissionDataModel.hydro = data.user_permission?.hydro;
            this.permissionDataModel.budget = data.user_permission?.budget;
            this.permissionDataModel.cad = data.user_permission?.cad;
            this.permissionDataModel.is_doc_manager = data.user_permission?.is_doc_manager;

            if (data.user_permission?.is_doc_manager) {
                this.permissionDataModel.isAdministrator = false;
            } else if (data?.role !== CommonConstants.appRoleConstants.administrator) {
                this.permissionDataModel.isAdministrator = false;
            } else {
                this.permissionDataModel.isAdministrator = true;
            }
            return this.permissionDataModel;
        }
        return this.permissionDataModel;
    }

    // Object Mapping
    objectMapping(mappingObject: any, sourceObject: any) {
        Object.keys(sourceObject).map((key, index) => {
            if (mappingObject.hasOwnProperty(key) && sourceObject.hasOwnProperty(key)) {
                mappingObject[key] = sourceObject[key];
            }
        });
    }

    // Remove property from Array
    removePropertyFromObject(data: any, propertyName: string) {
        const prepareData = Object.assign({}, data);
        return _.omit(prepareData, [propertyName]);
    }

    // Grid Column Mapping
    gridColumnMapping(mappingList: any, souceList: any) {
        let mapList = _.cloneDeep(mappingList);
        const mapListLength = mapList.length;
        souceList.filter((list: any, index: number) => {
            list.orderIndex = mapListLength + index + 1;
            mapList.push(list);
        });
        return mapList;
    }

    // Conformation Dialog
    openConformationDialog(requestId: any, requestType: number) {
        const dialogRef = this.matDialog.open(ConformationDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: {
                requestId: requestId,
                requestType: requestType
            }
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            this.setConformationDialogValue.next(result.confirmResult);
        });
    }

    // Delete Conformation Dialog
    openDeleteConformationDialog(message: string) {
        const dialogRef = this.matDialog.open(DeleteConformationDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: message || null,
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            this.setDeleteConformationValue.next(result.confirmResult);
        });
    }

    // Delete Async Conformation Dialog
    async asyncOpenDeleteConformationDialog(message: string) {
        const dialogRef = this.matDialog.open(DeleteConformationDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: message || null,
        });
        return lastValueFrom(dialogRef.afterClosed()).then(result => {
            return result.confirmResult;
        });
    }

    // Revise Due Date Dialog
    openReviseDueDateDialog(requestId: any, formType: string) {
        const dialogRef = this.matDialog.open(ReviseDueDateDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            position: { top: '0' },
            hasBackdrop: false,
            data: {
                requestId: requestId,
                formType: formType
            }
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            this.setDueDateDialogValue.next(result.confirmResult);
        });
    }

    // Reassign User Form
    openReassignFormDialog(dataItem: any, formTypeValue: string): void {
        let requestId = '';
        let formType = formTypeValue;
        switch (formTypeValue) {
            case CommonConstants.gridNameConstants.userRequestGrid:
                requestId = dataItem?.request_id;
                if (dataItem?.request_type.toLowerCase() === CommonConstants.reAssignUserFormConstants.issue) {
                    formType = CommonConstants.gridNameConstants.issuesGrid;
                }
                break;
            case CommonConstants.gridNameConstants.documentStatusGrid:
                requestId = dataItem?.doc_req_id;
                break;
            case CommonConstants.gridNameConstants.issuesGrid:
                requestId = dataItem?.id;
                break;
            default:
                break;
        }
        const dialogRef = this.matDialog.open(ReassignUserFormComponent, {
            panelClass: 'confirm-dialog-wrapper',
            position: { top: '0' },
            hasBackdrop: false,
            data: {
                formType: formType,
                requestId: requestId,
                revised_due_date: dataItem?.revised_due_date
            }
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            this.setReassignUserDialogValue.next(result.confirmResult);
        });
    }

    // Upload Documents
    openUploadDocumentFormDialog(dataItem: any, formType: string): void {
        const dialogRef = this.matDialog.open(UploadDocumentDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: {
                formType: formType,
                model: dataItem.model,
                price: dataItem.price ? dataItem.price : '0.00',
                requestId: dataItem.doc_req_id,
                uploadedSupportFiles: dataItem.support_documents,
                uploadedFile: {
                    fileName: dataItem.file,
                    url: dataItem.url
                }
            }
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            this.setUploadDocumentDialogValue.next(result.confirmResult);
        });
    }

    // Grid Filter Form
    async openGridFilterFormDialog(payload: any) {
        const dialogRef = this.matDialog.open(GridFilterFormComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: payload || null,
        });
        return lastValueFrom(dialogRef.afterClosed()).then(result => {
            return result.confirmResult;
        });
    }

    // Mapping Grid Filters
    mappingGridFilters(response: any, kendoHelperObject: any, gridFilterModel: any, gridColumnModel: any): void {
        const responseData = Object.assign({}, response);
        responseData.column_fields = responseData.column_fields || [];
        responseData.grouping = responseData.grouping || [];
        this.objectMapping(gridFilterModel, responseData);
        if (responseData.column_fields.length > gridColumnModel.length || !responseData.column_fields.length) {
            gridFilterModel.column_fields = gridColumnModel;
        }
        this.mappingKendoGridHelper(kendoHelperObject, true, responseData);
        this.mappingColumnFields(gridFilterModel, gridColumnModel);
        this.sortingData(gridColumnModel, CommonConstants.appConstants.orderIndex);
    }

    // Grid Object Mapping
    gridObjectMapping(mappingObject: any, event: DataStateChangeEvent) {
        mappingObject.sort_by = null;
        mappingObject.sort_order = '';
        mappingObject.grouping = [];
        if (Array.isArray(event.group) && event.group.length) {
            mappingObject.grouping = event.group;
        }
        if (event.sort && event.sort.length) {
            if (event.sort[0].dir !== undefined) {
                mappingObject.sort_by = event.sort[0].field;
                mappingObject.sort_order = event.sort[0].dir;
            }
        }
        mappingObject.per_page = event.take;
        mappingObject.skip_count = event.skip;
        mappingObject.user_id = this.getUserId();
    }

    // Mapping Kendo Grid Helper
    mappingKendoGridHelper(sourceObject: any, value: boolean, responseData?: any): void {
        if (value) {
            sourceObject.sort = [{
                dir: responseData.sort_order,
                field: responseData.sort_by ? responseData.sort_by : null
            }];
            sourceObject.groups = responseData.grouping;
        } else {
            sourceObject.groups = [];
            sourceObject.sort = [{ dir: '', field: null }];
        }
        return sourceObject;
    }

    // Mapping Grid Column Fields
    mappingColumnFields(sourceObject: any, mappingObject: any) {
        sourceObject.column_fields.filter((sourceList: any) => {
            mappingObject.find((list: any) => {
                if (sourceList.field === list.field) {
                    list.hidden = sourceList.hidden;
                    list.orderIndex = sourceList.orderIndex;
                    if (list.field !== 'action') {
                        list.width = sourceList.width ? sourceList.width : 120;
                    }
                }
            });
        });
        return mappingObject;
    }

    // Set Column Visibility
    setGridColumnVisibility($event: any, mappingObject: any) {
        $event.columns.filter((parentList: any) => {
            mappingObject.map((list: any) => {
                if (parentList.field === list.field) {
                    list.hidden = parentList.hidden;
                }
            });
        });
        return mappingObject;
    }

    // Set Column Reorder
    setGridColumnReorder(event: any, gridColumnModel: any, deletePermission = false) {
        const data = gridColumnModel.filter((list: any) => list.hidden === false);
        if (deletePermission) {
            data.unshift(this.gridSelectionColumnModel);
        }
        const reorderedColumn = data.splice(event.oldIndex, 1);
        data.splice(event.newIndex, 0, reorderedColumn[0]);
        data.join();

        data.forEach((value: any, index: number) => {
            gridColumnModel.filter((parentList: any) => {
                if (!parentList.hidden) {
                    if (value.field === parentList.field) {
                        parentList.orderIndex = index;
                    }
                }
            });
        });

        this.sortingData(gridColumnModel, CommonConstants.appConstants.orderIndex);

        gridColumnModel.forEach((value: any, index: number) => {
            value.orderIndex = index + 1;
        });
        return gridColumnModel;
    }

    // Set Column Resize
    setGridColumnResize($event: any, mappingObject: any) {
        mappingObject.find((list: any) => {
            if (list.field === $event[0].column.field) {
                list.width = $event[0].newWidth;
            }
        });
        return mappingObject;
    }

    // Update Grid Filters
    updateGridFilters(gridName: string, gridFilterModel: any, gridColumnModel: any) {
        this.sortingData(gridColumnModel, CommonConstants.appConstants.orderIndex);
        gridColumnModel.forEach((value: any, index: number) => {
            value.orderIndex = index + 1;
        });
        gridFilterModel.column_fields = gridColumnModel;
        this.commonService.postGridFilters(gridName, gridFilterModel).subscribe(response => {
            if (gridName !== CommonConstants.gridNameConstants.logHistoryGrid) {
                this.customToastrService.success(response?.message);
            }
        });
    }

    // Sorting Data
    sortingData(mappingArray: any, field: string): void {
        mappingArray.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return mappingArray;
    }

    // Set Score Progressbar
    setScoreValue(value: number) {
        const scroeValue = Math.round(value);
        if (scroeValue >= 75) {
            return 'success-color';
        } else if (scroeValue > 50) {
            return 'warning-color';
        } else {
            return 'error-color text-color-black';
        }
    }

    leadLineModuleRedirection(dataItem: any, moduleName: string): void {
        this.router.navigate([`lead-line-info/${dataItem?.lead_id}/${dataItem?.leadline_id}/${0}/${moduleName}`]);
    }

    getLeadLineModulePath(dataItem: any, moduleName: string) {
        return `/lead-line-info/${dataItem?.lead_id}/${dataItem?.leadline_id}/${0}/${moduleName}`;
    }

    checkLeadLineId(leadlineId: any) {
        if (leadlineId && leadlineId === '0') {
            return false;
        }
        return true;
    }

    convertDate(date: any) {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }
        return '';
    }

    convertDateTime(date: any) {
        if (date) {
            return moment(date).format('MM-DD-YYYY hh:mm a');
        }
        return '';
    }

    serviceConvertDateTime(date: any) {
        if (date) {
            return moment(date).format('YYYY-MM-DD hh:mm:ss');
        }
        return '';
    }

    dateFieldMapping(value: string) {
        if (value) {
            return new Date(value);
        }
        return '';
    }

    convertDateObject(date: any) {
        if (date) {
            return new Date(date.replaceAll('-', ' ', 'g'));
        }
        return '';
    }

    convertCurrency(value: any) {
        if (value && value.toString()) {
            const data = value.toString().replace("$", "").trim();
            return this.currencyPipe.transform(parseFloat(data))?.toString() || '0';
        } else {
            return value;
        }
    }

    convertCurrencyToDecimal(value: any) {
        if (value.toString()) {
            return value.toString().replace(/[$,]/g, "").trim();
        } else {
            return value;
        }
    }

    convert24hrTo12hrTimeWithoutDate(time: any) {
        return moment(time, ["HH"]).format("h A");
    }

    async imageFileUploadValidation(event: any, data?: any) {
        let uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files[0].size <= 2000000) {
                let file = event.target.files[0];
                const extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
                if (this.checkImageFileExtension(extension)) {
                    uploadFileModel.isFileValid = true;
                    uploadFileModel.fileName = file.name;
                    uploadFileModel.file = file;
                    uploadFileModel.fileSize = this.formatFileSize(file.size, 1);
                    uploadFileModel.url = await this.convertFileToBase64(file);
                } else {
                    file = '';
                    uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
                    this.customToastrService.error(CommonConstants.appConstants.invalidFileFormat);
                }
            } else {
                uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
                this.customToastrService.error(CommonConstants.appConstants.imageFileSizeMessage);
            }
            return uploadFileModel;
        }
    }

    async uploadFileValidation(event: any) {
        let uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files[0].size <= 10000000) {
                let file = event.target.files[0];
                const extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
                if (this.checkFileExtension(extension)) {
                    uploadFileModel.isFileValid = true;
                    uploadFileModel.fileName = file.name;
                    uploadFileModel.file = file;
                    uploadFileModel.fileSize = this.formatFileSize(file.size, 1);
                    uploadFileModel.url = await this.convertFileToBase64(file);
                } else {
                    file = '';
                    uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
                    this.customToastrService.error(CommonConstants.appConstants.invalidFileFormat);
                }
            } else {
                uploadFileModel = _.cloneDeep(this.commonDataModel.uploadFileModel);
                this.customToastrService.error(CommonConstants.appConstants.fileSizeErrorText);
            }
            return uploadFileModel;
        }
    }

    convertFileToBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    checkImageFileExtension(fileType: any) {
        const inputType = fileType.toLowerCase();
        const imageFileExtensionList = ['png', 'jpeg', 'bmp', 'jpg', 'svg'];
        return imageFileExtensionList.includes(inputType);
    }

    checkFileExtension(fileType: any) {
        const inputType = fileType.toLowerCase();
        const msFileExtensionList = ['doc', 'dot', 'docx', 'dotx', 'xls', 'xlt', 'xlsx', 'xltx', 'ppt', 'pot', 'pptx', 'potx'];
        const imageFileExtensionList = ['dwg', 'jpeg', 'jpg', 'png', 'gif', 'pdf', 'kswps', 'kset'];
        return msFileExtensionList.includes(inputType) || imageFileExtensionList.includes(inputType);
    }

    checkExcelFileExtension(fileType: any) {
        const inputType = fileType.toLowerCase();
        const msFileExtensionList = ['xls', 'xlt', 'xlsx', 'xltx'];
        return msFileExtensionList.includes(inputType);
    }

    checkWordFileExtension(fileType: any) {
        const inputType = fileType.toLowerCase();
        const msFileExtensionList = ['doc', 'dot', 'docx', 'dotx'];
        return msFileExtensionList.includes(inputType);
    }

    formatFileSize(bytes: number, decimalPoint: number) {
        if (bytes === 0) { return '0 Bytes'; }
        const dm = decimalPoint || 1;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getYearList() {
        let results = [];
        const year = new Date().getFullYear();
        for (let i = year; i >= 2001; i--) {
            results.push({ value: i.toString(), text: i.toString() });
        }
        return results;
    }

    getTaggedUsers() {
        const htmlObject = tinymce.activeEditor.getBody();
        const taggedUsers = [];
        const eleArr = htmlObject.getElementsByClassName('mention-tag-text');
        for (const obj of eleArr) {
            taggedUsers.push(Number(obj.getAttribute('data-user')));
        }
        return taggedUsers;
    }

    // Check comment is valid
    commentValidation(comment: any) {
        return comment && ($(comment).text().trim() !== '' || comment.includes('<img')) ? comment : '';
    }

    leadLineFieldValidate(data: any) {
        const removeArr = [
            { "text": "Center Track Quantity", "value": "ULOLCENTERTRACKQTY" },
            { "text": "Created From Mobile?", "value": "lolCreatedFromMobile" },
            { "text": "Discount %", "value": "lolDiscountPercent" },
            { "text": "Discount Amount", "value": "lolDiscountAmount" },
            { "text": "Discount Amount Foreign", "value": "lolDiscountAmountForeign" },
            { "text": "Org Part Description", "value": "lolOrgPartShortDescription" },
            { "text": "Org Part ID", "value": "lolOrgPartID" },
            { "text": "Pricing Notes Text", "value": "ULOLPRICINGNOTESTEXT" },
            { "text": "Project Notes Text", "value": "ULOLPROJECTNOTESTEXT" },
            { "text": "Quantity of Equipment", "value": "ULOLQTYOFEQUIP" },
            { "text": "Quantity of Equipment UM", "value": "ULOLQTYOFEQUIPUM" },
            { "text": "Resolution Code", "value": "lolResolutionReasonID" },
            { "text": "Screen Horsepower", "value": "ULOLSCREENHP" },
            { "text": "Screen Motors", "value": "ULOLSCREENMOTOR" },
            { "text": "Screw Horsepower", "value": "ULOLSCREWHP" },
            { "text": "Screw Motor", "value": "ULOLSCREWMOTOR" },
            { "text": "Transferred To Quote?", "value": "lolTransferredToQuote" },
            { "text": "Forecast Date", "value": "lolForecastDate" },
            { "text": "Gross Amount Foreign", "value": "lolGrossAmountForeign" },
            { "text": "Revenue Forecast Foreign", "value": "lolRevenueForecastForeign" },
            { "text": "Freeze protection required", "value": "ULOLFREEZEPROTECTREQ" },
            { "text": "Lead Value Calculation", "value": "ulolLeadValueCalculation" }
        ];
        removeArr.forEach(removeObj => {
            data.forEach((obj: any) => {
                if (obj.value == "ULOLHEAT" && obj.text == "Heat") obj.text = "Freeze Protection"
                if (obj.value == removeObj.value && obj.text == removeObj.text) {
                    data.splice(data.indexOf(obj), 1);
                }
            })
        })
        return data;
    }

    lazyLoadingPagination(skipCount: number, currentPage: number, lastPage: number) {
        let skipCountValue = skipCount;
        if (currentPage < lastPage) {
            return skipCountValue + DefaultResultsPerPage.value;
        }
        return skipCountValue;
    }

    onExportToPDF(grid: GridComponent): void {
        grid.saveAsPDF();
    }

    onExportToCSV(grid: GridComponent): void {
        grid.saveAsExcel();
    }

    setTargetLink(fileUrl: string) {
        return fileUrl.split('.').pop() == 'pdf' ? '_blank' : '';
    }

    setFileNavigationLink(fileUrl: string) {
        return fileUrl.split('.').pop() == 'pdf' ? environment.baseUrl + 'preview?url=' + fileUrl : fileUrl;
    }

    convertToPlain(notes: any) {
        if (notes) {
            let trimmedNotes = notes.trim();
            trimmedNotes = trimmedNotes.replace(/\\par[d]?/g, '');
            return trimmedNotes.replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, '').trim();
        } else {
            return null;
        }
    }

    notificationsTitleMapping(data: any) {
        const notificationModel = _.cloneDeep(this.commonDataModel.notificationTitleModel);
        notificationModel.map((notification: any) => {
            data.map((val: any) => {
                if (val.key === notification.name) {
                    val.title = notification.title;
                }
            })
        })
        return data;
    }

    openRevenueEditingDialog() {
        const dialogRef = this.matDialog.open(LeadlineRevenueEditorDialogComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: ''
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            this.setLoadLeadLinesGrid.next(result.confirmResult);
        });
    }

    getServiceModuleExpansionPath(leadId: any, issueId = 0) {
        return `/lead-details/lead/${leadId}/${issueId}/service`;
    }

    serviceModuleExpansionRedirection(leadId: any, issueId = 0): void {
        this.router.navigate([`lead-details/lead/${leadId}/${issueId}/service`]);
    }

    isCommentShort(comment: string): boolean {
        const maxLines = 3;
        const tempElement = document.createElement('div');
        tempElement.style.position = 'absolute';
        tempElement.style.width = '552px';
        tempElement.innerHTML = comment.replace(/\n/g, '<br>');
        document.body.appendChild(tempElement);
        const isShort = tempElement.clientHeight / parseFloat(getComputedStyle(tempElement).lineHeight) <= maxLines;
        document.body.removeChild(tempElement);
        return isShort;
    }

}
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonConstants } from '../constants/common-constants';
import { CommonMethod } from '../method/common-method';
import { CustomToastrService } from './custom-toastr-service';

@Injectable()

export class BaseHttpService implements HttpInterceptor {

    readonly commonConstants: CommonConstants = CommonConstants;

    constructor(
        private commonMethod: CommonMethod,
        private customToastrService: CustomToastrService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.onCheckURLMatches(request)) {
            const loggedUserData = this.commonMethod.getLoggedUserData();
            if (loggedUserData && loggedUserData?.token) {
                request = request.clone({
                    setHeaders: {
                        'Authorization': `Bearer ${loggedUserData?.token}`,
                        'Pragma': 'no-cache',
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        'Referrer-Policy': 'no-referrer',
                        'X-XSS-Protection': '1; mode=block',
                        'X-Content-Type-Options': 'nosniff',
                        'X-Frame-Options': 'DENY',
                        'X-Permitted-Cross-Domain-Policies': 'none',
                        'Permissions-Policy': 'sync-xhr=*',
                        'Content-Security-Policy': "default-src 'self'",
                        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
                    }
                });
            }
        }
        return next.handle(request).pipe(tap(success => {
        }, error => {
            this.errorHandler(error);
        }));
    }

    onCheckURLMatches(request: any): any {
        if (!request.url.match(/login/g) &&
            !request.url.match(/core.windows.net/g) &&
            !request.url.match(/api.ipify.org/g) &&
            !request.url.match(/.pdf/g)) {
            return true;
        }
        return false;
    }

    errorHandler(error: any): any {
        switch (error.status) {
            case 400:
                this.customToastrService.error(error.error.error);
                break;
            case 401:
                this.commonMethod.logoutWithError(CommonConstants.appConstants.sessionErrorText);
                break;
            case 403:
                this.commonMethod.logoutWithError(CommonConstants.appConstants.permissionErrorText);
                break;
            case 404:
                this.customToastrService.error(CommonConstants.appConstants.serverErrorText);
                break;
        }
    }

}

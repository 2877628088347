<div class="filter-dropdown-wrapper">
    <div class="form-field pe-0">
        <kendo-dropdownlist [data]="gridFilterDropdownSource" [textField]="'key'" [valueField]="'value'"
            [valuePrimitive]="true" [defaultItem]="commonSettings.defaultItems.gridFilterKeyValue" [filterable]="true"
            [kendoDropDownFilter]="commonSettings.dropDownFilterSettings" [(ngModel)]="selectedGridFilter"
            (selectionChange)="onFilterSelectionChange($event)" name="gridFilterDropdown">
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div class="custom-option-wrapper" *ngIf="dataItem?.value">
                    <span matTooltip="Default Report" matTooltipPosition="left" class="default-icon">
                        <img [src]="dataItem.is_default_filter? 'assets/images/star-icon.svg': 'assets/images/star-border-icon.svg'"
                            height="22" [ngClass]="{'active': dataItem.is_default_filter}"
                            (click)="onChangeDefaultFilter(dataItem)" />
                    </span>
                    <span class="option-text" [matTooltip]="dataItem.key" matTooltipPosition="right">
                        {{dataItem.key}}
                    </span>
                    <span class="delete-icon" matTooltip="Delete" matTooltipPosition="right">
                        <img src="assets/images/delete-icon.svg" height="20"
                            (click)="onDeleteFilter($event, dataItem.value)"
                            [ngClass]="{'disabled-img': dataItem.is_default_filter}" />
                    </span>
                </div>
                <div *ngIf="!dataItem?.value">
                    Select Report
                </div>
            </ng-template>
        </kendo-dropdownlist>
    </div>
    <div class="button-icon-wrapper">
        <button mat-flat-button type="button" color="input-color" matTooltip="Clear Filter" (click)="onClearFilter()"
            [disabled]="!selectedGridFilter">
            <img src="assets/images/close-icon.svg" />
        </button>
    </div>
</div>
export class AccountDataModel {

    public loginFormPayload: any = {
        email: '',
        password: '',
        timezone: ''
    }

    public changePasswordFormPayload: any = {
        password: '',
        confirm_password: '',
        new_password: ''
    }

}
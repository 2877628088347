import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';

@Component({
    selector: 'app-resolve-issue-form',
    templateUrl: './resolve-issue-form.component.html'
})
export class ResolveIssueFormComponent implements OnInit {

    commonDataModel = new CommonDataModel()
    resolveIssueFormPayload = _.cloneDeep(this.commonDataModel.resolveIssueFormPayload);
    issueId: any;
    isFormLoading = false;
    activeUserSourceList: any[] = [];
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<ResolveIssueFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.issueId = data?.issueId;
            this.activeUserSourceList = data?.activeUserSourceList;
        }
    }

    ngOnInit() {
    }

    onFormSubmit() {
        if (this.resolveIssueFormPayload.body) {
            this.isFormLoading = true;
            const comment = this.commonMethod.commentValidation(this.resolveIssueFormPayload.body);
            if (comment) {
                this.resolveIssueFormPayload.body = comment;
                this.resolveIssueFormPayload.users = this.commonMethod.getTaggedUsers();
            }
            if (!this.resolveIssueFormPayload.file.fileName) {
                this.resolveIssueFormPayload.file = null;
            }
            this.commonService.postResolveIssueForm(this.issueId, this.resolveIssueFormPayload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    this.dialogRef.close({ confirmResult: true });
                });
        }
    }

    async onChangeFile(event: any) {
        try {
            const resultData = await this.commonMethod.uploadFileValidation(event);
            if (resultData.isFileValid) {
                this.commonMethod.objectMapping(this.resolveIssueFormPayload.file, resultData);
            } else {
                this.resolveIssueFormPayload.file = _.cloneDeep(this.commonDataModel.fileModel);
            }
        } catch (error: any) {
            this.customToastrService.error(error);
        }
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}


import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlphaNumericDirective } from './directive/alpha-numeric.directive';
import { BusyIfDirective } from './directive/busy-if.directive';
import { DecimalNumberDirective } from './directive/decimal-number.directive';
import { DropdownDirective } from './directive/dropdown.directive';
import { HorizontalScrollDirective } from './directive/horizontal-scroll.directive';
import { InputAutoFocusDirective } from './directive/input-auto-focus.directive';
import { InputMaskDirective } from './directive/input-mask.directive';
import { NumericNumberDirective } from './directive/numeric-number.directive';
import { RestrictPatternDirective } from './directive/restrict-pattern.directive';
import { CurrencyValuePipe } from './pipe/currency-value.pipe';
import { CurrentTimeZonePipe } from './pipe/current-time-zone.pipe';
import { DecodeBase64Pipe } from './pipe/decode-base64.pipe';
import { DropdownValueCheckPipe } from './pipe/dropdown-value-check.pipe';
import { EncodeBase64Pipe } from './pipe/encode-base64.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { NegativeValueCheckPipe } from './pipe/negative-value-check.pipe';
import { OptionalValueCheckPipe } from './pipe/optional-value-check.pipe';
import { RemoveUnderscorePipe } from './pipe/remove-underscore.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { ShowMoreTextPipe } from './pipe/show-more-text.pipe';
import { SortByPipe } from './pipe/sort-by.pipe';
import { ValueCheckPipe } from './pipe/value-check.pipe';
import { SecondsToDayPipe } from './pipe/seconds-to-day.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AlphaNumericDirective,
        BusyIfDirective,
        DecimalNumberDirective,
        DropdownDirective,
        HorizontalScrollDirective,
        InputAutoFocusDirective,
        InputMaskDirective,
        NumericNumberDirective,
        RestrictPatternDirective,
        CurrencyValuePipe,
        CurrentTimeZonePipe,
        DecodeBase64Pipe,
        DropdownValueCheckPipe,
        EncodeBase64Pipe,
        FilterPipe,
        NegativeValueCheckPipe,
        OptionalValueCheckPipe,
        RemoveUnderscorePipe,
        SafeUrlPipe,
        ShowMoreTextPipe,
        SortByPipe,
        ValueCheckPipe,
        SecondsToDayPipe
    ],
    exports: [
        CommonModule,
        AlphaNumericDirective,
        BusyIfDirective,
        DecimalNumberDirective,
        DropdownDirective,
        HorizontalScrollDirective,
        InputAutoFocusDirective,
        InputMaskDirective,
        NumericNumberDirective,
        RestrictPatternDirective,
        CurrencyValuePipe,
        CurrentTimeZonePipe,
        DecodeBase64Pipe,
        DropdownValueCheckPipe,
        EncodeBase64Pipe,
        FilterPipe,
        NegativeValueCheckPipe,
        OptionalValueCheckPipe,
        RemoveUnderscorePipe,
        SafeUrlPipe,
        ShowMoreTextPipe,
        SortByPipe,
        ValueCheckPipe,
        SecondsToDayPipe
    ],
    providers: [],
})

export class UtilsModule { }

<div class="details-box-wrapper">
    <div class="text-wrapper">
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <a *ngIf="leadDetailsModel?.id else noData" class="navigate-company-link d-block"
                        (click)="onRedirectCompanyEditForm(commonConstants.formTypeConstants.edit)">
                        <div class="text">{{leadDetailsModel?.id }}</div>
                    </a>
                    <ng-template #noData>
                        <div class="text">N/A</div>
                    </ng-template>
                    <div class="label">Hubspot Company ID</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.name | valueCheck}}</div>
                    <div class="label">Name</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.phone | valueCheck}}</div>
                    <div class="label">Phone</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text text-truncate" matTooltip="{{leadDetailsModel?.location}}">
                        {{leadDetailsModel?.location | valueCheck}}</div>
                    <div class="label">Location</div>
                </div>
            </div>
        </div>
    </div>
</div>
<textarea #textArea *ngIf="editorType === 'comment'" [value]="value" [mention]="userDataSource"
    [mentionConfig]="mentionConfig" [mentionListTemplate]="mentionListTemplate" (change)="onChangeMention()"
    (searchTerm)="onSearchMention($event)">
</textarea>
<textarea #textArea *ngIf="editorType=='endPageEditor'" [value]="value"></textarea>
<ng-template #mentionListTemplate let-item="item">
    <div class="mention-wrapper">
        <img [src]="item.url ? item.url: 'assets/images/profile-img.png'">
        <span class="mention-label" [innerHTML]="item.name"></span>
    </div>
</ng-template>
<textarea #textArea *ngIf="editorType === 'editor' || editorType === 'tempEditor'" class="tiny-temp-editor"
    [value]="value">
</textarea>
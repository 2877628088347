<h1 mat-dialog-title>
    <span>Resolve Issue</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #resolveIssueForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="resolveIssueForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper overflow-visible pb-0">
            <div class="form-field">
                <div class="label-text">Comment <sup>*</sup></div>
                <div [ngClass]="{'error-field': !resolveIssueFormPayload.body.length && resolveIssueForm.submitted}">
                    <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="false" [dropDownList]="activeUserSourceList"
                        [(ngModel)]="resolveIssueFormPayload.body" name="body">
                    </app-tiny-editor>
                </div>
            </div>
            <div class="form-field">
                <label for="myFile" class="custom-input-file">
                    <input type="file" id="myFile" name="filename" class="input-file" ng2FileSelect
                        (change)="onChangeFile($event)">
                </label>
            </div>
        </div>
        <div mat-dialog-actions class="pt-0">
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
import { DataStateChangeEvent, GridDataResult, GroupableSettings, SelectableSettings, SortSettings } from '@progress/kendo-angular-grid';
import { GroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { DefaultResultsPerPage } from '../enums/common-enum';

export class KendoGridHelper {

    defaultRecordsCountPerPage = DefaultResultsPerPage.value;

    resizableColumns = true;

    reorderableColumns = true;

    totalRecordsCount = 0;

    records: any[] = [];

    isLoading = false;

    filter: any;

    skipCount = 0;

    sort: SortDescriptor[] = [];

    defaultSortable: SortSettings;

    groups: GroupDescriptor[] = [];

    groupable: GroupableSettings | boolean;

    selectable: SelectableSettings | boolean;

    get isGrouped() {
        return this.groupable && this.groups && this.groups.length;
    }

    private view = new Array(this.defaultRecordsCountPerPage).fill({}).map(x => ({}));

    get gridData(): GridDataResult {
        return {
            data: this.records,
            total: this.totalRecordsCount
        } as GridDataResult;
    }

    constructor() {
        this.defaultSortable = {
            allowUnsort: true,
            mode: 'single'
        };

        this.groupable = false;
        this.selectable = false;
    }

    setGridData(pagedResult: any): void {
        if (pagedResult) {
            this.totalRecordsCount = pagedResult.total;
            this.records = pagedResult.data || [];
            if (this.records && this.records.length) {
                this.records = process(pagedResult.data, { group: this.groups }).data;
            }
        } else {
            this.totalRecordsCount = 0;
            this.records = [];
        }
    }

    setVirtualScrollGridRecord(pagedResult: any): void {
        const currentView = pagedResult.items;

        const removeCount = this.view.length - currentView.length;

        if (removeCount > 0) {
            this.view.splice(((currentView.length || 1) - 1), removeCount);
        }

        currentView.forEach((item: any, index: number) => {
            if (!this.view[index]) {
                this.view[index] = {};
            }

            Object.assign(this.view[index], item);
        });

        this.setGridData({
            items: this.view,
            totalCount: pagedResult.totalCount
        });
    }

    setRecords<T>(records: T[]): void {
        this.records = records || [];
    }

    showLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = true;
        }, 0);
    }

    hideLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = false;
        }, 0);
    }

    getSorting(event: any): string {
        let sorting: any;
        let sortArray: SortDescriptor[];
        if (!event) {
            sortArray = this.sort;
        } else {
            sortArray = event.sort;
        }

        if (sortArray && sortArray.length) {
            sorting = sortArray[0].field;
            if (sortArray[0].dir) {
                sorting += ' ' + sortArray[0].dir;
            }
        }

        return sorting;
    }

    getSkipCount(event: DataStateChangeEvent): number {
        if (!event) {
            return this.skipCount;
        }

        return event.skip;
    }

    getMaxResultCount(event: DataStateChangeEvent): number {
        if (!event) {
            return this.defaultRecordsCountPerPage;
        }

        return event.take;
    }

    dataStateChangeEvent(event: any): void {
        this.filter = event.filter;
        this.skipCount = event.skip;
        this.sort = event.sort;
        this.groups = event.group;
    }

}

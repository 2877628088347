import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { finalize, Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';

@Component({
    selector: 'app-all-logs-list',
    templateUrl: './all-logs-list.component.html',
    styleUrls: ['./all-logs-list.component.scss']
})
export class AllLogsListComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    virtualScrollPayload: any;
    isFormLoading = false;
    logList: any[] = [];
    currentPage = 1;
    lastPage = 1;
    notificationCount = 0;
    paramLeadId: any;
    paramLeadLineId: any;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set logsNotificationCount(value: any) {
        this.notificationCount = value;
    }

    constructor(
        private commonMethod: CommonMethod,
        private activeRoute: ActivatedRoute,
        private commonService: CommonService) {
        let virtualScrollPayload = _.cloneDeep(this.commonDataModel.virtualScrollPayload);
        this.virtualScrollPayload = Object.assign(virtualScrollPayload, { filter_value: '' });
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramLeadId = params.get('leadId');
            this.paramLeadLineId = params.get('leadlineId');
            this.onLoadItems();
            if (this.notificationCount && Number(this.notificationCount) !== 0) {
                this.notificationCount = 0;
                this.onReadLogsNotification();
            }
        }));
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.commonService.postAllLogRecords(this.virtualScrollPayload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response.data) {
                    this.currentPage = response.data?.current_page;
                    this.lastPage = response.data?.last_page;
                    this.logList = this.virtualScrollPayload.skip_count === 0 ? response.data.data : this.logList.concat(...response.data.data);
                }
            });
    }

    onReadLogsNotification(): void {
        const inputData = {
            lead_id: this.paramLeadId,
            leadline_id: this.paramLeadLineId
        }
        this.commonService.postReadLogsNotification(inputData).subscribe(response => {
            this.commonMethod.sendLoadAllLogsRecords(false);
        });
    }

    onScrollDown() {
        if (this.logList?.length > 1) {
            const skipCount = this.commonMethod.lazyLoadingPagination(this.virtualScrollPayload?.skip_count, this.currentPage, this.lastPage);
            if (skipCount !== this.virtualScrollPayload.skip_count) {
                this.virtualScrollPayload.skip_count = skipCount;
                this.onLoadItems();
            }
        }
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}

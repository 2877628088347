import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { ProfileDataModel } from 'src/app-core/data-model/profile-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { ProfileService } from 'src/app-core/services/profile.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
    selector: 'app-profile-info',
    templateUrl: './profile-info.component.html',
    styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel()
    profileDataModel = new ProfileDataModel();
    profileFormPayload = this.profileDataModel.profileFormPayload;
    dropDownDataSource: any[] = [];
    commonConstants: typeof CommonConstants = CommonConstants;
    isFormLoading = false;
    isNotificationsLoading = false;
    userId: any;
    notificationDataSource: any[] = [];
    allowedExtensions = ['png', 'jpeg', 'bmp', 'jpg', 'svg'];
    allowedExtensionsString = `.${this.allowedExtensions.join(',.')}`;

    constructor(
        private matDialog: MatDialog,
        private profileService: ProfileService,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<ProfileInfoComponent>,
        @Inject(MAT_DIALOG_DATA) data?: string) {
    }

    ngOnInit() {
        this.userId = this.commonMethod.getUserId();
        this.onLoadItems();
    }

    onLoadItems() {
        this.isFormLoading = true;
        this.commonService.getUserFormRecord(this.userId)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response.data) {
                    this.commonMethod.objectMapping(this.profileFormPayload, response.data);
                    this.profileFormPayload.file = '';
                }
            });
        this.isNotificationsLoading = true;
        this.profileService.getUserNotifications()
            .pipe(finalize(() => this.isNotificationsLoading = false))
            .subscribe(response => {
                if (response.data) {
                    this.notificationDataSource = this.commonMethod.notificationsTitleMapping(response.data);
                }
            });
    }

    onFormSubmit() {
        this.isFormLoading = true;
        const payload = {
            name: this.profileFormPayload.name,
            file: this.profileFormPayload.file,
            notifications: this.profileFormPayload.notifications
        }
        this.profileService.postUserUpdateForm(this.userId, payload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response?.data) {
                    this.commonMethod.setUserName({ name: response.data.name, file: response.data.file });
                    this.customToastrService.success(response?.data.message);
                }
                this.dialogRef.close({ confirmResult: true });
            });
    }

    onShowProfileInfo(): void {
        this.dialogRef.close({ confirmResult: false });
        this.matDialog.open(ChangePasswordComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: {
                isDisabledClosedBtn: false,
                isShowOldPassword: true
            }
        });
    }

    async onChangeFile(event: any) {
        try {
            const resultData = await this.commonMethod.imageFileUploadValidation(event);
            if (resultData.isFileValid) {
                this.profileFormPayload.file = resultData.url;
            } else {
                this.profileFormPayload.file = null;
            }
        } catch (error: any) {
            this.customToastrService.error(error);
        }
    }

    onClearImage() {
        this.profileFormPayload.file = null;
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

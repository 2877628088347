import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { LeadDataModel } from 'src/app-core/data-model/lead-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LeadService } from 'src/app-core/services/lead-service.service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-edit-status-information',
    templateUrl: './edit-status-information.component.html'
})
export class EditStatusInformationComponent implements OnInit {

    isFormLoading = false;
    commonSettings = new CommonSettings();
    leadDataModel = new LeadDataModel();
    commonDataModel = new CommonDataModel();
    statusInformationPayload = _.cloneDeep(this.leadDataModel.statusInformationPayload);
    leadStageDataSource = _.cloneDeep(this.commonDataModel.leadStageDataSource);
    statusDatelist: any[] = [];
    closedReasonDataList: any[] = [];
    formType: any;
    loggedUserData: any;
    dateFormat = this.commonSettings.dateFormat;

    constructor(
        private leadService: LeadService,
        private commonMethod: CommonMethod,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<EditStatusInformationComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.formType = data?.formType;
            this.statusInformationPayload.deal_id = data?.leadId;
        }
    }

    ngOnInit(): void {
        this.onLoadItems();
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.loggedUserData = this.commonMethod.getLoggedUserData();
        this.leadService.getLeadFields()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.statusDatelist = response.data.dd_fields.status;
                this.closedReasonDataList = response.data.dd_fields.closed_reason;
            });
        this.leadService.editlead(this.statusInformationPayload.deal_id)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.commonMethod.objectMapping(this.statusInformationPayload, response.data);
                this.statusInformationPayload.bid_date = this.commonMethod.dateFieldMapping(response.data?.bid_date);
                this.statusInformationPayload.lead_date = this.commonMethod.dateFieldMapping(response.data?.lead_date);
                this.statusInformationPayload.deal_closed_date = this.commonMethod.dateFieldMapping(response.data?.deal_closed_date);
                this.statusInformationPayload.deal_stage_date = this.commonMethod.dateFieldMapping(response.data?.deal_stage_date);
                this.statusInformationPayload.closed_by = this.statusInformationPayload.closed_by == null ? this.loggedUserData.name : response.data.closed_by;
            })
    }

    onFormSubmit() {
        this.isFormLoading = true;
        const payloadToSend = { ...this.statusInformationPayload };
        payloadToSend.bid_date = this.commonMethod.convertDate(this.statusInformationPayload.bid_date);
        payloadToSend.lead_date = this.commonMethod.convertDate(this.statusInformationPayload.lead_date);
        payloadToSend.deal_closed_date = this.commonMethod.convertDate(this.statusInformationPayload.deal_closed_date);
        payloadToSend.deal_stage_date = this.commonMethod.convertDate(this.statusInformationPayload.deal_stage_date);
        delete payloadToSend.closed_by;
        if (payloadToSend.status == 'Open' || payloadToSend.status == 'Pending') {
            payloadToSend.closed_reason = null;
        }
        this.leadService.updateStatusDetails(payloadToSend)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response?.isWarning) {
                    this.customToastrService.warning(response?.message);
                } else {
                    this.customToastrService.success(response?.message);
                }
                this.dialogRef.close({ confirmResult: true });
            });
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

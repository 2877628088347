import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from 'src/app-core/services/loader.service';

@Component({
    selector: 'app-loader-indicator',
    templateUrl: './loader-indicator.component.html',
    styleUrls: ['./loader-indicator.component.scss']
})
export class LoaderIndicatorComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    isLoading: Subject<boolean> = this.loaderService.isLoading;
    displayLoadingText = '';

    constructor(
        private loaderService: LoaderService) {
    }

    ngOnInit() {
        this.componentSubscriptions.add(this.loaderService.getLoadingText.subscribe(data => {
            if (data) {
                this.displayLoadingText = data;
            } else {
                this.displayLoadingText = '';
            }
        }));
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}

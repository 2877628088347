import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';

@Component({
    selector: 'app-grid-filter-form',
    templateUrl: './grid-filter-form.component.html',
    styleUrls: ['./grid-filter-form.component.scss']
})
export class GridFilterFormComponent {

    commonDataModel = new CommonDataModel();
    isFormLoading = false;
    gridName: string = '';
    gridFilterFormPayload = _.cloneDeep(this.commonDataModel.gridFilterFormPayload);
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<GridFilterFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.commonMethod.objectMapping(this.gridFilterFormPayload, data);
        }
    }

    onFormSubmit() {
        this.isFormLoading = true;
        this.commonService.postGridFilterForm(this.gridFilterFormPayload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.customToastrService.success(response?.message);
                this.dialogRef.close({ confirmResult: true });
            });
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { finalize } from 'rxjs';
import { AccountDataModel } from 'src/app-core/data-model/account-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { ProfileService } from 'src/app-core/services/profile.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

    @ViewChild('oldPassword') public oldPassword: any = TextBoxComponent;
    @ViewChild('newPassword') public newPassword: any = TextBoxComponent;
    @ViewChild('confirmPassword') public confirmPassword: any = TextBoxComponent;
    accountDataModel = new AccountDataModel();
    changePasswordFormPayload = this.accountDataModel.changePasswordFormPayload;
    isFormLoading = false;
    isPasswordMatch: boolean = true;
    changePasswordForm: any;

    constructor(
        private customToastrService: CustomToastrService,
        private profileService: ProfileService,
        private commonMethod: CommonMethod,
        private dialogRef: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.changePasswordForm = data;
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        if (this.changePasswordForm.isShowOldPassword) {
            this.oldPassword.input.nativeElement.type = 'password';
        }
        this.newPassword.input.nativeElement.type = 'password';
        this.confirmPassword.input.nativeElement.type = 'password';
    }

    onFormSubmit() {
        const userData = this.commonMethod.getLoggedUserData();
        if (this.changePasswordFormPayload.new_password === this.changePasswordFormPayload.confirm_password) {
            this.isPasswordMatch = true;
            this.isFormLoading = true;
            if (!this.changePasswordForm.isShowOldPassword) {
                this.changePasswordFormPayload.password = userData.tempPassword;
            }
            this.profileService.postChangePasswordForm(this.changePasswordFormPayload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    userData.passwordReset = 0;
                    userData.tempPassword = '';
                    this.commonMethod.setLoggedUserData(userData);
                    this.customToastrService.success(response?.message);
                    this.onClose();
                });
        } else {
            this.isPasswordMatch = false;
        }

    }

    onClose() {
        this.dialogRef.close();
    }

}

<div class="logs-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <div class="logs-content-wrapper" infinite-scroll [infiniteScrollUpDistance]="10" [scrollWindow]="false"
        (scrolled)="onScrollDown()">
        <ng-container *ngIf="logList.length">
            <div class="logs-box-wrapper" *ngFor="let item of logList">
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="profile-wrapper">
                            <img [src]="item?.user_image ? item.user_image : 'assets/images/profile-img.png'" />
                            <div class="title-text">{{item?.changed_by}}</div>
                            <div class="date-text">{{item?.change_at}}</div>
                        </div>
                    </div>
                    <div class="heading-text">Leadline Form</div>
                    <div class="col-6 ps-0">
                        <div class="heading-text-sm">Old Value</div>
                    </div>
                    <div class="col-6 pe-0">
                        <div class="heading-text-sm">New Value</div>
                    </div>
                </div>
                <div class="title-text-sm">{{item?.section}}</div>
                <ng-container *ngFor="let list of item?.field_data">
                    <div class="row text-content">
                        <div class="col-6 ps-0">
                            <div class="label">{{list?.changed_key}}</div>
                            <div class="text">{{list?.old_value | valueCheck}}</div>
                        </div>
                        <div class="col-6 pe-0">
                            <div class="label">{{list?.changed_key}}</div>
                            <div class="text">{{list?.new_value | valueCheck}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="no-record-text mt-2" *ngIf="!isFormLoading && !logList.length">
            {{commonConstants.appConstants.noRecordsAvailable}}
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-revise-due-date-dialog',
    templateUrl: './revise-due-date-dialog.component.html',
    styleUrls: ['./revise-due-date-dialog.component.scss']
})
export class ReviseDueDateDialogComponent implements OnInit {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel();
    isFormLoading = false;
    reviseDueDate: any;
    minDate = new Date();
    requestId: any;
    formType: any;
    dateTimeFormat = this.commonSettings.dateTimeFormat;
    reviseDueDateFormPayload = _.cloneDeep(this.commonDataModel.reviseDueDateFormPayload);
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<ReviseDueDateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.requestId = data?.requestId;
            this.formType = data?.formType;
        }
    }

    ngOnInit() {
    }

    onFormSubmit() {
        this.isFormLoading = true;
        const payload = _.cloneDeep(this.reviseDueDateFormPayload);
        payload.due_date = this.commonMethod.convertDateTime(this.reviseDueDate);
        if (this.formType === CommonConstants.gridNameConstants.documentStatusGrid) {
            this.commonService.postReviseDueDateForm(this.requestId, payload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    this.dialogRef.close({ confirmResult: true });
                });
        } else {
            this.commonService.postIssueReviseDueDate(this.requestId, payload)
                .pipe(finalize(() => this.isFormLoading = false))
                .subscribe(response => {
                    this.customToastrService.success(response?.message);
                    this.dialogRef.close({ confirmResult: true });
                });
        }
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'optionalValueCheck'
})
export class OptionalValueCheckPipe implements PipeTransform {

    transform(value: any): any {
        return value ? value : '';
    }

}

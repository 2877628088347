import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CompanyFormComponent } from '../company-form/company-form.component';

@Component({
    selector: 'app-lead-organization-info',
    templateUrl: './lead-organization-info.component.html'
})
export class LeadOrganizationInfoComponent implements OnInit {

    leadDetailsModel: any;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set data(list: any) {
        this.leadDetailsModel = list;
    }

    constructor(
        private matDialog: MatDialog,
    ) { }

    ngOnInit(): void {
    }

    onRedirectCompanyEditForm(formType: string): void {
        this.matDialog.open(CompanyFormComponent, {
            position: {
                right: '0',
            },
            panelClass: 'sm-dialog-wrapper',
            hasBackdrop: false,
            data: {
                id: this.leadDetailsModel?.company_id,
                formType: formType
            }
        }).afterClosed().subscribe((result: { confirmResult: boolean }) => { });
    }

}

import { Pipe, PipeTransform } from '@angular/core';

declare var $: any;

@Pipe({
    name: 'showMoreText'
})
export class ShowMoreTextPipe implements PipeTransform {

    transform(text: any, args?: any): any {
        if (text) {
            if ((text.includes('<html') &&
                (text.includes('<img') || text.split('<p>').length - 1 > 3 || $(text).text().length > 616)) ||
                text.split('</p').length + text.split('<br').length - 2 > 3) {
                return true;
            } else if (text.length > 230) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

}

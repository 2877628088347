import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-grid-filter-dropdown',
    templateUrl: './grid-filter-dropdown.component.html',
    styleUrls: ['./grid-filter-dropdown.component.scss']
})
export class GridFilterDropdownComponent implements OnInit, OnDestroy {

    @Output() selectedFilter = new EventEmitter<any>();
    componentSubscriptions: Subscription = new Subscription();
    commonSettings = new CommonSettings();
    gridNameValue = '';
    gridSavedFilterList: any;
    gridFilterDropdownSource: any;
    selectedGridFilter: any;
    isCheckDefaultFilter = true;
    commonConstants: typeof CommonConstants = CommonConstants;

    @Input() set gridName(data: string) {
        this.gridNameValue = data;
    }

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService) {
    }

    ngOnInit(): void {
        this.onLoadItems();
        this.componentSubscriptions.add(
            this.commonMethod.getLoadGridFilterDropdown.subscribe((data: any) => {
                if (data && data?.isLoadDropDown) {
                    this.isCheckDefaultFilter = data?.isCheckDefaultFilter;
                    this.onLoadItems();
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getClearGridFilterDropdown.subscribe((data: boolean) => {
                if (data) {
                    this.onClearFilter();
                }
            })
        );
    }

    onLoadItems(): void {
        this.commonMethod.sendLoadGridFilterDropdown({ isLoadDropDown: false, isCheckDefaultFilter: true });
        this.commonService.getGridFilter(this.gridNameValue).subscribe(response => {
            if (response.data) {
                this.gridSavedFilterList = response.data?.saved_filter_list;
                this.gridFilterDropdownSource = response.data?.filter_dropdown_list;
                if (this.isCheckDefaultFilter) {
                    setTimeout(() => {
                        this.checkDefaultFilter();
                    }, 4000);
                }
            }
        });
    }

    onUpdateFilter(data: any): void {
        if (data) {
            this.commonService.updateGridFilter(data).subscribe(response => {
                if (response) {
                    this.customToastrService.success(response?.message);
                }
            });
        }
    }

    checkDefaultFilter(): void {
        if (this.gridFilterDropdownSource?.length) {
            const results = _.find(this.gridFilterDropdownSource, ['is_default_filter', true]);
            if (results) {
                this.selectedGridFilter = results.value;
                this.sendSavedFilterObject(results.value);
            } else {
                this.onClearFilter();
            }
        } else {
            this.onClearFilter();
        }
    }

    onChangeDefaultFilter(dataItem: any): void {
        if (dataItem?.value) {
            _.each(this.gridFilterDropdownSource, (list: any) => {
                list.is_default_filter = false;
                if (list.value === dataItem.value) {
                    list.is_default_filter = true;
                }
                return list;
            });
            _.each(this.gridSavedFilterList, (list: any) => {
                list.is_default_filter = false;
                if (list.id === dataItem.value) {
                    list.is_default_filter = true;
                }
                return list;
            });
            const results = _.find(this.gridSavedFilterList, ['id', dataItem.value]);
            this.onUpdateFilter(results);
            this.checkDefaultFilter();
        }
    }

    onFilterSelectionChange(dataItem: any): void {
        if (dataItem?.value) {
            this.sendSavedFilterObject(dataItem?.value);
        } else {
            this.onClearFilter();
        }
    }

    onDeleteFilter(event: any, id: number): void {
        event.stopPropagation();
        const payload = {
            id: id,
            grid_name: this.gridNameValue
        }
        this.commonService.deleteGridFilter(payload).subscribe(response => {
            if (response) {
                _.remove(this.gridSavedFilterList, { id: id });
                _.remove(this.gridFilterDropdownSource, { value: id });
                this.customToastrService.success(response?.message);
            }
        });
    }

    sendSavedFilterObject(id: any) {
        const results = _.find(this.gridSavedFilterList, ['id', id]);
        if (results) {
            this.selectedFilter.emit(results);
        }
    }

    onClearFilter(): void {
        this.selectedGridFilter = null;
        this.selectedFilter.emit(null);
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}

import { AfterViewInit, Component, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { orderBy } from '@progress/kendo-data-query';
import { MentionDirective } from 'angular-mentions';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';

declare let tinymce: any;
declare var $: any;

export const TINYMCE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TinyEditorComponent),
    multi: true,
};

@Component({
    selector: 'app-tiny-editor',
    templateUrl: './tiny-editor.component.html',
    providers: [TINYMCE_VALUE_ACCESSOR],
})
export class TinyEditorComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {

    @ViewChild(MentionDirective) mention: any = MentionDirective;
    @ViewChild('textArea') textArea: any;
    @Output() tinyNotify: EventEmitter<any> = new EventEmitter();
    userDataSource: any[] = [];
    tinymceRef: any;
    editor: any;
    value: any;
    mentionConfig = {
        triggerChar: '@',
        labelKey: 'name',
        mentionSelect: this.onMentionFormat,
        dropUp: true
    }
    plugins = [
        'preview',
        'image',
        'searchreplace',
        'autolink',
        'directionality',
        'visualblocks',
        'visualchars',
        'fullscreen',
        'link',
        'media',
        'table',
        'charmap',
        'pagebreak',
        'nonbreaking',
        'anchor',
        'insertdatetime',
        'advlist',
        'lists',
        'wordcount',
        'help',
    ]

    @Input('tinyType') editorType: any;
    @Input('specTool') isSpecTool: any;
    @Input('placeHolderText') placeHolderText: any;
    @Input() set dropDownList(data: any) {
        if (data?.length) {
            this.userDataSource = data;
        }
    }
    @Input() set isLoadItem(data: boolean) {
        if (data) {
            this.onLoadItems();
        }
    }

    constructor(
        private _zone: NgZone,
        private commonMethod: CommonMethod,
        private commonService: CommonService) {
    }

    ngOnInit() {
    }

    onLoadItems(): void {
        this.commonService.getActiveUserList().subscribe(response => {
            if (response?.data) {
                this.userDataSource = response.data;
            }
        });
    }

    onChange = (_: any) => { };

    writeValue(value: any): void {
        this.value = value ? value : '';
        if (this.editor) {
            this.editor.setContent(this.value);
            tinymce.get(this.textArea.nativeElement.id).setContent(this.value);
        }
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void { }

    ngAfterViewInit() {
        switch (this.editorType) {
            case 'comment':
                this.getCommentEditorConfig();
                break;
            case 'endPageEditor':
                this.getPageEditorConfig();
                break;
            default:
                this.getEditorConfig();
                break;
        }
    }

    getCommentEditorConfig() {
        tinymce.init({
            base_url: '/tinymce/', // Root for resources
            suffix: '.min', // Suffix to use when loading resources
            target: this.textArea.nativeElement,
            mobile: {
                theme: 'silver',
            },
            plugins: [],
            paste_as_text: false,
            toolbar: false,
            menubar: false,
            statusbar: false,
            paste_data_images: true,
            image_advtab: true,
            body_class: 'tiny-body-wrapper',
            placeholder: this.placeHolderText ? this.placeHolderText : '',
            content_css: "/assets/styles/tiny-editor.scss",
            height: 125,
            content_style: `
                body {
                    color: #021420;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                    padding: 8px 16px;
                    margin: 0;
                    background: transparent;
                }

                .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
                    left: 16px;
                }

                p {
                    margin: 0;
                }
                
            }`,
            setup: (editor: any) => {
                this.editor = editor;
                editor.on('init', (args: any) => {
                    this.mention.setIframe(editor.iframeElement);

                });
                editor.on('keydown', (e: any) => {
                    this.mention.setIframe(editor.iframeElement);
                    const frame = <any>window.frames[editor.iframeElement.id];
                    const contentEditable = frame.contentDocument.getElementById('tinymce');
                    this._zone.run(() => {
                        this.mention.keyHandler(e, contentEditable);
                    });
                });
                editor.on('keyup', () => {
                    const content = editor.getContent();
                    this._zone.run(() => this.onChange(content));
                });
                editor.on('PreProcess', (e: any) => {
                    this.tinymceRef = editor;
                });
                editor.on('blur', (e: any) => {
                    const content = this.commonMethod.commentValidation(editor.getContent());
                    this._zone.run(() => this.onChange(content));
                });
            },
        });
    }

    getPageEditorConfig() {
        tinymce.init({
            base_url: '/tinymce/', // Root for resources
            suffix: '.min', // Suffix to use when loading resources
            target: this.textArea.nativeElement,
            mobile: {
                theme: 'silver',
            },
            plugins: this.plugins,
            paste_as_text: false,
            toolbar: false,
            menubar: false,
            statusbar: false,
            paste_data_images: true,
            image_advtab: true,
            height: 300,
            content_style: `
                body {
                    color: #021420;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                    padding: 8px 16px;
                    margin: 0;
                    background: transparent;
                }

                p {
                    margin-top: 0;
                }

            }`,
            setup: (editor: any) => {
                this.editor = editor;
                editor.on('keyup', () => {
                    const content = editor.getContent();
                    this._zone.run(() => this.onChange(content));
                });
                editor.on('PreProcess', (e: any) => {
                    this.tinymceRef = editor;
                });
            },
        });
    }

    getEditorConfig(isSpecTool?: any) {
        let toolBarArr = '';
        if (this.editorType == 'tempEditor') {
            toolBarArr =
                'undo  redo  styleselect  fontselect fontsizeselect |  bold italic underline emoticons  forecolor backcolor linespace | alignleft aligncenter alignright alignjustify | outdent indent | link unlink bullist numlist continueOrderList image | EditButton | leadFieldButton quesFieldButton ruleFieldButton pagebreak searchreplace';
        } else if (this.editorType == 'editor' && (isSpecTool || (isSpecTool != false && this.isSpecTool))) {
            toolBarArr =
                'undo  redo  styleselect  fontsizeselect |  bold italic underline emoticons  forecolor backcolor linespace | alignleft aligncenter alignright alignjustify | outdent indent | link unlink bullist numlist continueOrderList image | EditButton | leadFieldButton specFieldButton pagebreak searchreplace';
        } else {
            toolBarArr =
                'undo  redo  styleselect  fontsizeselect |  bold italic underline emoticons  forecolor backcolor linespace | alignleft aligncenter alignright alignjustify | outdent indent | link unlink bullist numlist continueOrderList image | EditButton | leadFieldButton pagebreak searchreplace';
        }
        const tinymceConfig = {
            base_url: '/tinymce/', // Root for resources
            suffix: '.min', // Suffix to use when loading resources
            selector: '.tiny-temp-editor',
            mobile: {
                theme: 'silver',
            },
            plugins: this.plugins,
            importcss_append: true,
            toolbar: toolBarArr,
            menubar: false,
            statusbar: false,
            height: this.editorType === 'editor' ? 250 : 500,
            paste_data_images: true,
            image_advtab: true,
            content_style: `
                body {
                    color: #021420;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                    padding: 8px 16px;
                    margin: 0;
                    background: transparent;
                }

                p {
                    margin-top: 0;
                }
            }`,
            setup: (editor: any) => {
                editor.settings.toolbar = toolBarArr;
                this.editor = editor;
                const that = this;
                editor.on('keyup', () => {
                    const content = editor.getContent();
                    this._zone.run(() => this.onChange(content));
                });

                editor.on('keyup', (e: any) => {
                    if (e.keyCode == 9) {
                        let curEleme = editor.selection.getNode();
                        let curOl = curEleme.closest('ol');
                        $(curOl).removeAttr('start');
                    }
                });

                editor.on('blur', (e: any) => {
                    const content = that.commonMethod.commentValidation(editor.getContent());
                    this._zone.run(() => this.onChange(content));
                });
                if (this.editorType == 'editor') {
                    editor.ui.registry.addButton('specFieldButton', {
                        text: 'Insert Sub-Question',
                        tooltip: 'Insert Sub-Question',
                        onAction: function (_: any) {
                            that.tinyNotify.emit();
                        },
                    });
                }
                if (this.editorType == 'tempEditor') {
                    editor.ui.registry.addButton('quesFieldButton', {
                        text: 'Add Question',
                        tooltip: 'Add Question',
                        onAction: function (_: any) {
                            that.tinyNotify.emit('question');
                        },
                    });
                    editor.ui.registry.addButton('continueOrderList', {
                        icon: 'more-drawer',
                        tooltip: 'Continue Numbered List',
                        onAction: function (_: any) {

                            let htmlContent = editor.getContent();
                            editor.focus();


                            if (editor.dom.getParent(editor.selection.getNode(), 'li')) {

                                let curOL = editor.dom.getParent(editor.selection.getNode(), 'ol');
                                let curLI = editor.dom.getParent(editor.selection.getNode(), 'li');
                                let li = curLI.closest('li');
                                let nodes = Array.from(li.closest('ol').children);
                                let index = nodes.indexOf(li);

                                let totallis = nodes.length - 1;

                                if (totallis > 0) {
                                    if (index == 0) {
                                        editor.dom.getParent(editor.selection.getNode(), 'li').remove();
                                        $("<p>" + curLI.innerHTML + "</p>").insertBefore(curOL);
                                    }
                                    else if (index == totallis) {
                                        editor.dom.getParent(editor.selection.getNode(), 'li').remove();
                                        $("<p>" + curLI.innerHTML + "</p>").insertAfter(curOL);
                                    }
                                    else {
                                        editor.dom.getParent(editor.selection.getNode(), 'ol').remove();
                                        var newOl = "<ol>";
                                        $(nodes).each(function (liIndex: any) {
                                            if (liIndex < index) {
                                                newOl = newOl + "<li>" + $().text() + "</li>";
                                            }
                                        });
                                        newOl = newOl + '</ol>';
                                        newOl = newOl + '<p> ' + curLI.innerHTML + ' </p>';

                                        newOl = newOl + "<ol>";
                                        $(nodes).each(function (liIndex: any) {
                                            if (liIndex > index) {
                                                newOl = newOl + "<li>" + $().text() + "</li>";
                                            }
                                        });
                                        newOl = newOl + '</ol>';
                                        editor.insertContent(newOl);
                                    }
                                }
                                else {
                                    editor.dom.getParent(editor.selection.getNode(), 'li').remove();
                                    editor.insertContent('<p>');
                                    editor.insertContent(curLI.innerHTML);
                                    editor.insertContent('</p>');
                                }
                            }
                            else {
                                let innerHTML = editor.dom.getParent(editor.selection.getNode(), 'p').innerHTML;
                                editor.dom.getParent(editor.selection.getNode(), 'p').remove();

                                let parser = new DOMParser();
                                let parsedHtml = parser.parseFromString(htmlContent, 'text/html');

                                let olCount = parsedHtml.getElementsByTagName('ol');

                                var nextNumber = 1
                                if (olCount.length > 0) {
                                    let rawLiElements = parsedHtml.getElementsByTagName("ol")[olCount.length - 1].children;
                                    nextNumber = rawLiElements.length + 1;

                                }

                                if (olCount.length > 1) {

                                    editor.windowManager.open({
                                        title: 'Ordered List',
                                        body: {
                                            type: 'panel',
                                            items: [
                                                {
                                                    type: 'input',
                                                    name: 'orderNumber',
                                                    label: 'Enter the value',
                                                },
                                            ]
                                        },
                                        buttons: [

                                            {
                                                type: 'submit',
                                                text: 'Apply',
                                                primary: true
                                            }
                                        ],

                                        initialData: {
                                            orderNumber: nextNumber.toString()
                                        },

                                        onFormSubmit: function (api: any) {
                                            var data = api.getData();
                                            editor.windowManager.close()
                                            editor.insertContent('<ol start="' + data.orderNumber + '"><li><br data-mce-bogus="1">');
                                            editor.insertContent(innerHTML);
                                            editor.insertContent('</li></ol>');
                                        }
                                    });
                                }

                                else {

                                    editor.insertContent('<ol start="' + nextNumber + '"><li><br data-mce-bogus="1">');
                                    editor.insertContent(innerHTML);
                                    editor.insertContent('</li></ol>');

                                }
                            }

                        },
                    });
                    editor.ui.registry.addButton('ruleFieldButton', {
                        text: 'Add Rule',
                        tooltip: 'Add Rule',
                        onAction: function (_: any) {
                            that.tinyNotify.emit('rule');
                        },
                    });
                }
                editor.ui.registry.addButton('leadFieldButton', {
                    text: 'Insert Field',
                    tooltip: 'Insert Field',
                    onAction: function (_: any) {
                        tinymce.activeEditor.setProgressState(true);
                        that.commonService.getLeadLineFields().subscribe((res: any) => {
                            tinymce.activeEditor.setProgressState(false);
                            if (res.status === 200) {
                                let returnData = res.data;
                                returnData.push({ text: "Today's Date", value: 'todaysdate' });
                                returnData = that.commonMethod.leadLineFieldValidate(returnData);
                                returnData = orderBy(returnData, [{ field: 'text', dir: 'asc' }]);
                                editor.windowManager.open({
                                    title: 'Insert Field',
                                    body: {
                                        type: 'panel',
                                        classes: 'insertField',
                                        items: [
                                            {
                                                type: 'selectbox',
                                                name: 'fieldname',
                                                label: 'Select :',
                                                items: returnData,
                                            },
                                        ],
                                    },
                                    buttons: [
                                        {
                                            type: 'custom',
                                            name: 'submitButton',
                                            text: 'Ok',
                                            primary: true,
                                        },
                                        {
                                            type: 'cancel',
                                            name: 'closeButton',
                                            text: 'Cancel',
                                        },
                                    ],
                                    initialData: {
                                        fieldname: '',
                                    },
                                    onAction: function (dialogApi: any) {
                                        const data = dialogApi.getData();
                                        const newHtml =
                                            '<span class="field-text" data-field-name="' +
                                            data.fieldname +
                                            '" contenteditable="false">' +
                                            data.fieldname +
                                            '</span>';
                                        editor.insertContent(newHtml);
                                        dialogApi.close();
                                    },
                                });
                                $('.insertField select').select2();
                            }
                        });
                    },
                });
            },
        };
        if (isSpecTool != undefined) {
            tinymce.remove();
        }
        tinymce.init(tinymceConfig);
    }

    onChangeMention() {
        const data = this.tinymceRef.getContent();
        this.tinymceRef.setContent(data.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>'));
    }

    onMentionFormat(item: any) {
        return '<span class="mention-tag-text" style="color: #005287; font-weight: 600; padding: 0 2px" data-user="' + item.id + '" contenteditable="false">' + item.name + ' </span>';
    }

    onSearchMention(event: any) {
        const filterData = this.userDataSource.filter((obj: any) => {
            return obj.name.toUpperCase().indexOf(event.toUpperCase()) >= 0;
        });
        return filterData;
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
    }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonMethod } from '../method/common-method';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class LeadLineService {

    leadId: any;
    leadlineId: any;
    requestId: any;

    constructor(
        private commonMethod: CommonMethod,
        private httpCommonService: HttpCommonService) {
    }

    // Update Part Info
    private setLeadLineHeader = new BehaviorSubject<any>({});
    getLeadLineHeader = this.setLeadLineHeader.asObservable();

    // Get Score
    private setLeadLineScore = new BehaviorSubject<boolean>(false);
    getLeadLineScore = this.setLeadLineScore.asObservable();

    private scrollLeftRight = new BehaviorSubject<any>(null);
    getScrollValue = this.scrollLeftRight.asObservable();

    updateScroll(data: any) {
        this.scrollLeftRight.next(data);
    }

    updateLeadLineHeader(data: any) {
        this.setLeadLineHeader.next(data);
    }

    callLeadLineScore(data: boolean) {
        this.setLeadLineScore.next(data);
    }

    setLeadId(leadId: any) {
        this.leadId = leadId;
    }

    setLeadlineId(leadlineId: any) {
        this.leadlineId = leadlineId;
    }

    setRequestId(requestId: any) {
        this.requestId = requestId;
    }

    getIdInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/id-info/${this.leadId}`);
    }

    postIdInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/create/${this.leadId}`, inputData);
    }

    getPartInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/partgroup/${this.leadId}/${this.leadlineId}`);
    }

    postPartInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/partgroup/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getRevenueInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/revenueinfo/${this.leadId}/${this.leadlineId}`);
    }

    postRevenueInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/revenueinfo/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getProjectInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/projectinformation/${this.leadId}/${this.leadlineId}`);
    }

    postProjectInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/projectinformation/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getScreenInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/screeninfo/${this.leadId}/${this.leadlineId}`);
    }

    postScreenInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/screeninfo/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getGritEquipmentInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/gritequipmentinfo/${this.leadId}/${this.leadlineId}`);
    }

    postGritEquipmentInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/gritequipmentinfo/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getEquipmentInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/equipmentinformation/${this.leadId}/${this.leadlineId}`);
    }

    postEquipmentInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/equipmentinformation/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getBidInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/bidsinformation/${this.leadId}/${this.leadlineId}`);
    }

    postBidInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/bidsinformation/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getControlsInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/controls/${this.leadId}/${this.leadlineId}`);
    }

    postControlsInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/controls/${this.leadId}/${this.leadlineId}`, inputData);
    }

    getPricingInfoForm(): Observable<any> {
        return this.httpCommonService.getRecord(`leadline-form/pricinginformation/${this.leadId}/${this.leadlineId}`);
    }

    postPricingInfoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/pricinginformation/${this.leadId}/${this.leadlineId}`, inputData);
    }

    onTabRedirection(url: string) {
        const prepareData = {
            lead_id: this.leadId,
            leadline_id: this.leadlineId,
            request_id: this.requestId,
        }
        this.commonMethod.leadLineModuleRedirection(prepareData, url);
    }

}

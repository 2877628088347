import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class ContactCompanyService {

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    getContactsGrid(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`contactgrid`, inputData);
    }

    postContactForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`create-contact`, inputData);
    }

    getContactId(): Observable<any> {
        return this.httpCommonService.getRecord(`get-contact-id`);
    }

    getContactDetails(contact_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`contact/${contact_id}`);
    }

    getIndustryType(): Observable<any> {
        return this.httpCommonService.getRecord(`contact-industry-type`);
    }

    getArchiveStatus(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`change-contact-status`, inputData);
    }

    postCheckEmailContact(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`check-email-contact`, inputData);
    }

    getCompanyGrid(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`companygrid`, inputData);
    }

    getCompanyIndustryType(): Observable<any> {
        return this.httpCommonService.getRecord(`company-industry-type`);
    }

    getContactFormEdit(contact_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`contact/${contact_id}`);
    }

    updateContactForm(contact_id: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`update-contact/${contact_id}`, inputData);
    }

    getAssociatedContactGrid(contact_id: string, inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`contact-deals/${contact_id}`, inputData);
    }

    getCompanyId(): Observable<any> {
        return this.httpCommonService.getRecord(`get-company-id`);
    }

    getCompanyDetails(company_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`company/${company_id}`);
    }

    getCompanyFormEdit(company_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`company/${company_id}`);
    }

    updateCompanyForm(company_id: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`update-company/${company_id}`, inputData);
    }

    postCompanyForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`create-company`, inputData);
    }

    getAssociatedCompanyGrid(company_id: string, inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`company-deals/${company_id}`, inputData);
    }

    getCompanyArchiveStatus(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`change-company-status`, inputData);
    }

    getAllCompanyList(): Observable<any> {
        return this.httpCommonService.getRecord(`get-all-companies`);
    }

    getAllContactList(): Observable<any> {
        return this.httpCommonService.getRecord(`get-all-contacts`);
    }

    deleteShippingAddress(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord('delete-shipping-address', inputData);
    }

    getCompanyByDomain(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord('get-companies-by-domain', inputData);
    }

    checkCompanyName(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord('check-company-name', inputData);
    }
}

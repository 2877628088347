import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription, finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { AccountModuleService } from 'src/app-core/services/account-module.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LoaderService } from 'src/app-core/services/loader.service';
import { ProfileInfoComponent } from 'src/app/profile/profile-info/profile-info.component';

@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    commonDataModel = new CommonDataModel();
    permissionDataModel = this.commonDataModel.permissionDataModel;
    virtualScrollPayload = _.cloneDeep(this.commonDataModel.virtualScrollPayload);
    loggedUserData: any;
    notificationCount = null;
    notificationList: any;
    currentPage = 1;
    lastPage = 1;
    notificationIntervalTimer: any;
    isNotificationLoading: boolean = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private router: Router,
        private matDialog: MatDialog,
        private commonMethod: CommonMethod,
        private loaderService: LoaderService,
        private customToastrService: CustomToastrService,
        private accountModuleService: AccountModuleService) {
        this.loggedUserData = this.commonMethod.getLoggedUserData();
        this.permissionDataModel = this.commonMethod.mappingPermissionModel();
    }

    ngOnInit(): void {
        this.onLoadItems();
        this.componentSubscriptions.add(
            this.commonMethod.getUserNameValue.subscribe((data: any) => {
                if (!_.isEmpty(data)) {
                    this.loggedUserData = this.commonMethod.getLoggedUserData();
                    this.loggedUserData.name = data.name;
                    this.loggedUserData.file = data.file;
                    this.commonMethod.setLoggedUserData(this.loggedUserData);
                }
            })
        )
        this.notificationIntervalTimer = setInterval(() => {
            this.onLoadItems(false);
        }, 15000);
    }

    onLoadItems(loaderValue = true): void {
        this.isNotificationLoading = loaderValue;
        this.accountModuleService.postNotifications(this.virtualScrollPayload)
            .pipe(finalize(() => this.isNotificationLoading = loaderValue))
            .subscribe(response => {
                this.notificationCount = response.data?.total;
                this.currentPage = response.data?.current_page;
                this.lastPage = response.data?.last_page;
                this.notificationList = this.virtualScrollPayload.skip_count === 0 ? response.data?.data : this.notificationList.concat(...response.data?.data);
            });
    }

    onSelectNotification(data: any) {
        this.loaderService.show()
        if (data.data?.type) {
            switch ((data.data.type)) {
                case this.commonConstants.gridNameConstants.issue:
                case this.commonConstants.gridNameConstants.leadlineStatus:
                    if (data.data?.is_active) {
                        this.router.navigateByUrl(`lead-line-info/${data?.data?.lead_id}/${data?.data?.leadline_id}/0/${CommonConstants.gridNameConstants.dashboard}`);
                    } else {
                        this.router.navigateByUrl(`lead-details/lead/${data.data.lead_id}/0`);
                    }
                    break;
                default:
                    if (data.data.is_active === undefined) {
                        if (data.data.type === 'Issue') {
                            this.commonMethod.serviceModuleExpansionRedirection(data?.data?.lead_id);
                        } else {
                            this.router.navigateByUrl(`lead-line-info/${data?.data?.lead_id}/${data?.data?.leadline_id}/0/${(data?.data?.type).toLowerCase()}-list`);
                        }
                    } else {
                        this.router.navigateByUrl(`lead-line-info/${data?.data?.lead_id}/${data?.data?.leadline_id}/0/${CommonConstants.gridNameConstants.dashboard}`);
                    }
                    break;
            }
        } else {
            this.router.navigateByUrl(`lead-details/lead/${data.data.lead_id}/0`);
        }
        this.accountModuleService.getNotificationsRead(data.id)
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe(response => {
                this.onLoadItems();
            });
    }

    onMarkAllAsRead() {
        this.isNotificationLoading = true;
        this.accountModuleService.getNotificationsReadAll()
            .pipe(finalize(() => {
                this.isNotificationLoading = false;
                this.virtualScrollPayload.skip_count = 0;
                this.onLoadItems(false);
            }))
            .subscribe(response => {
                this.customToastrService.success(response?.message);
            });
    }

    onShowProfileInfo(): void {
        this.matDialog.open(ProfileInfoComponent, {
            position: { right: '0' },
            panelClass: 'md-dialog-wrapper',
            hasBackdrop: false,
            data: [] || undefined
        }).afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result?.confirmResult) {
            }
        });
    }

    onLogout(): void {
        this.loaderService.show();
        this.accountModuleService.postUserlogout()
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe(response => {
                this.commonMethod.logout();
                this.customToastrService.success(CommonConstants.appConstants.loggedOutText);
            });
    }

    onScrollDown() {
        if (this.notificationList?.length > 1) {
            const skipCount = this.commonMethod.lazyLoadingPagination(this.virtualScrollPayload?.skip_count, this.currentPage, this.lastPage);
            if (skipCount !== this.virtualScrollPayload.skip_count) {
                this.virtualScrollPayload.skip_count = skipCount;
                this.onLoadItems();
            }
        }
    }

    onSupportClick(): void {
        window.open('https://softwerks.atlassian.net/servicedesk/customer/portal/1', '_blank');
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
        clearInterval(this.notificationIntervalTimer);
    }

}

export enum DefaultResultsPerPage {
    value = 20,
    reportGrid = 100000,
    contactCompanyGrid = 100000,
    announcementGrid = 100000,
    logHistoryGrid = 100000,
}

export enum ConformationDialogEnum {
    approveRequest = 1,
    rejectRequest = 2
}

export enum DocumentStatusEnum {
    doc_uploaded = 0,
    doc_approved = 1,
    doc_rejected = 2,
    new_request = 4,
    doc_cancelled = 5,
    commented = 6,
}

export enum BreakoutDocumentUploadEnum {
    value = 10
}

export enum EquipmentDataLengthEnum {
    value = 5
}

export enum ServiceGridPaginationEnum {
    value = 3
}

export enum DeletedValueEnum {
    deletedArchiveValue = 0,
    deletedRestoreValue = 1
}

export enum IdleUserTimesEnum {
    idleTime = 3600
}

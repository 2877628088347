import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { BreakoutDocumentUploadEnum, DocumentStatusEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
    selector: 'app-drawing-document-upload-form',
    templateUrl: './drawing-document-upload-form.component.html'
})
export class DrawingDocumentUploadFormComponent implements OnInit {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel();
    uploadDrawingFormPayload = _.cloneDeep(this.commonDataModel.uploadDrawingFormPayload);
    isFormLoading = false;
    isBreakoutDrawingValid = true;
    commonConstants: typeof CommonConstants = CommonConstants;
    documentStatusEnum: typeof DocumentStatusEnum = DocumentStatusEnum;
    breakoutDocumentUploadEnum: typeof BreakoutDocumentUploadEnum = BreakoutDocumentUploadEnum;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<DrawingDocumentUploadFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            const dataItem = _.cloneDeep(data);
            dataItem.file = Object.assign({}, this.uploadDrawingFormPayload.file);
            this.commonMethod.objectMapping(this.uploadDrawingFormPayload, dataItem);
            this.uploadDrawingFormPayload.oldFile = data?.url;
            this.uploadDrawingFormPayload.oldFileName = data?.file;
            if (this.uploadDrawingFormPayload.oldFile) {
                this.onLoadItems();
            }
        }
    }

    ngOnInit() {
    }

    onLoadItems(): void {
        this.isFormLoading = true;
        this.commonService.getBreakoutFileList(this.uploadDrawingFormPayload.doc_req_id)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response.data) {
                    this.uploadDrawingFormPayload.breakout_files_old = response.data;
                }
            });
    }

    onFormSubmit() {
        if (this.uploadDrawingFormPayload.is_approved !== this.documentStatusEnum.doc_approved) {
            if (this.uploadDrawingFormPayload.oldFile) {
                this.uploadSizingDocument();
            } else if (this.uploadDrawingFormPayload.file.fileName && this.isBreakoutDrawingValid) {
                this.uploadSizingDocument();
            } else { }
        } else {
            this.uploadSizingDocument();
        }
    }

    uploadSizingDocument(): void {
        this.isFormLoading = true;
        const payload = _.cloneDeep(this.uploadDrawingFormPayload);
        payload.breakout_files = _.filter(this.uploadDrawingFormPayload.breakout_files, (list: any) => {
            if (list.fileName) {
                return list;
            }
        });
        this.commonService.postUploadDrawingDocumentForm(payload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.customToastrService.success(response?.message);
                this.dialogRef.close({ confirmResult: true });
            });
    }

    async onChangeFile(event: any, index: number) {
        try {
            const resultData = await this.commonMethod.uploadFileValidation(event);
            if (resultData.isFileValid) {
                if (index === -1) {
                    this.commonMethod.objectMapping(this.uploadDrawingFormPayload.file, resultData);
                } else {
                    const fileIndex = _.findIndex(this.uploadDrawingFormPayload.breakout_files, ['fileName', resultData.fileName]);
                    if (fileIndex === -1) {
                        this.isBreakoutDrawingValid = true;
                        this.commonMethod.objectMapping(this.uploadDrawingFormPayload.breakout_files[index], resultData);
                    } else {
                        this.isBreakoutDrawingValid = false;
                        this.customToastrService.error(`${resultData?.fileName + this.commonConstants.uploadDocumentsConstants.breakoutDuplicateError}`)
                    }
                }
            }
        } catch (error: any) {
            this.customToastrService.error(error);
        }
    }

    onAddBreakoutDrawings(): void {
        if (this.uploadDrawingFormPayload.breakout_files.length < this.breakoutDocumentUploadEnum.value) {
            this.uploadDrawingFormPayload.breakout_files.push(Object.assign({}, this.commonDataModel.fileModel));
        } else {
            this.customToastrService.error(`${this.commonConstants.uploadDocumentsConstants.drawingBreakoutError + this.breakoutDocumentUploadEnum.value + ' breakout drawings.'}`);
        }
    }

    onRemoveUplodedBreakoutDrawings(id: any, index: number): void {
        this.isFormLoading = true;
        this.commonService.deleteBreakoutFileById(id)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.uploadDrawingFormPayload.breakout_files_old.splice(index, 1);
                this.customToastrService.success(response?.message);
            });
    }

    onRemoveBreakoutDrawings(index: number): void {
        this.uploadDrawingFormPayload.breakout_files.splice(index, 1);
    }

    onSetTargetLink(fileUrl: string) {
        return this.commonMethod.setTargetLink(fileUrl);
    }

    onSetFileNavigationLink(fileUrl: string) {
        return this.commonMethod.setFileNavigationLink(fileUrl);
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { DocumentStatusEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-sizing-document-upload-form',
    templateUrl: './sizing-document-upload-form.component.html',
    styleUrls: ['./sizing-document-upload-form.component.scss']
})
export class SizingDocumentUploadFormComponent implements OnInit {

    commonSettings = new CommonSettings();
    commonDataModel = new CommonDataModel();
    uploadSizingFormPayload = _.cloneDeep(this.commonDataModel.uploadSizingFormPayload);
    uploadedFile: any = {};
    isFormLoading = false;
    baseUrl = environment.baseUrl;
    commonConstants: typeof CommonConstants = CommonConstants;
    documentStatusEnum: typeof DocumentStatusEnum = DocumentStatusEnum;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<SizingDocumentUploadFormComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            const dataItem = _.cloneDeep(data);
            this.uploadedFile.fileName = dataItem?.file;
            this.uploadedFile.url = dataItem?.url;
            dataItem.file = Object.assign({}, this.uploadSizingFormPayload.file);
            this.commonMethod.objectMapping(this.uploadSizingFormPayload, dataItem);
        }
    }

    ngOnInit() {
    }

    onFormSubmit() {
        if (this.uploadSizingFormPayload.is_approved !== DocumentStatusEnum.doc_approved) {
            if (this.uploadSizingFormPayload.file.fileName || this.uploadedFile.url) {
                this.uploadSizingDocument();
            }
        } else {
            this.uploadSizingDocument();
        }
    }

    uploadSizingDocument(): void {
        this.isFormLoading = true;
        this.commonService.postUploadSizingDocumentForm(this.uploadSizingFormPayload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.customToastrService.success(response?.message);
                this.dialogRef.close({ confirmResult: true });
            });
    }

    async onChangeFile(event: any) {
        try {
            const resultData = await this.commonMethod.uploadFileValidation(event);
            if (resultData.isFileValid) {
                this.commonMethod.objectMapping(this.uploadSizingFormPayload.file, resultData);
            } else {
                this.uploadSizingFormPayload.file = _.cloneDeep(this.commonDataModel.fileModel);
            }
        } catch (error: any) {
            this.customToastrService.error(error);
        }
    }

    onSetTargetLink(fileUrl: string) {
        return this.commonMethod.setTargetLink(fileUrl);
    }

    onSetFileNavigationLink(fileUrl: string) {
        return this.commonMethod.setFileNavigationLink(fileUrl);
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

import { Directive, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';

@Directive({
    selector: '[appDropdown]',
})
export class DropdownDirective implements AfterViewInit, OnChanges {

    constructor(private el: ElementRef) { }
    @Input('appDropdown') list: any;
    @Input('dropDownOrderBy') orderBy: any;
    @Input('dropDownOrder') order: any;

    ngAfterViewInit() {
        this.processList();
    }

    ngOnChanges() {
        this.processList();
    }

    processList() {
        if (this.list) {
            let sorted = [];
            if (this.order === 'asc') {
                sorted = this.list.sort((a: any, b: any) => (a[this.orderBy].toLowerCase() > b[this.orderBy].toLowerCase() ? 1 : -1));
            } else {
                sorted = this.list.sort((a: any, b: any) => (a[this.orderBy] > b[this.orderBy] ? -1 : 1));
            }
            const noneIndex = sorted.findIndex((item: any) => {
                return item.value && item.value.startsWith('None');
            });
            if (noneIndex !== -1) {
                const noneItem = sorted.splice(noneIndex, 1);
                sorted.unshift(noneItem[0]);
            }
            const otherIndex = sorted.findIndex((item: any) => {
                return item.value && (item.value.toLowerCase() === 'other' || item.value.startsWith('Other - '));
            });
            if (otherIndex !== -1) {
                const otherItem = sorted.splice(otherIndex, 1);
                sorted.push(otherItem[0]);
            }
            return sorted;
        }
    }
}

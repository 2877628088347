<h1 mat-dialog-title>
    <span>Upload Drawing Document</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #uploadDrawingForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="uploadDrawingForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="form-field" *ngIf="uploadDrawingFormPayload.oldFile">
                <div class="label-text">Uploaded Primary Drawing</div>
                <div class="file-wrapper">
                    <a [target]="onSetTargetLink(uploadDrawingFormPayload?.oldFile)"
                        [href]="onSetFileNavigationLink(uploadDrawingFormPayload?.oldFile)">
                        {{uploadDrawingFormPayload.oldFileName}}
                    </a>
                </div>
            </div>
            <ng-container *ngIf="uploadDrawingFormPayload.is_approved !== documentStatusEnum.doc_approved">
                <div class="form-field">
                    <div class="label-text">Choose Primary Drawing
                        <sup *ngIf="!uploadDrawingFormPayload.oldFile">*</sup>
                    </div>
                    <label for="myFile" class="custom-input-file"
                        [ngClass]="{'error-field': !uploadDrawingFormPayload.oldFile && !uploadDrawingFormPayload.file.fileName && uploadDrawingForm.submitted}">
                        <input type="file" id="myFile" name="filename" class="input-file" ng2FileSelect
                            (change)="onChangeFile($event,-1)">
                    </label>
                </div>
            </ng-container>
            <div class="row mb-3">
                <div class="col-8 p-0 d-flex align-items-center">
                    <div class="display-6">Upload Breakout Drawings</div>
                </div>
                <div class="col-4 p-0 text-end">
                    <button mat-flat-button type="button" color="primary" class="icon-btn m-0"
                        matTooltip="Add Breakout Drawings" (click)="onAddBreakoutDrawings()"
                        *ngIf="uploadDrawingFormPayload.is_approved !== documentStatusEnum.doc_approved">
                        <img src="assets/images/add-icon.svg" />
                    </button>
                </div>
            </div>
            <div class="text mb-3">
                You must order the breakout drawings before approving them. Once approved you cannot re-order and
                you will have to create a new drawing request.
            </div>
            <div class="row" *ngFor="let item of uploadDrawingFormPayload.breakout_files; let index=index">
                <div class="col-11 ps-0 pe-2">
                    <div class="form-field">
                        <div class="label-text">Choose Breakout Drawing {{index + 1}}</div>
                        <label for="myFile{{index}}" class="custom-input-file">
                            <input type="file" id="myFile{{index}}" name="myFile{{index}}" class="input-file"
                                ng2FileSelect (change)="onChangeFile($event, index)">
                        </label>
                    </div>
                </div>
                <div class="col-1 p-0 d-flex align-items-center justify-content-end">
                    <button mat-flat-button type="button" color="primary" class="icon-btn m-0"
                        matTooltip="Remove Breakout Drawing" (click)="onRemoveBreakoutDrawings(index)">
                        <img src="assets/images/close-icon.svg" />
                    </button>
                </div>
            </div>
            <div class="row" *ngFor="let item of uploadDrawingFormPayload.breakout_files_old; let index=index">
                <div
                    [ngClass]="(uploadDrawingFormPayload.is_approved !== documentStatusEnum.doc_approved?'col-11 ps-0 pe-2':'col-12 p-0')">
                    <div class="form-field">
                        <div class="label-text">Uploaded Breakout Drawing {{index + 1}}</div>
                        <div class="file-wrapper">
                            <a [target]="onSetTargetLink(item?.url)" [href]="onSetFileNavigationLink(item?.url)">
                                {{item.file}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-1 p-0 d-flex align-items-center justify-content-end"
                    *ngIf="uploadDrawingFormPayload.is_approved !== documentStatusEnum.doc_approved">
                    <button mat-flat-button type="button" color="primary" class="icon-btn m-0"
                        matTooltip="Remove Breakout Drawing" (click)="onRemoveUplodedBreakoutDrawings(item.id,index)">
                        <img src="assets/images/close-icon.svg" />
                    </button>
                </div>
            </div>
            <div class="no-record-text text-start mt-4"
                *ngIf="uploadDrawingFormPayload.is_approved === documentStatusEnum.doc_approved && !uploadDrawingFormPayload.breakout_files_old.length">
                {{commonConstants.appConstants.noRecordsAvailable}}
            </div>
        </div>
        <div mat-dialog-actions *ngIf="uploadDrawingFormPayload.is_approved !== documentStatusEnum.doc_approved">
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
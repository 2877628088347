import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { ConformationDialogEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';

@Component({
    selector: 'app-conformation-dialog',
    templateUrl: './conformation-dialog.component.html',
    styleUrls: ['./conformation-dialog.component.scss']
})
export class ConformationDialogComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    approveRequestFormPayload = _.cloneDeep(this.commonDataModel.approveRequestFormPayload);
    dialogData: any;
    isFormLoading = false;
    respondersSourceList: any[] = [];
    commonConstants: typeof CommonConstants = CommonConstants;
    conformationDialogEnum: typeof ConformationDialogEnum = ConformationDialogEnum;

    constructor(
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private customToastrService: CustomToastrService,
        private dialogRef: MatDialogRef<ConformationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.dialogData = data;
        }
    }

    ngOnInit() {
        this.onLoadItems();
    }

    onLoadItems() {
        this.isFormLoading = true;
        this.commonService.getActiveUserList()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.respondersSourceList = response?.data;
            });
    }

    onFormSubmit(): void {
        this.isFormLoading = true;
        const comment = this.commonMethod.commentValidation(this.approveRequestFormPayload.body);
        if (comment) {
            this.approveRequestFormPayload.body = comment;
            this.approveRequestFormPayload.users = this.commonMethod.getTaggedUsers();
        }
        this.commonService.postApproveRequestForm(this.dialogData, this.approveRequestFormPayload)
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                this.customToastrService.success(response?.message);
                this.dialogRef.close({ confirmResult: true });
            });
    }

    onClose() {
        this.dialogRef.close({ confirmResult: false });
    }

}

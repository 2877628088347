import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-lead-recent-activity-info',
    templateUrl: './lead-recent-activity-info.component.html'
})
export class LeadRecentActivityInfoComponent implements OnInit {

    leadDetailsModel: any;

    @Input() set data(list: any) {
        this.leadDetailsModel = list;
    }

    constructor() { }

    ngOnInit(): void {
    }

}

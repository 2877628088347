import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[inputAutoFocus]'
})
export class InputAutoFocusDirective implements AfterContentInit {

    constructor(private el: ElementRef) { }

    public ngAfterContentInit() {
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 100);
    }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsToDay'
})
export class SecondsToDayPipe implements PipeTransform {

    transform(value: any): any {
        if (value == null) {
            return;
        }
        if (value === 0) {
            return '-';
        }
        let time = '';
        value = parseInt(value, 10);
        const days = Math.floor(value / (3600 * 24));
        if (days !== 0) {
            time = time + days + 'd ';
        }
        value -= days * 3600 * 24;
        const hrs = Math.floor(value / 3600);
        if (hrs !== 0) {
            time = time + hrs + 'h ';
        }
        value -= hrs * 3600;
        const mins = Math.floor(value / 60);
        value -= mins * 60;
        return time + mins + 'm ' + value + 's';
    }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class LeadDetailsService {

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    getIssueGridRecords(leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`issues/${leadId}`, inputData);
    }

    getServiceGridRecords(leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`callgrid/${leadId}`, inputData);
    }

    getLeadLineList(leadId: string,): Observable<any> {
        return this.httpCommonService.getRecord(`issue-leadline/${leadId}`);
    }

    getCallLineList(lead_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`call_ids/${lead_id}`);
    }

    postLeadLineCopy(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`leadline-form/copy`, inputData);
    }

    getMemoGridRecords(leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`memo/${leadId}`, inputData);
    }

    getAffiliatesGridRecords(deal_id: string, inputData: object):Observable<any> {
        return this.httpCommonService.postRecord(`affiliate-contacts/${deal_id}`, inputData);
    }

    postMemoForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`memo`, inputData);
    }

    postPinMemo(memoId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`update_memo/${memoId}`, inputData);
    }

    postIssueForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`issues`, inputData);
    }

    postIssueValidation(issueId: string): Observable<any> {
        return this.httpCommonService.postRecord(`issue-status/${issueId}`, {});
    }

    postActivateLeadLine(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`lead/leadline/change-active-status`, inputData);
    }

    getleadActivity(lead_id: any, inputData: any): Observable<any> {
        return this.httpCommonService.postRecord(`lead-activity/lead/${lead_id}`, inputData);
    }

}

<h1 mat-dialog-title>
    <span>Leadline Revenue Editor</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper leadline-revenue-editor-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <table class="table table-borderless revenue-custom-table">
        <thead>
            <tr>
                <th class="header-text leadline-width"> Leadline </th>
                <th class="header-text"> Gross Amount </th>
                <th class="header-text"> Revenue Forecast </th>
            </tr>
        </thead>
        <tbody>
            <tr colspan="3" *ngFor="let item of revenueDataSource?.leadLine; let i= index">
                <td class="lead-line-id-text leadline-width"> {{item.lolleadline_id}} </td>
                <td>
                    <kendo-textbox [(ngModel)]="item.lolgross_amount" (focus)="handleFocus(item, 'amount')"
                        (blur)="onRevenueBlur(item, i)" name="lolpart_id" autocomplete="off" [maxlength]="30"
                        [disabled]="!item.is_active" pattern="^\$?(\d{0,8})?(?:,\d{3})*(\.\d{0,2})?$" RestrictPattern>
                    </kendo-textbox>
                </td>
                <td>
                    <kendo-textbox [(ngModel)]="item.lolrevenue_forecast" (focus)="handleFocus(item, 'forecast')"
                        (blur)="onRevenueBlur(item, i)" name="lolrevenue_forecast" autocomplete="off" [maxlength]="30"
                        [disabled]="!item.is_active" pattern="^\$?(\d{0,8})?(?:,\d{3})*(\.\d{0,2})?$" RestrictPattern>
                    </kendo-textbox>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td class="footer-total-text leadline-width"> Total </td>
                <td class="footer-amount-text"> ${{revenueDataSource?.totalGrossAmount}} </td>
                <td class="footer-amount-text"> ${{revenueDataSource?.totalRevenueForecastAmount}} </td>
            </tr>
        </tfoot>
    </table>
</div>
<h1 mat-dialog-title>
    <span>
        {{dialogData?.requestType === conformationDialogEnum.approveRequest ?
        commonConstants.appConstants.approveRequestTitle: commonConstants.appConstants.rejectRequestTitle}}
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper conformation-wrapper">
    <form #conformationForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="conformationForm.form.valid && onFormSubmit()">
        <div class="overlay-loader" [busyIf]="isFormLoading">
            <div class="form-loader"></div>
        </div>
        <div class="form-field">
            <div class="label-text">Comment</div>
            <app-tiny-editor [tinyType]="'comment'" [isLoadItem]="isFormLoading" [dropDownList]="respondersSourceList"
                [(ngModel)]="approveRequestFormPayload.body" name="bodyComments">
            </app-tiny-editor>
        </div>
        <div class="title-text">
            {{dialogData?.requestType === conformationDialogEnum.approveRequest ?
            commonConstants.appConstants.approveRequestText: commonConstants.appConstants.rejectRequestText}}
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                No
            </button>
            <button mat-flat-button type="submit" color="primary">
                Yes
            </button>
        </div>
    </form>
</div>
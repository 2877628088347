<h1 mat-dialog-title>
    <span>
        Edit Status Information
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper edit-status-form-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #editStatusInformation="ngForm" novalidate autocomplete="off"
        (ngSubmit)="editStatusInformation.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="edit-information-wrapper">
                <div class="row">
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Bid Date</div>
                            <kendo-datepicker [(ngModel)]="statusInformationPayload.bid_date" name="bid_date"
                                [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                [navigation]="false">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Lead Date</div>
                            <kendo-datepicker [(ngModel)]="statusInformationPayload.lead_date" name="lead_date"
                                [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                [navigation]="false" [disabled]="true">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Lead Stage</div>
                            <kendo-dropdownlist [data]="leadStageDataSource"
                                [(ngModel)]="statusInformationPayload.deal_stage" [textField]="'text'"
                                [valueField]="'value'" [valuePrimitive]="true"
                                [defaultItem]="commonSettings.defaultItems.textValue" name="deal_stage">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Lead Stage Date</div>
                            <kendo-datepicker [(ngModel)]="statusInformationPayload.deal_stage_date"
                                name="deal_stage_date" [readOnlyInput]="true" [format]="dateFormat"
                                [placeholder]="dateFormat" [navigation]="false">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Expected Closed Date</div>
                            <kendo-datepicker [(ngModel)]="statusInformationPayload.deal_closed_date"
                                name="deal_closed_date" [readOnlyInput]="true" [format]="dateFormat"
                                [placeholder]="dateFormat" [navigation]="false">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Status</div>
                            <kendo-dropdownlist [data]="statusDatelist" [textField]="'value'" [valueField]="'key'"
                                [valuePrimitive]="true" [(ngModel)]="statusInformationPayload.status"
                                [defaultItem]="commonSettings.defaultItems.KeyValue" name="status">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <ng-container *ngIf="statusInformationPayload.status === 'Closed'">
                        <div class="col-6">
                            <div class="form-field mb-3">
                                <div class="label-text">Closed By</div>
                                <kendo-textbox [(ngModel)]="statusInformationPayload.closed_by" name="closed_by"
                                    autocomplete="off" [disabled]="true">
                                </kendo-textbox>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field mb-3">
                                <div class="label-text">Closed Reason <sup>*</sup></div>
                                <kendo-dropdownlist [data]="closedReasonDataList" [textField]="'value'"
                                    [valueField]="'key'" [valuePrimitive]="true"
                                    [(ngModel)]="statusInformationPayload.closed_reason"
                                    [defaultItem]="commonSettings.defaultItems.KeyValue" name="closed_reason" required>
                                </kendo-dropdownlist>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Back
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>
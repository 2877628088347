import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app-core/auth/auth.guard';
import { ChangeLogListComponent } from './change-log-list/change-log-list.component';
import { DrawingListComponent } from './drawing-list/drawing-list.component';
import { HandoffReportComponent } from './handoff-report/handoff-report.component';
import { LayoutComponent } from './layout/layout.component';
import { LeadLayoutComponent } from './lead-layout/lead-layout.component';
import { PricingListComponent } from './pricing-list/pricing-list.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { SizingListComponent } from './sizing-list/sizing-list.component';
import { SystemAnnouncementsComponent } from './system-announcements/system-announcements.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        data: {
            preload: true
        }
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                data: {
                    preload: true
                }
            },
            {
                path: 'lead-line-info/:leadId/:leadlineId/:requestId',
                component: LeadLayoutComponent,
                children: [
                    {
                        path: 'dashboard',
                        loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule),
                        data: {
                            preload: true
                        }
                    },
                    {
                        path: 'lead-line',
                        loadChildren: () => import('./lead-line/lead-line.module').then(m => m.LeadLineModule),
                        data: {
                            preload: true
                        }
                    },
                    {
                        path: 'sizing-list',
                        component: SizingListComponent
                    },
                    {
                        path: 'drawing-list',
                        component: DrawingListComponent
                    },
                    {
                        path: 'pricing-list',
                        component: PricingListComponent
                    },
                    {
                        path: 'handoff-report',
                        component: HandoffReportComponent
                    },
                    {
                        path: 'change-log-list',
                        component: ChangeLogListComponent
                    }
                ]
            },
            {
                path: 'lead-details',
                loadChildren: () => import('./lead-details/lead-details.module').then(m => m.LeadDetailsModule),
                data: {
                    preload: true
                }
            },
            {
                path: 'profile',
                loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
                data: {
                    preload: true
                }
            },
            {
                path: '',
                loadChildren: () => import('./contact-company-list/contact-company.module').then(m => m.ContactCompanyModule),
                data: {
                    preload: true
                }
            },
            {
                path: 'system-announcements',
                component: SystemAnnouncementsComponent
            },
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full'
            },
            {
                path: '**',
                pathMatch: 'full',
                component: PageNotFoundComponent,
            }
        ]
    },
    // {
    //     path: '**',
    //     pathMatch: 'full',
    //     component: PageNotFoundComponent,
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule],
    providers: []
})

export class AppRoutingModule { }

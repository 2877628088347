<h1 mat-dialog-title>
    <span>Revise Due Date</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper conformation-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #reviseDueDateForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="reviseDueDateForm.form.valid && onFormSubmit()">
        <div class="form-field">
            <div class="label-text">Revise Due Date <sup>*</sup></div>
            <kendo-datetimepicker [(ngModel)]="reviseDueDate" name="reviseDueDate" [min]="minDate"
                [readOnlyInput]="true" [format]="dateTimeFormat" [placeholder]="dateTimeFormat" required>
            </kendo-datetimepicker>
        </div>
        <div class="form-field">
            <div class="label-text">Comment <sup>*</sup></div>
            <kendo-textarea [(ngModel)]="reviseDueDateFormPayload.comment" name="comment" [rows]="5" autocomplete="off"
                [maxlength]="500" required>
            </kendo-textarea>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                No
            </button>
            <button mat-flat-button type="submit" color="primary">
                Yes
            </button>
        </div>
    </form>
</div>